import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NapiComponent } from '../napi/napi.component';
import { AppComponent } from '../app.component';

@Component({
  selector: 'app-engedjogcim',
  standalone: true,
  imports: [CommonModule,NapiComponent],
  templateUrl: './engedjogcim.component.html',
  styleUrls: ['./engedjogcim.component.css']
})
export class EngedjogcimComponent  implements OnInit {
  constructor(private ap :AppComponent) {this.ap.title = 'Jogcímek engedélyezése'; }

  ngOnInit(): void {
      
  }  

}
