import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule} from '@angular/forms';
import { MatInputModule} from '@angular/material/input';
import { MatFormFieldModule} from '@angular/material/form-field';
import { AppComponent } from '../app.component';
import { SzurokComponent } from '../szurok/szurok.component';
import { AppService } from '../app.service';
import { MatTableModule} from '@angular/material/table';
import { MatDividerModule} from '@angular/material/divider';
import { KeyText} from '../model';
import { getMonth, getYear } from 'date-fns';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';

@Component({
  selector: 'app-zaras',
  standalone: true,
  imports: [CommonModule,MatSelectModule,FormsModule,MatInputModule,MatFormFieldModule,SzurokComponent,
    MatTableModule,MatDividerModule,MatSlideToggleModule,MatProgressSpinnerModule],
  templateUrl: './zaras.component.html',
  styleUrls: ['./zaras.component.css']
})
export class ZarasComponent {
  selectedYear: number = 0;
  selectedMonth: number = 0;
  honap : Number = 0;
  szerv : String = '';
  szervegysek: KeyText[] = [];
  bodydata= [];
  headerdata = [];
  tip = 'sz';
  isLoading = false;
  products : boolean[] = [];
  dolgozo : string[] = [];
  mszerz : string[] = [];

  years : any = [
    {y1: 2024,y2: "2024"},
    {y1: 2025,y2: "2025"},
  ];
  months : any = [
    {y1: 1,y2: "1"},
    {y1: 2,y2: "2"},
    {y1: 3,y2: "3"},
    {y1: 4,y2: "4"},
    {y1: 5,y2: "5"},
    {y1: 6,y2: "6"},
    {y1: 7,y2: "7"},
    {y1: 8,y2: "8"},
    {y1: 9,y2: "9"},
    {y1: 10,y2: "10"},
    {y1: 11,y2: "11"},
    {y1: 12,y2: "12"}
  ];
  
    constructor(private ap :AppComponent,private _service: AppService) {
      this.ap.title = 'Napi jelenlét zárás';
      this.ap.menuhujuj = false;
    }
  
    ngOnInit(): void {
    }
  
    ngAfterViewInit(): void {
      this._service.fetchBerinstall().subscribe(p => {
        this._service.szervegys3().subscribe(p => {
          this.szervegysek = p;
          this.selectedYear = getYear(Date.now());
          this.selectedMonth = getMonth(Date.now())+1;
          //************************************ */
          this.years[0].key =  this.selectedYear;
          this.years[0].KeyText =  this.selectedYear.toString;
          this.years[1].key =  this.selectedYear+1;
          this.years[1].Kextext =  (this.selectedYear+1).toString;
          //************************************ */
          if (p.length!=0) 
            this.szerv = p[0].key;
          else
            this.szerv = '';
          this.ToltTabla(this.szerv, this.selectedYear, this.selectedMonth, this.tip);
        });
      });
    }
  
    ToltTabla(szervegys:String,ev:number,ho:number,tip:string) : void {
      if ((ev==0) || (ho==0)) return;
      this.isLoading = true;
      this.products = [];
      //******************************************************************* */
      this._service.getszabiinfotable2(szervegys,ev, ho,tip).subscribe(p => {
        this.headerdata = JSON.parse(String(p.header));
        this.bodydata = JSON.parse(String(p.body));
        this.dolgozo = p.dolgozo;
        this.mszerz  = p.mszerz;
        this.products = p.zarva;
        this.isLoading = false;
      });
    }
    onSzervChanged(): void
    {
      this.ToltTabla(this.szerv,this.selectedYear,this.selectedMonth,this.tip);
    }
    onYearChanged(): void
    {
      this.ToltTabla(this.szerv,this.selectedYear,this.selectedMonth,this.tip);
    }
    onMonthChanged(): void
    {
      this.ToltTabla(this.szerv,this.selectedYear,this.selectedMonth,this.tip);
    }
    
    onengedely(): void {
      for(let i=0;i<this.products.length-1;i++){
        if ((!this.products[i]) && (this.dolgozo[i]!='')) 
          this.products[i] = true
        }
        this.NyitZarCsop(this.dolgozo,this.mszerz,this.products,this.selectedYear,this.selectedMonth,'zar');
    }
    onengedely2(): void {
      for(let i=0;i<this.products.length-1;i++) {
        if ((this.products[i]) && (this.dolgozo[i]!=''))
          this.products[i] = false
        }
        this.NyitZarCsop(this.dolgozo,this.mszerz,this.products,this.selectedYear,this.selectedMonth,'nyit');
      }

    changedZar(e: any, i:number): void {
      if (e.checked) 
        this.NyitZar(this.dolgozo[i],this.mszerz[i],this.selectedYear,this.selectedMonth,'zar');
      else 
        this.NyitZar(this.dolgozo[i],this.mszerz[i],this.selectedYear,this.selectedMonth,'nyit');
    }
  
    NyitZar(dolgozo:String,mszerz:String,ev:Number,ho:Number,tip:String): void {
      this._service.getNyitZar(dolgozo,mszerz,ev, ho,tip).subscribe();      
    }

    NyitZarCsop(dolgozo:String[],mszerz:String[],maszk:boolean[],ev:Number,ho:Number,tip:String): void {
      this._service.getNyitZarCsop(dolgozo,mszerz,maszk,ev, ho,tip).subscribe();      
    }

    SzervegysVisible() : boolean {
      return false;  
    }

  

}
