import { Component,OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NapiComponent } from '../napi/napi.component';
import { AppComponent } from '../app.component';

@Component({
  selector: 'app-engedszabi',
  standalone: true,
  imports: [CommonModule,NapiComponent],
  templateUrl: './engedszabi.component.html',
  styleUrls: ['./engedszabi.component.css']
})
export class EngedszabiComponent implements OnInit {

  constructor(private ap :AppComponent) {this.ap.title = 'Szabadság engedélyezés'; }

  ngOnInit(): void {
      
  }  
}
