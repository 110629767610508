import {FormArray, FormGroup, Validators} from "@angular/forms";
import {Utils} from "../core/utils";
import {KeyValue} from "@angular/common";

/**
 * General form utility methods
 */
export class FormUtils
{
    /**
     * Mark controls as required
     * @param group the form
     * @param fields comma separated list of control names to mark required
     */
    public static markControlsRequired(group: FormGroup | FormArray, fields?: string): void
    {
        const fset = new Set(Utils.toIdentList(fields));
        for(const key of Object.keys(group.controls))
        {
            const abstractControl = group.get(key);
            if(abstractControl instanceof FormGroup || abstractControl instanceof FormArray)
                FormUtils.markControlsRequired(abstractControl, fields);
            else if(fset?.has(key))
                abstractControl?.addValidators(Validators.required);
            else
                abstractControl?.removeValidators(Validators.required);
        }
    }

    /**
     * Mark controls as disabled (read only)
     * @param group the form
     * @param fields control names to mark disabled
     */
    public static markControlsDisabled(group: FormGroup | FormArray, fields?: string): void
    {
        const fset = new Set(Utils.toIdentList(fields));
        for(const key of Object.keys(group.controls))
        {
            const abstractControl = group.get(key);
            if(abstractControl instanceof FormGroup || abstractControl instanceof FormArray)
                FormUtils.markControlsDisabled(abstractControl, fields);
            else if(fset?.has(key))
                abstractControl?.disable();
            else
                abstractControl?.enable();                
        }
    }

    /**
     * Get first modified control name
     *
     * @param group the form
     */
    public static firstDirtyControl(group: FormGroup | FormArray): KeyValue<string, string> | null
    {
        const keys = Object.keys(group.controls);
        for(const key of keys)
        {
            const abstractControl = group.get(key);
            if(abstractControl instanceof FormGroup || abstractControl instanceof FormArray)
            {
                const ctrl = this.firstDirtyControl(abstractControl);
                if(ctrl)
                    return ctrl;
            }
            else if(abstractControl?.dirty && abstractControl.value != null)
                return <KeyValue<string, string>>{key: key, value: abstractControl.value};
        }
        return null;
    }

    public static firstTouchedControl(group: FormGroup | FormArray): KeyValue<string, string> | null
    {
        const keys = Object.keys(group.controls);
        for(const key of keys)
        {
            const abstractControl = group.get(key);
            if(abstractControl instanceof FormGroup || abstractControl instanceof FormArray)
            {
                const ctrl = this.firstTouchedControl(abstractControl);
                if(ctrl)
                    return ctrl;
            }
            else if(abstractControl?.touched && abstractControl.value)
                return <KeyValue<string, string>>{key: key, value: abstractControl.value};
        }
        return null;
    }
}
