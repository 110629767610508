import {ApplicationRef, EventEmitter, OnDestroy, Pipe, PipeTransform} from "@angular/core";
import {LangChangeEvent, TranslateService} from "./translate.service";
import {Utils} from "../core/utils";

@Pipe({
    name: "translate", pure: false,
    standalone: true
})
export class TranslatePipe implements PipeTransform, OnDestroy
{
    onLangChange?: EventEmitter<LangChangeEvent>;

    private _value = "";
    private _lastKey: string = "";

    constructor(private _translate: TranslateService, private _appRef: ApplicationRef) {}

    transform(key: any, ...args: any[]): any
    {
        if(!key || !key.length)
            return key;

        if(Utils.equals(key, this._lastKey))
            return this._value;

        this._lastKey = key;
        this._value = this._translate.translate(key);

        if(!this.onLangChange)
        {
            this.onLangChange = this._translate.onLangChange;
            this.onLangChange.subscribe((event: LangChangeEvent) => this._value = this._translate.translate(key));
        }

        return this._value;
    }

    /**
     * Clean any existing subscription to change events
     * @private
     */
    _dispose(): void
    {
        if(typeof this.onLangChange !== "undefined")
        {
//            this.onLangChange.unsubscribe();
            this.onLangChange = undefined;
        }
    }

    ngOnDestroy(): void
    {
        this._dispose();
    }
}
