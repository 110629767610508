import { Component, EventEmitter, Input, Output} from '@angular/core';
import { CommonModule } from '@angular/common';
import { IJelenlet, TorzstarParams,KeyText,Berinstall,DolgozoRec} from '../model';
import {OnInit,Inject } from '@angular/core';
import { AppService } from '../app.service';
import { ActivatedRoute } from '@angular/router';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatSelectModule } from '@angular/material/select';
import { MatAutocompleteModule} from '@angular/material/autocomplete';
import {Observable} from 'rxjs';
import {FormsModule, ReactiveFormsModule, UntypedFormControl} from '@angular/forms';
import {map, startWith} from 'rxjs/operators';
import {MatIconModule} from '@angular/material/icon';


@Component({
  selector: 'app-szurok',
  standalone: true,
  imports: [CommonModule,MatInputModule,MatListModule,MatSelectModule,MatAutocompleteModule,FormsModule,ReactiveFormsModule,MatIconModule],
  templateUrl: './szurok.component.html',
  styleUrls: ['./szurok.component.css']
})
export class SzurokComponent implements OnInit {
  szervegysek: KeyText[] = [];
  munkaszerzodesek: KeyText[] = [];
  szerv: string = "";
  szervlabel: string = "";
  msz: string = "";
  dolg: string = '';
  autocompleteDolgozo = new UntypedFormControl();   
  filtereddolgozo?: Observable<DolgozoRec[]>;
  
  @Input() item = ''; 
  
  constructor(private _route: ActivatedRoute, private _service: AppService) {}


  ngOnInit(): void
  {
     this.autocompleteDolgozo.valueChanges
        .pipe(
        startWith(''),         
        map(value => typeof value === 'string' ? this._filter(value) : this._filter(value?.name))
            ).subscribe(p=> {
                this.filtereddolgozo= p;
            });
  }

  ngAfterViewInit() : void
  {
    this._service.fetchBerinstall().subscribe(p => {
      if (this._service._Berinst().szurotip == '1') {
        this.szervlabel = "Szervezetiegység";
        this._service.szervegys().subscribe(p => {
          this.szervegysek = p;
        });
      }
      else {
        this.szervlabel = "Kifizetőhely";
        this._service.fizhely().subscribe(p => {
          this.szervegysek = p;
        });
      }

      if ((this.item=='engedhome') || (this.item=='engedszabi') || (this.item=='engedtavmunka')) {
        this.filtereddolgozo?.subscribe(p => {
          if (p.length==0) return;

          document.getElementById("hujuj")?.focus();
          // this.autocompleteDolgozo.setValue(p[0]);
          // this.dolg = this.autocompleteDolgozo.value.id;
          // if ((this.dolg !="")){
          //   this._service.getDolgozoFilterId(this.dolg).subscribe(p => {
          //     this.autocompleteDolgozo.setValue(p);
          //     //**************************************************************
          //     this._service.getMszerz(this.dolg).subscribe(pm => {
          //       this.munkaszerzodesek = pm;
          //       this.msz = p.fomszerz;
          //       //***************************************************************
          //       //this._napi.HonapTolt(this.dolg,this.msz,this._service._Berinst().ev,this._service._Berinst().aktberho)
          //       var ev_ = this._service._Berinst().ev;
          //       var ho_ = this._service._Berinst().aktberho; 
          //       this._service.getevho(this.dolg,this.msz,this.item).subscribe(j => {
          //           ev_ = j.ev;
          //           ho_ = j.ho
          //           this._service.Kulcsok.emit({dolgozo:this.dolg,mszerz:this.msz,ev:ev_,ho:ho_,item:this.item});
          //       });
          //     });
          //   });
          // }
        });
      }
      else {
        this.dolg = this._service._Berinst().dolgozo;
        if ((this.dolg !="")){
          this._service.getDolgozoFilterId(this.dolg).subscribe(p => {
            this.autocompleteDolgozo.setValue(p);
            //**************************************************************
            this._service.getMszerz(this.dolg).subscribe(pm => {
              this.munkaszerzodesek = pm;
              this.msz = p.fomszerz;
              //***************************************************************
              //this._napi.HonapTolt(this.dolg,this.msz,this._service._Berinst().ev,this._service._Berinst().aktberho)
              var ev_ = this._service._Berinst().ev;
              var ho_ = this._service._Berinst().aktberho; 
              this._service.Kulcsok.emit({dolgozo:this.dolg,mszerz:this.msz,ev:ev_,ho:ho_,item:this.item});
            });
          });
        }
      }
  });
}

  private _filter(value: string): Observable<DolgozoRec[]> {
    return this._service.getDolgozoFilter(this.szerv,value,this.item);
  }
   displayFn(ktgs: DolgozoRec): string {
    return ktgs && ktgs.name ? ktgs.name : '';
  }

        
  onDolgozoChanged(event: any){
      this._service.getMszerz(event.option.value.id).subscribe(p => {
        this.munkaszerzodesek = p;
        this.dolg = event.option.value.id;
        this.msz = event.option.value.fomszerz;
        var ev_ = this._service._Berinst().ev;
        var ho_ = this._service._Berinst().aktberho; 
        if ((this.item=='engedhome') || (this.item=='engedszabi') || (this.item=='engedtavmunka')) {
            this._service.getevho(this.dolg,this.msz,this.item).subscribe(j => {
                ev_ = j.ev;
                ho_ = j.ho
                this._service.Kulcsok.emit({dolgozo:this.dolg,mszerz:this.msz,ev:ev_,ho:ho_,item:this.item});
              });
          }
        else  
          this._service.Kulcsok.emit({dolgozo:this.dolg,mszerz:this.msz,ev:ev_,ho:ho_,item:this.item});
      });
  }  

  onSzervChanged(): void
  {
    this.autocompleteDolgozo.setValue('');
    this.dolg = '';
    this.msz = "";
  }

  onMszChanged(): void
  {
  //  this.msz = '';
    this._service.Kulcsok.emit({dolgozo:this.dolg,mszerz:this.msz,ev:this._service._Berinst().ev,ho:this._service._Berinst().aktberho,item:this.item});
  }
  ontorles(): void
  {
    this.autocompleteDolgozo.setValue('');
    this.dolg = '';
    this.msz = "";
//    document.getElementById("mat-input-0")?.focus();
    document.getElementById("hujuj")?.focus();
  }

}
