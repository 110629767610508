import { Routes } from '@angular/router';
import { AuthGuard, ChangePasswordComponent } from 'top-core';
import { LoginComponentSpec } from './login.component';
import { JelenletComponent } from './jelenlet/jelenlet.component';
import { EngedComponent } from './enged/enged.component';
import { EngedszabiComponent } from './engedszabi/engedszabi.component';
import { EngedjogcimComponent } from './engedjogcim/engedjogcim.component';
import { EngedhomeComponent } from './engedhome/engedhome.component';
import { EngedtavmunkaComponen } from './engedtavmunka/engedtavmunka.component';
import { KerelemComponent } from './kerelem/kerelem.component';
import { KerelemhoComponent } from './kerelemho/kerelemho.component';
import { KerelemtmComponent } from './kerelemtm/kerelemtm.component';
import { SzabiinfoComponent } from './szabiinfo/szabiinfo.component';
import { SzabiinfotableComponent } from './szabiinfotable/szabiinfotable.component';
import { ZarasComponent } from './zaras/zaras.component';
import { TervComponent } from './terv/terv.component';
import { MainpageComponent } from './mainpage/mainpage.component';

export const routes: Routes = [
    { path: "", redirectTo: "/kezdolap", pathMatch: "full" },
    { path: "login", component: LoginComponentSpec },
    { path: "chgpwd", canActivate: [AuthGuard], component: ChangePasswordComponent },
    { path: "kezdolap", canActivate: [AuthGuard],component: MainpageComponent},
    { path: "jelenlet", canActivate: [AuthGuard],component: JelenletComponent},
    { path: "enged", canActivate: [AuthGuard],component: EngedComponent},
    { path: "engedszabi", canActivate: [AuthGuard],component: EngedszabiComponent},
    { path: "engedjogcim", canActivate: [AuthGuard],component: EngedjogcimComponent},
    { path: "engedhome", canActivate: [AuthGuard],component: EngedhomeComponent},
    { path: "engedtavmunka", canActivate: [AuthGuard],component: EngedtavmunkaComponen},
    { path: "kerelem", canActivate: [AuthGuard],component: KerelemComponent},
    { path: "kerelemho", canActivate: [AuthGuard],component: KerelemhoComponent},
    { path: "kerelemtm", canActivate: [AuthGuard],component: KerelemtmComponent},
    { path: "szabiinfo", canActivate: [AuthGuard],component: SzabiinfoComponent},
    { path: "szabiinfotable", canActivate: [AuthGuard],component: SzabiinfotableComponent},
    { path: "zaras", canActivate: [AuthGuard],component: ZarasComponent},
    { path: "terv", canActivate: [AuthGuard],component: TervComponent},
];
