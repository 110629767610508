import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppComponent } from '../app.component';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import { RouterModule } from '@angular/router';
import { AuthService } from 'top-core';
import { AppService } from '../app.service';
import {MatBadgeModule} from '@angular/material/badge';

@Component({
  selector: 'app-mainpage',
  standalone: true,
  imports: [CommonModule, MatButtonModule, MatCardModule, RouterModule,MatBadgeModule],
  templateUrl: './mainpage.component.html',
  styleUrls: ['./mainpage.component.css']
})
export class MainpageComponent implements OnInit {
  userName: string = "";
  constructor(private ap :AppComponent, private _auth: AuthService,private _service: AppService) { 
    this.ap.title = 'Kezdőlap'; 
    this.userName = this._auth.user.name;
    this.ap.menuhujuj = false;
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this._service.fetchBerinstallLogin().subscribe(p => {
    });
  }

  showmenu(tipus: string):boolean {        
    if (tipus=="engedely")
        return this._service._Berinst().vezeto;
    
    if (tipus=="zaras")
        return this._service._Berinst().vezeto && this._service._Berinst().lezaras;

    if (tipus=="tavmunka")
        return this._service._Berinst().vezeto && this._service._Berinst().tavmunka;

    if (tipus=="jogcim")
      return this._service._Berinst().vezeto && this._service._Berinst().engedelykot;

    return false;
}

  szdb():number {
    return this._service._Berinst().szdb;
  }
  hodb():number {
    return this._service._Berinst().hodb;
  }
  tmdb():number {
    return this._service._Berinst().tmdb;
  }
  jodb():number {
    return this._service._Berinst().jodb;
  }

}
