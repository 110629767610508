import { Component, OnInit, Input } from "@angular/core";
import {TranslateService} from "./translate.service";
import {NgClass} from "@angular/common";
import { TranslatePipe } from "./translate.pipe";

@Component({
    selector: "top-lang",
    templateUrl: "./lang.component.html",
    standalone: true,
    imports: [NgClass, TranslatePipe]
})
export class LangComponent implements OnInit
{
    lang: string = '';
    isOpen: boolean = false;

    constructor(private _trans: TranslateService) {}

    ngOnInit(): void
    {
        this.lang = this._trans.language;
    }

    public selectLang(lang: string): void
    {
        this._trans.use(lang);
        this.lang = lang;
        this.isOpen = false;
    }

    public toggleOpen()
    {
        this.isOpen = !this.isOpen;
    }
}
