import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule} from '@angular/forms';
import { MatInputModule} from '@angular/material/input';
import { MatFormFieldModule} from '@angular/material/form-field';
import { AppComponent } from '../app.component';
import { SzurokComponent } from '../szurok/szurok.component';
import { AppService } from '../app.service';
import { MatTableModule} from '@angular/material/table';
import { MatDividerModule} from '@angular/material/divider';
import { KeyText} from '../model';
import {  getYear } from 'date-fns';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatButtonModule } from '@angular/material/button';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { ActivatedRoute } from '@angular/router';
import {MatRadioModule} from '@angular/material/radio';
@Component({
  selector: 'app-enged',
  standalone: true,
  imports: [CommonModule,MatSelectModule,FormsModule,MatInputModule,MatFormFieldModule,SzurokComponent,
    MatTableModule,MatDividerModule,MatSlideToggleModule,MatButtonModule,MatProgressSpinnerModule,MatRadioModule],
  templateUrl: './enged.component.html',
  styleUrls: ['./enged.component.css']
})
export class EngedComponent {
  selectedYear: number = 0;
  szerv : String = '';
  szervegysek: KeyText[] = [];
  bodydata= [];
  headerdata = [];
  tip = 'sz';
  products : boolean[] = [];
  dolgozo : string[] = [];
  dolgozo_ : string[] = [];
  isLoading = false;
  selected_: string = '1';
  helyettesitett : String = '';
  //helyettesitetttomb: KeyText[] = [];
  jogcimdata : String[] = [];

  years : any = [
    {y1: 2024,y2: "2024"},
    {y1: 2025,y2: "2025"},
  ];
  
    constructor(private ap :AppComponent,private _service: AppService,private route: ActivatedRoute) {
      this.ap.title = 'Csoportos engedélyezés';
      this.ap.menuhujuj = false;
    }
  
    ngOnInit(): void {
    }
  
  ngAfterViewInit(): void {
    this._service.fetchBerinstall().subscribe(p => {
      this._service.szervegys3().subscribe(p => {
        this.szervegysek = p;
        if (p.length!=0) 
          this.szerv = p[0].key;
        else
          this.szerv = '';
//        this._service.helyettesitetttolt().subscribe(p => {
//          this.helyettesitetttomb = p;
          //************************************ */
          this.selectedYear = getYear(Date.now());
          this.years[0].key =  this.selectedYear;
          this.years[0].KeyText =  this.selectedYear.toString;
          this.years[1].key =  this.selectedYear+1;
          this.years[1].Kextext =  (this.selectedYear+1).toString;
          //************************************ */
          this.route.queryParams.subscribe(params => {
            if (params.tip=='sz')
              this.selected_ = '1';
            else if (params.tip=='ho')
              this.selected_ = '2';
            else if (params.tip=='tm')
              this.selected_ = '3';
            else if (params.tip=='jo')
              this.selected_ = '4';
            this.tip = params.tip;
            this.ToltTabla(this.helyettesitett,this.szerv, this.selectedYear, this.tip);
          })
//        });
      });
    });
  }
  
    ToltTabla(helyettesitett:String,szervegys:String,ev:number,tip:string) : void {
      if (ev==0) return;
      //******************************************************************* */
      this.products = [];
      this.isLoading = true;
      this._service.getengedtable(helyettesitett,szervegys,ev, 0,tip).subscribe(p => {
        this.headerdata = JSON.parse(String(p.header));
        this.bodydata = JSON.parse(String(p.body));
        this.dolgozo = p.dolgozo;
        this.jogcimdata = p.jogcimdata;
        for (let i = 0; i< this.dolgozo.length; i++) {
          if (this.dolgozo[i] != '')
            this.products[i] = true;
        }
        this.isLoading = false;
      });
    }
    onSzervChanged(): void
    {
      this.ToltTabla(this.helyettesitett,this.szerv,this.selectedYear,this.tip);
    }
    ///onhelyettesitettChanged(): void
    //{
//      this.ToltTabla(this.helyettesitett,this.szerv,this.selectedYear,this.tip);
    //}
    onYearChanged(): void
    {
      this.ToltTabla(this.helyettesitett,this.szerv,this.selectedYear,this.tip);
    }
    onMonthChanged(): void
    {
      this.ToltTabla(this.helyettesitett,this.szerv,this.selectedYear,this.tip);
    }
    changedTip(e: any): void {
      if (!e.checked) {
        this.tip = 'sz';
        this.ToltTabla(this.helyettesitett,this.szerv,this.selectedYear,this.tip);
      }
      else {
        this.tip = 'ho';
        this.ToltTabla(this.helyettesitett,this.szerv,this.selectedYear,this.tip);
      }
    }

    changeradio(e: any): void {
      if (e.value=='1') {
        this.tip = 'sz';
        this.ToltTabla(this.helyettesitett,this.szerv,this.selectedYear,this.tip);
      }
      else if (e.value=='2') {
        this.tip = 'ho';
        this.ToltTabla(this.helyettesitett,this.szerv,this.selectedYear,this.tip);
      }
      else if (e.value=='3') {
        this.tip = 'tm';
        this.ToltTabla(this.helyettesitett,this.szerv,this.selectedYear,this.tip);
      }
      else if (e.value=='4') {
        this.tip = 'jo';
        this.ToltTabla(this.helyettesitett,this.szerv,this.selectedYear,this.tip);
      }
    }
    

    onengedely(tip:string): void {
      this.dolgozo_ = [];
      for (let i = 0; i< this.dolgozo.length; i++) {
        if (this.products[i] == true)
          this.dolgozo_.push(this.dolgozo[i])
      }
      if (this.dolgozo_.length == 0) return;
      //************************************************ */
      if (this.tip=='sz')
        this._service.szabiEngedcs(this.selectedYear,this.dolgozo_,tip).subscribe(p => {
          this.ToltTabla(this.helyettesitett,this.szerv,this.selectedYear,this.tip);
        });
      else if (this.tip=='jo')
        this._service.jogcimEngedcs(this.selectedYear,this.dolgozo_,tip).subscribe(p => {
          this.ToltTabla(this.helyettesitett,this.szerv,this.selectedYear,this.tip);
        });
      else
        this._service.hoEngedcs(this.selectedYear,this.dolgozo_,tip,this.tip).subscribe(p => {
          this.ToltTabla(this.helyettesitett,this.szerv,this.selectedYear,this.tip);
        });
    }

    SzervegysVisible() : boolean {
      // return !this._service._Berinst().engedmszerzbe;  
      return false;
    }
    TavmunkaVisible() : boolean {
      return this._service._Berinst().tavmunka;  
    }
    JogcimekVisible() : boolean {
      return this._service._Berinst().engedelykot;  
    }
    JogcimekdataVisible() : boolean {
      return this._service._Berinst().engedelykot && (this.tip=='jo');  
    }

    elsokar(s : any):string {
      if (s.length>7) return '';
      //if (s.length=0) return '';
      return s[0];
    } 
    // helyettesitettVisible() : boolean {
    //   // 
    //   return true;
    // }

  }
  