/*
 * Public API Surface of the common Topsoft library
 */

import {PrivateExportAliasingHost} from "@angular/compiler-cli/src/ngtsc/imports";

// Core
export * from './lib/core/global';
export * from './lib/core/utils';

// Lang
export * from './lib/lang/translate.service';
export * from './lib/lang/translate.pipe';
export * from './lib/lang/lang.component';

// Net
export * from './lib/net/http-sender.service';
export * from './lib/net/online-service';

// Form
export * from './lib/form/formutils';

// Security
export * from './lib/security/auth-params';
export * from './lib/security/md5';
export * from './lib/security/sec-utils';
export * from './lib/security/user';
export * from './lib/security/user.name.component';
export * from './lib/security/auth.guard';
export * from './lib/security/auth.service';

// UI
export * from './lib/ui/confirm.component';
export * from './lib/ui/alert.component';
export * from './lib/ui/login.component';
export * from './lib/ui/top-number.pipe';
export * from './lib/ui/safe-html.pipe';
export * from './lib/ui/ui.service';
export * from './lib/ui/safe-html.component';
export * from './lib/ui/change-password/change-password.component';

// Services
export * from './lib/services/audio.service';
export * from './lib/services/barcode.service';
export * from './lib/services/topSpinner.service';

export * from './lib/ui/spinner/spinner-overlay.service';
export * from './lib/ui/spinner/spinner.interceptor';
export * from './lib/ui/spinner/spinner-overlay.component';

export * from './lib/top-core.module';
