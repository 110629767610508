import { Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TflevitelTolt} from '../model';
import { MatDialogModule,MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import {  MatButtonModule } from '@angular/material/button';
import { szabiinfo, szabiinfo0} from '../model';
import {MatTableModule} from '@angular/material/table';
import { MatTableDataSource } from '@angular/material/table';
import {MatDividerModule} from '@angular/material/divider';
import { AppService } from '../app.service';

@Component({
  selector: 'app-szabiinfomod',
  standalone: true,
  imports: [CommonModule,MatCardModule,MatIconModule,MatDialogModule,MatButtonModule,MatTableModule,MatDividerModule,],
  templateUrl: './szabiinfomod.component.html',
  styleUrls: ['./szabiinfomod.component.css']
})
export class SzabiinfomodComponent {
  dataSource0: MatTableDataSource<szabiinfo0> = new MatTableDataSource<szabiinfo0>([]);
  displayedColumns0: string[] = ['szoveg', 'felh', 'tanul', 'rendk','ossz'];
  dataSource: MatTableDataSource<szabiinfo> = new MatTableDataSource<szabiinfo>([]);
  displayedColumns: string[] = ['ho', 'tol', 'ig', 'nap','kerelem'/*, 'engedely', 'torles', 'visszavonas', 'elutasitas'*/];
  _dolgozo: String = '';
  _mszerz: String = '';
  _szabijelleg: String = " 0";
  istanulDisabled : boolean = false;
  constructor( public dialogRef: MatDialogRef<SzabiinfomodComponent>, @Inject(MAT_DIALOG_DATA) public data: TflevitelTolt,private _service: AppService) {}  

  ngOnInit(): void {
      //**********************************************************
      this._dolgozo = this.data.dolgozo;
      this._mszerz  = this.data.mszerz;
      this.SzabiInfo0Tolt();
      this.SzabiInfoTolt();
      //**********************************************************
  }

  public SzabiInfo0Tolt() {
    this._service.getszabiinfo0(this._dolgozo, this._mszerz).subscribe(p => {
      this.istanulDisabled = true;
      this.dataSource0 = new MatTableDataSource<szabiinfo0>(p);
    });  
  }
  public SzabiInfoTolt() {
    this._service.getszabiinfo(this._dolgozo, this._mszerz, this._szabijelleg).subscribe(p => {
      this.dataSource = new MatTableDataSource<szabiinfo>(p);
    });  
  }


}
