import { Component,OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NapiComponent } from '../napi/napi.component';
import { AppComponent } from '../app.component';

@Component({
  selector: 'app-jelenlet',
  standalone: true,
  imports: [CommonModule,NapiComponent],
  templateUrl: './jelenlet.component.html',
  styleUrls: ['./jelenlet.component.css']
})

export class JelenletComponent implements OnInit {

  constructor(private ap :AppComponent) { this.ap.title = 'Jelenlét';}

  ngOnInit(): void {
    
  }  
}
