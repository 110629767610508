import { Injectable } from "@angular/core";
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import {AuthService, LoginState} from "./auth.service";
import { transpileModule } from "typescript";

@Injectable()
export class AuthGuard 
{
    constructor(private _auth: AuthService, private _router: Router) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot)
    {
        switch(this._auth.state)
        {
            case LoginState.Need2FA:
                if(route.routeConfig?.path === "chk2fa")
                    return true;
                this._router.navigate(["/chk2fa"], { queryParams: { returnUrl: state.url }});
                return false;

            case LoginState.MustChangePassword:
                if(route.routeConfig?.path === "chgpwd")
                    return true;
                this._router.navigate(["/chgpwd"], { queryParams: { returnUrl: state.url }});
                return false;

            case LoginState.LoggedIn:
                return true;

            default:
                if(route.routeConfig?.path === "login")
                    return true;
                this._router.navigate(["/login"], { queryParams: { returnUrl: state.url }});
                return false;
        }
    }
}
