import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule} from '@angular/material/dialog';
import {MatTabsModule} from '@angular/material/tabs';

@Component({
  selector: 'app-helphelp',
  standalone: true,
  imports: [CommonModule,MatDialogModule,MatTabsModule],
  templateUrl: './helphelp.component.html',
  styleUrls: ['./helphelp.component.css']
})
export class HelphelpComponent {
}
