<mat-card class="login-form mat-card-flat" >
    <mat-card-header>
        <mat-card-title>{{'loginHeader'|translate}}</mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <form name="loginForm" novalidate>
            <table cellspacing="0">
                <tr>
                    <td>
                        <mat-form-field appearance="fill">
                            <mat-label>{{'date'|translate}}</mat-label>
                            <input matInput [matDatepicker]="logingDatepicker" name="date" id="date" [(ngModel)]="date" >
                            <mat-datepicker-toggle matSuffix [for]="logingDatepicker"></mat-datepicker-toggle>
                        </mat-form-field>
                        <mat-datepicker #logingDatepicker></mat-datepicker>
                    </td>
                    <td style="padding-left: 15px"><mat-form-field appearance="fill">
                        <mat-label>{{'year'|translate}}</mat-label>
                        <input type="number" matInput min="1997" max="2100" [(ngModel)]="year" name="year" id="year" />
                    </mat-form-field></td>
                </tr>
            </table>

            <table class="login-full-width" cellspacing="0">
                <tr>
                    <td>
                        <mat-form-field appearance="fill">
                            <mat-label><i class="sprite sprite-user"></i>{{'userIdDetail'|translate}}</mat-label>
                            <input matInput name="userId" #userIdCtrl [(ngModel)]="userId" required matInputDirective="focused" />

                        </mat-form-field>
                    </td>
                </tr>
                    <tr>
                        <td>
                            <mat-form-field appearance="fill">
                                <mat-label><i class="sprite sprite-login"></i>{{'passwordDetail'|translate}}</mat-label>
                                <input matInput name="password" type="password" [(ngModel)]="password" required />
                            </mat-form-field>
                        </td>
                    </tr>
                </table>
            <mat-card-actions align="end" >
                <button mat-fab color="primary" (click)="login()"><mat-icon>done</mat-icon></button>
            </mat-card-actions>
        </form>
    </mat-card-content>
</mat-card>
