import {Component, OnInit, Input, ViewChild, ElementRef} from "@angular/core";
import {AuthService} from "../security/auth.service";
import {SecUtils} from "../security/sec-utils";
import {Router} from "@angular/router";
import {DateAdapter} from "@angular/material/core";
import { TranslatePipe } from "../lang/translate.pipe";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatInputModule } from "@angular/material/input";
import { MatFormFieldModule } from "@angular/material/form-field";
import { FormsModule } from "@angular/forms";
import { MatCardModule } from "@angular/material/card";
import { AuthParams, AuthProfile } from "../security/auth-params";

@Component({
    selector: "top-login",
    templateUrl: "./login.component.html",
    standalone: true,
    imports: [MatCardModule, FormsModule, MatFormFieldModule, MatInputModule, MatDatepickerModule, MatButtonModule, MatIconModule, TranslatePipe]
})
export class LoginComponent implements OnInit
{
    @ViewChild("userIdCtrl") userIdCtrl?: ElementRef;
    userId: string = "";
    password: string = "";
    date: Date = new Date();
    year: number = 0;
    profile: AuthProfile = new AuthProfile();

    constructor(private _auth: AuthService, private _router: Router, private _dateAdapter: DateAdapter<Date>) {}

    ngOnInit(): void
    {
        this._dateAdapter.setLocale("hu-HU");
        this.userId = this._auth.userId;
        this.year = this.date.getFullYear();
        if(this.userIdCtrl != undefined)
            this.userIdCtrl.nativeElement.focus();
    }

    public login(): void
    {
        let pars = new AuthParams();
        pars.UserId = this.userId;
        pars.Password = this.password;
        pars.Profile = this.profile;
        pars.Date = this.date;
        pars.Year = this.year;
        this._auth.login(pars).subscribe(() => this._router.navigate(["/"]));
    }
}
