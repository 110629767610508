import {Component} from "@angular/core";
import { MatDialogRef, MatDialogModule } from "@angular/material/dialog";
import { TranslatePipe } from "../lang/translate.pipe";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";

@Component({
    selector: "top-confirm",
    templateUrl: "./confirm.component.html",
    standalone: true,
    imports: [MatDialogModule, MatButtonModule, MatIconModule, TranslatePipe]
})
export class ConfirmComponent
{
    public title: string = "";
    public message: string = "";

    constructor(public dialogRef: MatDialogRef<ConfirmComponent>) {}
}
