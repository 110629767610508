<mat-card class="chgpwd-form mat-card-flat" >
    <mat-card-header>
        <mat-card-title>{{'chgPwdHeader'|translate}}</mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <form [formGroup]="form" name="chgPwdForm">
            <mat-form-field>
                <mat-label><i class="sprite sprite-login"></i></mat-label>
                <input matInput type="password" formControlName="password1" placeholder="{{'password'|translate}}"/>
            </mat-form-field>
            <mat-form-field>
                <mat-label><i class="sprite sprite-login"></i></mat-label>
                <input matInput type="password" formControlName="password2" placeholder="{{'password2'|translate}}"/>
            </mat-form-field>
            <p>{{message}}</p>
            <mat-card-actions align="end" >
                <button mat-fab color="primary" (click)="send()"><mat-icon>done</mat-icon></button>
            </mat-card-actions>
        </form>
    </mat-card-content>
</mat-card>
