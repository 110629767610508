<!-- Szűrők ----------------------------------------->                  <!-- <br> -->       <!-- &nbsp; -->
<app-szurok [item]="item"></app-szurok>
<!-- Naptár ----------------------------------------->
<div class="example-list">
    <button class="box-shadow"  (click)="onValDate()" title="vissza" 
        mwlCalendarPreviousView
        [view]="view"
        [(viewDate)]="viewDate">
        <i class="sprite sprite-backj" alt="" style="transform: scale(0.7);"></i>
    </button>
    &nbsp;
    &nbsp;
    <h3 class="example-list0">{{ viewDate | calendarDate:(view + 'ViewTitle'):'hu' }}</h3>
    &nbsp;
    &nbsp;
    <button class="box-shadow"  (click)="onValDate()" title="előre"
        mwlCalendarNextView
        [view]="view"
        [(viewDate)]="viewDate">
        <i class="sprite sprite-forwardj" alt="" style="transform: scale(0.7);"></i>
    </button>
    &nbsp;
    &nbsp;
    &nbsp;
    <mat-slide-toggle class="select-slide-toggle"  *ngIf="kijelolvisible()"
        (change)="changedSelect($event)"  [(ngModel)]="selected_"  name="myselected">Kijelöl
    </mat-slide-toggle>
    &nbsp;
    &nbsp;
    &nbsp;
    <mat-slide-toggle class="select-slide-toggle"  *ngIf="kijelolvisible()"
    (change)="changedTolIg($event)" [(ngModel)]="tolig_"  name="mytolig">Tól-Ig
    </mat-slide-toggle>
</div>
<!-- <br> -->
<div class="example-list4">

        <!-- Szabadág gyors felvitel -->
        <!-- felvitelGyors(felvitelGyorsParams:TfelvitelGyorsParams;maszk:Tmaszk) -->
        <button class="box-shadow" (click)="onInsertMunkaidoSzabi('szabi')" title="szabadság gyors" [disabled]="isDisabled" *ngIf="szabivisible()">
             <i class="{{pic[2]}}"></i>
        </button>

        <!-- Jogcím felvitel(dialogból) -->
        <!-- jelenlettran(data:TflevitelTolt;maszk:Tmaszk) -->
        <button class="box-shadow"  (click)="openDialogFelvitel(-1,-1)" title="felvitel" [disabled]="isDisabled" *ngIf="felvitelvisible()">
            <i class="{{pic[0]}}"></i>
        </button>

        <!-- Munkaidő gyors felvitel -->
        <!-- felvitelGyors(felvitelGyorsParams:TfelvitelGyorsParams;maszk:Tmaszk) -->
        <button class="box-shadow"  (click)="onInsertMunkaidoSzabi('munkaido')" title="munkaidő gyors" [disabled]="isDisabled" *ngIf="munkaidovisible()">
            <i class="{{pic[1]}}"></i>
        </button>

        <!-- Jelnlét(munkaidő/szabadság) gyors törlés -->
        <!-- deleteGyors(felvitelGyorsParams:TfelvitelGyorsParams;maszk:Tmaszk) -->
        <button class="box-shadow"  (click)="onDelete('delete')" title="törlés" [disabled]="isDisabled"  *ngIf="deletevisible()">
            <i class="{{pic[4]}}"></i>
        </button>    

        <!-- Homeoffice felvitel/törlés -->
        <!-- hoGyors(felvitelGyorsParams:TfelvitelGyorsParams;maszk:Tmaszk) -->
        <button class="box-shadow"  (click)="onhoGyors('felvitel')" title="homeoffice" [disabled]="isDisabled" *ngIf="hovisible()">
            <i class="{{pic[3]}}"></i>
        </button>
        <button class="box-shadow"  (click)="onhoGyors('torles')" title="homeoffice törlés" [disabled]="isDisabled" *ngIf="hoGyorsvisible()">
            <i class="{{pic[5]}}"></i>
        </button>
        <!-- Távmunka felvitel/törlés -->
        <!-- hoGyors(felvitelGyorsParams:TfelvitelGyorsParams;maszk:Tmaszk) -->
        <button class="box-shadow"  (click)="ontmGyors('felvitel')" title="távmunka" [disabled]="isDisabled" *ngIf="tmvisible()">
            <i class="{{pic[6]}}"></i>
        </button>
        <button class="box-shadow"  (click)="ontmGyors('torles')" title="távmunka törlés" [disabled]="isDisabled" *ngIf="tmGyorsvisible()">
            <i class="{{pic[7]}}"></i>
        </button>

        <!-- Engedélyezések/Elutasítások -->
        <!-- szabiEnged(felvitelGyorsParams:TfelvitelGyorsParams;maszk:Tmaszk) -->
        <!-- hoEnged(felvitelGyorsParams:TfelvitelGyorsParams;maszk:Tmaszk;tip:string) -->
        <button class="box-shadow"  (click)="onengedely('enged')" title="engedélyez" *ngIf="engedelyvisible()">
            <i class="sprite sprite-engedely"></i>
        </button>
        <button class="box-shadow"  (click)="onengedely('elut')" title="elutasít"  *ngIf="elutasitvisible()">
            <i class="sprite sprite-elutasit"></i>
        </button>

        <!-- Szabadság infó -->
        <button class="box-shadow"  (click)="szabiinfo()" title="infó" *ngIf="infovisible()">
            <i class="sprite sprite-infoj"></i>
        </button>

        <!-- Jelenlét lista -->
        <button class="box-shadow"  (click)="pdf()" title="pdf" *ngIf="pdfvisible()">
            <i class="sprite sprite-pdf"></i>
        </button>

        <!-- Lezárás -->
        <mat-slide-toggle class="select-slide-toggle" *ngIf="LezarasKell()" [disabled]="szamfejtve"
        (change)="changedLezaras($event)"  [(ngModel)]="lezaras"  name="mylezaras">{{lezaras_}}
        </mat-slide-toggle>
</div>

<ng-template #customCellTemplate let-day="day" let-locale="locale">
    <div class="{{MunkanapSelectMaszk(day.date)}}">
        <div class="cal-cell-top">
            <div class="image" *ngIf="TervDont(day.date)"><i class="sprite sprite-szabadsag_terv" [style]="margin2px"
                    [style.transform]="scaleXY"></i></div>
                <div class="image2" *ngIf="HoDont(day.date)"><i class="{{HoTmIcon(day.date)}}" [style]="margin2px"
                        title="{{HoDonSzovt(day.date)}}"></i></div>
                <span class="cal-day-number">{{ day.date | calendarDate:'monthViewDayNumber':locale }}</span>
        </div>
        <!--<small style="margin: 5px">There are {{ day.events.length }} events on this day</small>-->
        <div class="container">
            <div class="image" *ngIf="IconDont(day.date,0)"><i class="sprite sprite-munkanap" [style]="margin2px"
                    [style.transform]="scaleXY"></i></div>
            <div class="image" *ngIf="IconDont(day.date,1)"><i class="sprite sprite-unnep" alt="" [style]="margin2px"
                    [style.transform]="scaleXY"></i></div>
            <div class="image" *ngIf="IconDont(day.date,2)"><i class="{{SzabiIkon(day.date,3)}}" [style]="margin2px"
                    title="{{IconDontSzov(day.date,3)}}" [style.transform]="scaleXY"></i></div>
            <div class="image" *ngIf="IconDont(day.date,4)"><i class="sprite sprite-betegseg" alt="" [style]="margin2px"
                    [style.transform]="scaleXY"></i></div>
            <div class="image" *ngIf="IconDont(day.date,5)"><i class="sprite sprite-oktatas" alt="" [style]="margin2px"
                    [style.transform]="scaleXY"></i></div>
            <div class="image" *ngIf="IconDont(day.date,6)"><i class="sprite sprite-tavollet" alt="" [style]="margin2px"
                    [style.transform]="scaleXY"></i></div>
            <div class="image" *ngIf="IconDont(day.date,7)"><i class="{{EgyebIkon(day.date,3)}}" [style]="margin2px"
                    title="{{IconDontSzov(day.date,3)}}" [style.transform]="scaleXY"></i></div>
                <!-- <div class="image" *ngIf="IconDont(day.date,7)"><i class="sprite sprite-egyeb" alt="" [style]="margin2px"
                    [style.transform]="scaleXY"></i></div> -->

            <!-- <div class="image2" *ngIf="HoDont(day.date)"><i class="{{HoTmIcon(day.date)}}" [style]="margin2px"
                    title="{{HoDonSzovt(day.date)}}" [style.transform]="scaleXY"></i></div> -->
        </div>
    </div>
</ng-template>

<!-- <ng-template
      #openDayEventsTemplate
      let-events="events"
      let-eventClicked="eventClicked">
      <div
        *ngFor="let event of events"
        [ngClass]="event?.cssClass">
        <span
          class="cal-event"
          [style.backgroundColor]="event.color.primary">
        </span>
        <mwl-calendar-event-title
          [event]="event"
          view="month"
          (mwlClick)="eventClicked.emit({event: event})">
        </mwl-calendar-event-title>
        <mwl-calendar-event-actions [event]="event"></mwl-calendar-event-actions>
      </div>
</ng-template> -->

<div [ngSwitch]="view">
  <mwl-calendar-month-view
    *ngSwitchCase="CalendarView.Month"
    [locale]="locale"
    [viewDate]="viewDate"
    [events]="events"
    [refresh]="refresh"
    (dayClicked)="dayClicked($event.day)"
    [weekStartsOn]=1	
    [activeDayIsOpen]="activeDayIsOpen"
    [cellTemplate]="customCellTemplate"
    >
  </mwl-calendar-month-view>
  <mwl-calendar-week-view
    *ngSwitchCase="CalendarView.Week"
    [locale]="locale"
    [viewDate]="viewDate"
    [events]="events"
    [refresh]="refresh"
    [weekStartsOn]=1 
    >
  </mwl-calendar-week-view>
  <mwl-calendar-day-view
    *ngSwitchCase="CalendarView.Day"
    [locale]="locale"
    [viewDate]="viewDate"
    [events]="events"
    [refresh]="refresh"
  >
  </mwl-calendar-day-view>
</div>

<div class="example-list">
    <mat-button-toggle-group name="fontStyle" aria-label="Font Style" appearance="legacy"> 
        <mat-button-toggle title="hó"
            (click)="setView(CalendarView.Month)"
            [class.active]="view === CalendarView.Month">
            <i class="sprite sprite-monthview"></i>
        </mat-button-toggle>
        <mat-button-toggle title="hét"
            (click)="setView(CalendarView.Week)"
            [class.active]="view === CalendarView.Week">
            <i class="sprite sprite-weekview"></i>
        </mat-button-toggle>
        <mat-button-toggle title="nap"
            (click)="setView(CalendarView.Day)"
         [class.active]="view === CalendarView.Day">
         <i class="sprite sprite-dayview"></i>
        </mat-button-toggle>
    </mat-button-toggle-group>
</div>

<!-- [openDayEventsTemplate]="openDayEventsTemplate" -->