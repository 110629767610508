import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { provideRouter } from '@angular/router';

import { routes } from './app.routes';
import { provideAnimations } from '@angular/platform-browser/animations';
import { TopCoreModule } from 'top-core';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';


export const appConfig: ApplicationConfig = {
  providers: [
    importProvidersFrom(TopCoreModule.forRoot(), CalendarModule.forRoot({
        provide: DateAdapter,
        useFactory: adapterFactory,
      })), 
    { provide: MAT_DATE_LOCALE, useValue: 'hu-HU' },
    provideRouter(routes), provideAnimations()
]
};
