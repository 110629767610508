import { Component } from '@angular/core';
import {FormBuilder, Validators, FormGroup, FormsModule, ReactiveFormsModule} from "@angular/forms";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { TranslatePipe } from "../../lang/translate.pipe";
import { AuthService } from '../../security/auth.service';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatCardModule } from '@angular/material/card';
import { Router, RouterModule } from '@angular/router';

interface Values
{
    password1: string;
    password2: string;
}

@Component({
  selector: 'change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css'],
  standalone: true,
  imports: [MatCardModule, FormsModule, MatFormFieldModule, MatInputModule, MatButtonModule, MatIconModule, TranslatePipe, RouterModule, ReactiveFormsModule]
})
export class ChangePasswordComponent
{
    form = this.fb.group({
      password1: ["", Validators.required],
      password2: ["",  Validators.required]
  });

  public title: string = "";
  public message: string = "";

  constructor(private fb: FormBuilder, private _router: Router, private _auth: AuthService) {}

  send(): void
  {
      const v: Values = <Values> this.form.value;

      if(v.password1 != v.password2)
      {
        this.message = "A jelszavak nem egyeznek meg";
        return;
      }
      this._auth.changePassword(v.password1).subscribe(() => {
        this._router.navigate(["/"]);
      })
  }
}
