import { Injectable } from "@angular/core";
import { Subscription } from "rxjs";
import { SpinnerOverlayService } from '../ui/spinner/spinner-overlay.service';

@Injectable()
export class topSpinnerService {

disableInterceptor = true;
spinnerSubscription?: Subscription;

constructor(private spinnerOverlayService: SpinnerOverlayService) { }

show(){
   this.spinnerSubscription = this.spinnerOverlayService.spinner$.subscribe();
}
hide(){
  if (this.spinnerSubscription)
      this.spinnerSubscription.unsubscribe();
}
disableHttpIntercept() {
   this.disableInterceptor = true;        
}

enableHttpIntercept() {
  this.disableInterceptor = false;
}
}