import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { topSpinnerService } from '../../services/topSpinner.service';
import { SpinnerOverlayService } from './spinner-overlay.service';

@Injectable()
export class SpinnerInterceptor implements HttpInterceptor {
  constructor(private readonly spinnerOverlayService: SpinnerOverlayService, private topspinner: topSpinnerService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {        
    
    //igy lehet majd kikapcsolni, peldaul a vondalkod olvasasnal nem lenne szabad mukodnie!
    //const methods = ['POST', 'PUT', 'PATCH', 'DELETE'];
    //methods.indexOf(req.method) === -1
    if (this.topspinner.disableInterceptor) {
        return next.handle(req);
    }
    const spinnerSubscription: Subscription = this.spinnerOverlayService.spinner$.subscribe();
    return next
      .handle(req)
      .pipe(finalize(() => spinnerSubscription.unsubscribe()));
  }
}
