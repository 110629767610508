<div class="example-list"> <!-- <br> --> <!-- &nbsp; -->

    <mat-radio-group [(ngModel)]="selected_" (change)="changeradio($event)" name="myselectedradio">
        <mat-radio-button value="1">Szabadság</mat-radio-button>
        <mat-radio-button *ngIf="JogcimekVisible()" value="4">Jogcímek</mat-radio-button>
        <mat-radio-button value="2">Homeoffice</mat-radio-button>
        <mat-radio-button *ngIf="TavmunkaVisible()" value="3">Távmunka</mat-radio-button>
    </mat-radio-group>    

    <!-- <mat-slide-toggle [(ngModel)]="selected_" name="myselected"
        (change)="changedTip($event)">Homeoffice</mat-slide-toggle> -->
    &nbsp;&nbsp;&nbsp;
    <mat-form-field class="my-select-panel-class" *ngIf="SzervegysVisible()">
        <mat-label>Szervezetiegység</mat-label>
        <mat-select [(value)]="szerv" (selectionChange)="onSzervChanged()">
            <mat-option class="my-select-class" *ngFor="let sz of szervegysek" [value]="sz.key">
                {{sz.text}}
            </mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field class="my-select-panel-class2">
        <mat-label>Év</mat-label>
        <mat-select [(value)]="selectedYear" (selectionChange)="onYearChanged()">
            <mat-option class="my-select-class" *ngFor="let year of years" [value]="year.y1">
                {{year.y2}}
            </mat-option>
        </mat-select>
    </mat-form-field>

    &nbsp;&nbsp;&nbsp;

    <button class="box-shadow"  (click)="onengedely('enged')" title="engedélyez">
        <i class="sprite sprite-engedely"></i>
    </button>
    &nbsp;&nbsp;&nbsp;
    <button class="box-shadow"  (click)="onengedely('elut')" title="elutasít">
        <i class="sprite sprite-elutasit"></i>
    </button>

    <!-- &nbsp;&nbsp;&nbsp; -->
    <!-- Helyettesitett szótár -------------------------------------------------------------------------------------------->
    <!-- <mat-form-field class="my-select-panel-class" *ngIf="helyettesitettVisible()">
        <mat-label>Helyettesített</mat-label>
        <mat-select [(value)]="helyettesitett" (selectionChange)="onhelyettesitettChanged()">
            <mat-option class="my-select-class" *ngFor="let sz of helyettesitetttomb" [value]="sz.key">
                {{sz.text}}
            </mat-option>
        </mat-select>
    </mat-form-field> -->
    <!-- Helyettesitett szótár vége ---------------------------------------------------------------------------------------->

</div>

<div>
    <div style="overflow-x:scroll;">
        <table>
            <tr>
                <th class="h1" *ngFor="let col of headerdata">{{col}}</th>
                <th> </th>
            </tr>
            <tr *ngFor="let item of bodydata;let i=index">
                <td class="h2" *ngFor="let field of item|keyvalue;let j=index"
                    [ngClass]="{
                                'lightcyan':field.value == 'P', 
                                'orange':elsokar(field.value) == 'K',
                                'orange2':elsokar(field.value) == 'V',
                                'green':elsokar(field.value) == 'E',
                                'white':field.value  == ''
                               }">
                    {{field.value}}</td>
                <td><input type="checkbox" value="" [(ngModel)]="products[i]" name="mycheckbox"></td>
            </tr>
        </table>
    </div>
</div>
<button class="green2">Engedélyezett</button>
<button class="orange">K : Kérelmezett</button>
<button class="orange">V : Visszavont</button>
&nbsp;
<div *ngFor="let item of jogcimdata;let i=index">
    <button *ngIf="JogcimekdataVisible()"  class="black">{{item}}</button>
</div>
&nbsp;&nbsp;

