<div class="example-list"> <!-- <br> --> <!-- &nbsp; -->
    <div *ngIf="isLoading" style="display: flex; justify-content: center; align-items: center;zoom: 0.6">
        <mat-progress-spinner color="primary" mode="indeterminate">
        </mat-progress-spinner>
    </div>
    <!-- <mat-slide-toggle [checked]="false" (change)="changedTip($event)">Homeoffice</mat-slide-toggle>
    &nbsp;&nbsp;&nbsp; -->
    <mat-form-field class="my-select-panel-class"  *ngIf="SzervegysVisible()">
        <mat-label>Szervezetiegység</mat-label>
        <mat-select [(value)]="szerv" (selectionChange)="onSzervChanged()">
            <mat-option class="my-select-class" *ngFor="let sz of szervegysek" [value]="sz.key">
                {{sz.text}}
            </mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field class="my-select-panel-class2">
        <mat-label>Év</mat-label>
        <mat-select [(value)]="selectedYear" (selectionChange)="onYearChanged()">
            <mat-option class="my-select-class" *ngFor="let year of years" [value]="year.y1">
                {{year.y2}}
            </mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field class="my-select-panel-class3">
        <mat-label>Hó</mat-label>
        <mat-select [(value)]="selectedMonth" (selectionChange)="onMonthChanged()">
          <mat-option class="my-select-class" *ngFor="let month of months" [value]="month.y1">
            {{month.y2}}
          </mat-option>
        </mat-select>
      </mat-form-field>
        
    &nbsp;&nbsp;&nbsp;

    <button class="box-shadow"  (click)="onengedely()" title="zár mind">
        <i class="sprite sprite-lock"></i>
    </button>
    &nbsp;&nbsp;&nbsp;
    <button class="box-shadow"  (click)="onengedely2()" title="nyit mind">
        <i class="sprite sprite-unlock"></i>
    </button>
</div>

<div>
    <div style="overflow-x:scroll;">
        <table>
            <tr>
                <th class="h1" *ngFor="let col of headerdata">{{col}}</th>
            </tr>
            <tr *ngFor="let item of bodydata;let i=index">
                <td class="h2" *ngFor="let field of item|keyvalue"
                [ngClass]="{
                'p':field.value  == 'P',
                's':field.value  == 'S',
                'm':field.value  == 'M',
                'u':field.value  == 'Ü',
                'b':field.value  == 'B',
                't':field.value  == 'T',
                'w':field.value  == ''
               }">
              {{field.value}}</td>
                <!-- <td class="h2"><input  class="h2" type="checkbox" *ngIf="dolgozo[i]!=''" value="" [(ngModel)]="products[i]" name="mycheckbox"></td>
                <td class="h2"><input  class="h2" type="checkbox" *ngIf="dolgozo[i]!=''" value="" [(ngModel)]="products[i]" name="mycheckbox"></td> -->
                <mat-slide-toggle *ngIf="dolgozo[i]!=''" (change)="changedZar($event,i)"  [(ngModel)]="products[i]"  name="mycheckbox"></mat-slide-toggle>
            </tr>
        </table>
    </div>
</div>
<button class="m">M : Munkanap</button>
<button class="u">Ü : Ünnepnap</button>
<button class="s">S : Szabadság</button>
<button class="b">B : Betegség</button>
<button class="t">T : Távollét</button> 
&nbsp;&nbsp;