/**
 * Created by lendvai on 2017.05.18.
 */

import {Utils} from "../core/utils";

/**
 * Security utility methods
 */
export class SecUtils
{
    private static permutation =
    [
        255,112,65,83,230,42,236,253,231,253,179,177,77,251,207,177,121,22,139,183,
        214,175,32,190,13,177,122,151,207,102,91,210,7,202,19,225,217,130,88,247,50,
        64,77,161,88,200,99,125,60,68,33,33,251,45,106,144,80,119,87,73,104,60,81,97,
        211,157,96,153,8,23,104,247,59,245,92,203,239,40,250,161,15,49,62,21,237,94,
        3,1,254,11,15,1,176,29,1,188,29,126,80,242,23,189,232,201,63,156,124,66,154,
        183,51,98,114,6,175,252,213,105,29,144,154,255,98,67,49,124,236,93,42,43,87,
        76,181,239,106,160,4,221,148,251,232,187,116,109,109,21,139,41,229,27,175,37,
        44,17,89,125,129,113,93,38,199,138,47,235,149,208,75,23,217,136,207,217,111,
        33,233,215,144,216,48,215,86,237,121,15,73,35,69,1,56,189,100,97,71,170,220,
        0,119,42,175,49,147,149,161,163,208,126,137,30,244,241,70,39,105,252,2,6,9,
        67,190,13,251,201,220,190,134,191,167,159,30,113,129,91,145,36,201,11,176,14,
        17,39,56,42,187,37,103,17,153,40,15,163,165,198,117,167,102,87
    ];

    private static randomValues = [23,234,56,122,99,187,65,214];

    private static permutate(buid: number[], bpwd: number[]): number[]
    {
        const s = [];
        var i : number;

        for(i = 0; i < 8; i++)
            s.push(Utils.rot((buid[i] ^ this.randomValues[i]) & 0xff, bpwd[i] % 8));

        for(i = 8; i < 64; i++)
            s.push((this.permutation[Utils.rot(s[i - 1], s[i - 8] % 8)] ^ Utils.rot(s[i - 8], s[i - 1] % 8) ^ bpwd[i % 8]));

        const ret = [];
        for(i = 0; i < 8; i++)
            ret.push(s[56 + i]);
    
        return ret;
    }

    private static xor(arr1: number[], arr2: number[]): number[]
    {
        const ret = [];
        for(let i = 0; i < 8; i++)
            ret.push(arr1[i] ^ arr2[i]);
        return ret;
    }   
    
    private static bin2text(arr: number[]): string
    {
        let ret = "";
        const chA = Utils.ascii("A");
        for(let i = 0; i < 8; i++)
            ret += String.fromCharCode((arr[i] >> 4) + chA) + String.fromCharCode((arr[i] & 0x0f) + chA);

        return ret;
    }

    /**
     * One direction password projection
     *
     * @param uid [string]  user identifier
     * @param pwd [string]  user password
     *
     * @return [string] the encoded text
     */
    public static passwordProject(uid: string, pwd: string): string
    {
        var encoder = new TextEncoder();
        const uidBuffer = encoder.encode(uid);
        const pwdBuffer = encoder.encode(pwd);

        var nuid = uidBuffer.length;
        var npwd = pwdBuffer.length;
        const buid: number[] = [0, 0, 0, 0, 0, 0, 0, 0];
        const bpwd: number[] = [0, 0, 0, 0, 0, 0, 0, 0];
        var iuid = 0, ipwd = 0;
        let perm = [0, 0, 0, 0, 0, 0, 0, 0];
        while(nuid > 0 || npwd > 0)
        {
            buid.fill(0);
            let n = nuid;
            if(n > 8)
                n = 8;
            for(let i = 0; i < n; i++)
                buid[i] = uidBuffer[iuid++];
            nuid -= n;

            bpwd.fill(0);
            n = npwd;
            if(n > 8)
                n = 8;
            for(let i = 0; i < n; i++)
                bpwd[i] = pwdBuffer[ipwd++];
            npwd -= n;

            const p = this.permutate(buid, bpwd);
            perm = this.xor(perm, p);
        }   
    
        return this.bin2text(perm);
    };


    /**
     * Is any of the source group is contained in the target groups?
     *
     * @param group {String}    group name
     * @param groups {String}   comma separated list of groups
     *
     * @returns {Boolean} true if in group
     */
    public static inGroup(group: string, groups: string): boolean
    {
        if(!group || !group.length)
            return !groups;
        if(!groups || !groups.length)
            return true;
        const ugroups = groups.split(",");
        const xgroup = group.split(",");
        for(let j = 0; j < xgroup.length; j++)
        {
            const grp = xgroup[j];
            for(let i = 0; i < ugroups.length; i++)
                if(ugroups[i] === grp)
                    return true;
        }
        return false;
    }

    /**
     * Add a group to a groups
     *
     * @param group {String}    group name
     * @param groups {String}   comma separated list of groups
     *
     * @return  string the updated groups
     */
    public static addGroup(group: string, groups: string): string
    {
        if(!group || !group.length)
            return groups;
        if(groups && groups.length)
        {
            const ugroups = groups.split(",");
            for(let i = 0; i < ugroups.length; i++)
                if(ugroups[i] === group)
                    return groups;
            return groups + "," + group;
        }
        return group;
    }

    /**
     * Remove group from groups
     *
     * @param   group     user group
     * @param   groups    comma separated list of groups
     * @return  string the updated groups
     */
    public static removeGroup(group: string, groups: string): string
    {
        if(!group || !groups || !groups.length)
            return groups;
        const ugroups = groups.split(",");
        for(let i = 0; i < ugroups.length; i++)
            if(ugroups[i] === group)
            {
                ugroups.splice(i, 1);
                return ugroups.join();
            }
        return groups;
    }
}
