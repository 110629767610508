import {APP_INITIALIZER, ModuleWithProviders, NgModule} from '@angular/core';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";

import {TranslatePipe} from "./lang/translate.pipe";
import {TopNumberPipe} from "./ui/top-number.pipe";
import {LangComponent} from "./lang/lang.component";
import {ConfirmComponent} from "./ui/confirm.component";
import {AlertComponent} from "./ui/alert.component";
import {LoginComponent} from "./ui/login.component";
import {UserNameComponent} from "./security/user.name.component";
import {CommonModule, DatePipe, DecimalPipe} from "@angular/common";
import {TranslateService} from "./lang/translate.service";
import {HttpSenderService} from "./net/http-sender.service";
import {AuthService} from "./security/auth.service";
import {UiService} from "./ui/ui.service";
import {AuthGuard} from "./security/auth.guard";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatNativeDateModule} from "@angular/material/core";
import {MatMenuModule} from "@angular/material/menu";
import {MatSliderModule} from "@angular/material/slider";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatInputModule} from "@angular/material/input";
import {MatButtonToggleModule} from "@angular/material/button-toggle";
import {MatSelectModule} from "@angular/material/select";
import {MatRadioModule} from "@angular/material/radio";
import {MatSnackBarModule} from "@angular/material/snack-bar";
import {LayoutModule} from "@angular/cdk/layout";
import {MatCardModule} from "@angular/material/card";
import {MatToolbarModule} from "@angular/material/toolbar";
import {MatButtonModule} from "@angular/material/button";
import {MatSidenavModule} from "@angular/material/sidenav";
import {MatIconModule} from "@angular/material/icon";
import {MatListModule} from "@angular/material/list";
import {MatDialogModule} from "@angular/material/dialog";
import { SafeHtmlPipe } from './ui/safe-html.pipe';
import {SafeHtmlComponent} from "./ui/safe-html.component";
import {SpinnerInterceptor} from "./ui/spinner/spinner.interceptor";
import {SpinnerOverlayService} from "./ui/spinner/spinner-overlay.service";
import {SpinnerOverlayComponent} from "./ui/spinner/spinner-overlay.component";
import {OnlineOfflineService} from "./net/online-service";
import {AudioService} from "./services/audio.service";
import {BarcodeService} from "./services/barcode.service";
import { BrowserCacheLocation, IPublicClientApplication, InteractionType, LogLevel, PublicClientApplication } from '@azure/msal-browser';
import { MSAL_INSTANCE, MsalGuardConfiguration, MsalInterceptorConfiguration, MsalModule, MsalRedirectComponent, MsalService } from '@azure/msal-angular';
import { topSpinnerService } from "./services/topSpinner.service";
import { ChangePasswordComponent } from './ui/change-password/change-password.component';

export function MsalInstanceFactory(): IPublicClientApplication{
    const msalConfig = {
        auth:{ clientId: '21548742-8974-497f-8bca-d6b84d85b85c',
        authority: 'https://login.microsoftonline.com/organizations', 
        redirectUri:'http://localhost:1988'}
    };// fd815473-bd85-4208-bb98-6aa047c8ce59 organizations
    
    const msalInstance = new PublicClientApplication(msalConfig);
//    msalInstance.initialize();
    
    return msalInstance;    
}
export function loggerCallback(logLevel: LogLevel, message: string)
{
    console.log(message);
}
export function MSALInstanceFactory(): IPublicClientApplication
{
    return new PublicClientApplication({
        auth: {
            clientId: '',
            authority: '',
            redirectUri: '',
            postLogoutRedirectUri: ''
        },
        cache: {
            cacheLocation: BrowserCacheLocation.LocalStorage
        },
        system: {
            allowNativeBroker: false, // Disables WAM Broker
            loggerOptions: {
                loggerCallback,
                logLevel: LogLevel.Info,
                piiLoggingEnabled: false
            }
        }        
    });
}
  
export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration
{
    const protectedResourceMap = new Map<string, Array<string>>([["https://graph.microsoft.com/v1.0/me", ["user.read"]]]);
    return {
        interactionType: InteractionType.Popup,
        protectedResourceMap
    };
}
    
export function MSALGuardConfigFactory(): MsalGuardConfiguration
{
    return { 
        interactionType: InteractionType.Popup,
        authRequest: {
            scopes: ["user.read"]
        },
        loginFailedRoute: '/login'
    };
}

@NgModule({ exports: [
        TranslatePipe,
        TopNumberPipe,
        SafeHtmlPipe,
        LangComponent,
        ConfirmComponent,
        AlertComponent,
        LoginComponent,
        UserNameComponent,
        SafeHtmlComponent,
        ChangePasswordComponent
    ],
    declarations: [], imports: [CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatNativeDateModule,
        MatMenuModule,
        MatSliderModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatDatepickerModule,
        MatInputModule,
        MatButtonToggleModule,
        MatSelectModule,
        MatRadioModule,
        MatSnackBarModule,
        MatDialogModule,
        LayoutModule,
        MatCardModule,
        MatToolbarModule,
        MatButtonModule,
        MatSidenavModule,
        MatIconModule,
        MatListModule,
        MatDialogModule,
        TranslatePipe,
        TopNumberPipe,
        LangComponent,
        ConfirmComponent,
        AlertComponent,
        LoginComponent,
        UserNameComponent,
        SafeHtmlPipe,
        SafeHtmlComponent,
        SpinnerOverlayComponent,
        ChangePasswordComponent,
        MsalModule], providers: [provideHttpClient(withInterceptorsFromDi())] })
export class TopCoreModule {
    static forRoot(): ModuleWithProviders<TopCoreModule>
    {
        return {
            ngModule: TopCoreModule,
            providers: [
                {
                    provide: MSAL_INSTANCE,
                    useFactory: MSALInstanceFactory
                },
                MsalService,
                DecimalPipe, DatePipe,
                TranslateService, HttpSenderService, OnlineOfflineService, AuthService, UiService, AuthGuard,
                AudioService, BarcodeService, topSpinnerService,
                SpinnerOverlayService,
                {
                    // ez vonalkod olvasos weboldalaknal nem jo, illetve ott ki kellene tudni kapcsolni (az spinner.interceptor.ts-ben!)
                    provide: HTTP_INTERCEPTORS,
                    useClass: SpinnerInterceptor,
                    multi: true
                }
            ]
        };
    }
}
