/**
 * Created by lendvai on 2017.05.17.
 */

import {Md5} from "./md5";
import {SecUtils} from "./sec-utils";
import {Utils} from "../core/utils";

export enum AuthMethod
{
    Unknown,
    Topinfo,
    OpSystem,
    Domain,
    Szovetseg,
    Msal
}

/**
 *  Authentication profile
 */
export class AuthProfile
{
    /// <summary>
    /// Profile name
    /// </summary>
    name: string = "";

    /// <summary>
    /// Login authentication method
    /// </summary>
    authMethod: AuthMethod = AuthMethod.Unknown;

    /// <summary>
    /// 2 factor authentication needed?
    /// </summary>
    need2FA: boolean = false;

    /// <summary>
    /// OIDC client identifier
    /// </summary>
    clientId: string = "";

    /// <summary>
    /// OIDC tenant identifier or "organizations" (default) or "common"
    /// </summary>
    tenant: string = "";

    /// <summary>
    /// OIDC redirect URI address
    /// </summary>
    callbackUrl: string = "";    
}

/**
 * Authentication parameters
 */
export class AuthParams
{
    /**
     * User login identifier
     */
    public UserId: string = "";

    /**
     * OIDC token
     */
    public IdToken: string = "";

    /**
     * OAUTH2 access token
     */
    public AccessToken: string = "";

    /**
     * User login password
     */
    public Password: string = "";

    /**
     * profile alias
     */
    public Profile?: AuthProfile;

    /**
     * Login date (actual date if not set)
     */
    public Date?: Date;

    /**
     * Login year (actual year if not set)
     */
    public Year?: number;

    /**
     * Authentication method
     */
    public Auth?: AuthMethod;

    public constructor()
    {
        this.Date = new Date();
        this.Year = this.Date.getFullYear();
    }

    /**
     * Get response header
     *
     * @param nonce seed value from the server
     */
    responseHeader(nonce: string): string
    {
        let rsp = "";
        let uid = this.UserId;
        if(!uid)
            uid = sessionStorage.getItem("userId") ?? "";
        const uidUpper = uid.toUpperCase();
        let authMethod = this.Profile ? this.Profile.authMethod : AuthMethod.Topinfo;
        if(authMethod == AuthMethod.Unknown)
            authMethod = AuthMethod.Topinfo;
        switch(authMethod)
        {
            case AuthMethod.Topinfo:
                const pwd = SecUtils.passwordProject(uidUpper, this.Password);
                rsp = "TopDigest " +
                    "nonce=\"" + nonce + "\"" +
                    ", username=\"" + Utils.myUrlEncode(uidUpper) + "\"" +
                    ", response=\"" + Md5.md5(uidUpper + ":" + nonce + ":" + pwd) + "\"";
                break;

            case AuthMethod.Msal:
                rsp = "TopOIDC " +
                      "response=\"" + this.IdToken + "\"" +
                      ", acess=\"" + this.AccessToken + "\"";
                break;

            default:
                rsp = "TopAuth username=\"" + Utils.myUrlEncode(uid) + "\", response=\"" + Utils.myUrlEncode(this.Password) + "\"";
                break;
        }

        if(this.Date)
        {
            var d = this.Date;
            rsp += ", Date=" + d.toISOString();
        }

        if(this.Profile && this.Profile.name)
            rsp = rsp + ", Profile=\"" + this.Profile.name + "\"";

        for(const id in this)
            if(id !== "UserId" && id !== "Password" && id !== "Date" && id !== "Profile" &&  this.hasOwnProperty(id))
            {
                const v = this[id];
                if(v)
                    rsp = rsp + ", " + id + "=\"" + encodeURIComponent(String(v)) + "\"";
            }
        return rsp;
    }

    /**
     * Can send authentication?
     */
    get canAuth(): boolean
    {
        let authMethod = this.Profile ? this.Profile.authMethod : AuthMethod.Topinfo;
        if(authMethod == AuthMethod.Unknown)
            authMethod = AuthMethod.Topinfo;
        if(authMethod == AuthMethod.Topinfo)
            return this.UserId.length > 0 && this.Password.length > 0;
        return true;
    }
}

