<div class="header mat-elevation-z6" >
    <mat-toolbar color="primary"  class="mytoolbar">
        <mat-toolbar-row>
            <button mat-icon-button (click)="drawer.toggle()" style="margin-right: 15px" *ngIf="isLoggedIn"><mat-icon>menu</mat-icon></button>
            <!-- <i class='sprite sprite-logo no-xs' title="{{'moveTopsoft'|translate}}"></i> -->
            <h4 class='mainTitle'>{{title}}</h4>
            <span class="top-spacer"></span>
            <ng-container *ngIf="isLoggedIn">
                <button mat-icon-button (click)="help()"><mat-icon>question_mark</mat-icon></button>
                <button mat-icon-button (click)="openPersonPrefs()"><mat-icon cdkOverlayOrigin #trigger="cdkOverlayOrigin" >person</mat-icon></button>
                <ng-template cdkPortal #menuTemplate>
                    <mat-card>
                        <mat-card-content>
                            <div>
                                <button mat-button [matMenuTriggerFor]="langMenu1"><i class="sprite sprite-en" [ngClass]="'sprite-' + lang()"></i></button>
                                <mat-menu #langMenu1="matMenu" [overlapTrigger]="false" >
                                    <!-- <button mat-menu-item (click)="useLang('en')"><i class="sprite sprite-en" style="margin-right: 10px"></i>{{'en'|translate}}</button> -->
                                    <button mat-menu-item (click)="useLang('hu')"><i class="sprite sprite-hu" style="margin-right: 10px"></i>{{'hu'|translate}}</button>
                                </mat-menu>
                            </div>
                            <p class="acuname">{{userName}}</p>
                            <button mat-icon-button (click)="logout()"><mat-icon>logout</mat-icon></button>
                            <button mat-icon-button (click)="sendLogToTopsoft()"><mat-icon>email</mat-icon></button>
                        </mat-card-content>                        
                    </mat-card>                
                </ng-template>
            </ng-container>            
            <button mat-button [matMenuTriggerFor]="langMenu" *ngIf="!isLoggedIn"><i class="sprite sprite-en" [ngClass]="'sprite-' + lang()" style="margin-right: 10px"></i></button>
            <mat-menu #langMenu="matMenu" [overlapTrigger]="false" >
                <button mat-menu-item (click)="useLang('en')"><i class="sprite sprite-en" style="margin-right: 10px"></i>{{'en'|translate}}</button>
                <button mat-menu-item (click)="useLang('hu')"><i class="sprite sprite-hu" style="margin-right: 10px"></i>{{'hu'|translate}}</button>
            </mat-menu>
        </mat-toolbar-row>
    </mat-toolbar>
  </div>

  
  <div id="mainContainer">    
    <mat-sidenav-container class="snavbar" fullscreen autosize>
        <mat-sidenav #drawer mode="over" role="navigation" [opened]="isLoggedIn && menuhujuj" (openedChange)="isLoggedIn && (menuhujuj = $event)">
            <mat-nav-list class="mynavlist">                    
                    <a mat-list-item [routerLink]="['/kezdolap']" routerLinkActive="active-menu" class="root-menu"><span class="root-menu-span">Kezdőlap</span></a>
                    <h3 mat-subheader class="root-menu-header">Jelenlét</h3>
                    <a mat-list-item  [routerLink]="['/jelenlet']" routerLinkActive="active-menu" class="root-menu"><span class="root-menu-span">Napi jelenlét</span></a>                    
                    <a mat-list-item  *ngIf="showmenu('zaras')" [routerLink]="['/zaras']" routerLinkActive="active-menu" class="root-menu"><span class="root-menu-span">Napi jelenlét zárás</span></a>
                    <h3 mat-subheader class="root-menu-header">Kérelem</h3>
                    <a mat-list-item [routerLink]="['/kerelem']" routerLinkActive="active-menu" class="root-menu"><span class="root-menu-span">Szabadság</span></a>
                    <a mat-list-item [routerLink]="['/kerelemho']" routerLinkActive="active-menu" class="root-menu"><span class="root-menu-span">Homeoffice</span></a>
                    <a mat-list-item  *ngIf="showmenu('tavmunka')" [routerLink]="['/kerelemtm']" routerLinkActive="active-menu" class="root-menu"><span class="root-menu-span">Távmunka</span></a>
                    <!-- <mat-divider></mat-divider>                     -->
                    <h4 mat-subheader *ngIf="showmenu('engedely')" class="root-menu-header">Engedélyezés</h4>
                    <a mat-list-item *ngIf="showmenu('engedely')" [routerLink]="['/enged']" [queryParams]="{ tip: 'sz'}" routerLinkActive="active-menu" class="root-menu"><span class="root-menu-span">Csoportos</span></a>
                    <a mat-list-item *ngIf="showmenu('engedely')" [routerLink]="['/engedszabi']" routerLinkActive="active-menu" class="root-menu"><span class="root-menu-span">Szabadság</span></a>
                    <a mat-list-item *ngIf="showmenu('engedelyjogcim')" [routerLink]="['/engedjogcim']" routerLinkActive="active-menu" class="root-menu"><span class="root-menu-span">Jogcímek</span></a>
                    <a mat-list-item *ngIf="showmenu('engedely')" [routerLink]="['/engedhome']" routerLinkActive="active-menu" class="root-menu"><span class="root-menu-span">Homeoffice</span></a>
                    <a mat-list-item *ngIf="showmenu('engedelytavmunka')" [routerLink]="['/engedtavmunka']" routerLinkActive="active-menu" class="root-menu"><span class="root-menu-span">Távmunka</span></a>
                    <h3 mat-subheader class="root-menu-header">Információ</h3>
                    <a mat-list-item [routerLink]="['/szabiinfo']" routerLinkActive="active-menu" class="root-menu"><span class="root-menu-span">Dolgozó szabadság</span></a>
                    <a mat-list-item [routerLink]="['/szabiinfotable']" routerLinkActive="active-menu" class="root-menu"><span class="root-menu-span">Szervezeti egység</span></a>
                    <h3 mat-subheader class="root-menu-header">Terv</h3>
                    <a mat-list-item [routerLink]="['/terv']" routerLinkActive="active-menu" class="root-menu"><span class="root-menu-span">Terv felvitele</span></a>
                    <!-- <mat-divider></mat-divider>                    
                    <mat-divider></mat-divider>                    
                    <a mat-list-item  class="root-menu" (click)="help()"><span class="root-menu-span">Help</span></a>
                    <mat-divider></mat-divider>                    
                    <mat-divider></mat-divider>                     -->
                </mat-nav-list>
        </mat-sidenav>
  
        <mat-sidenav-content role="container" class="content">
            <router-outlet></router-outlet>
        </mat-sidenav-content>
    </mat-sidenav-container>
  </div>
  