/**
 * Created by lendvai on 2017.05.24.
 */

import { Injectable, EventEmitter } from "@angular/core";
import {Observable, Subscription, of} from "rxjs";

import {User} from "./user";
import {AuthMethod, AuthParams, AuthProfile} from "./auth-params";
import {HttpSenderService} from "../net/http-sender.service";
import { from } from 'rxjs';
import { mergeMap, shareReplay, tap} from "rxjs/operators";
import { SpinnerOverlayService } from '../ui/spinner/spinner-overlay.service';
import { MsalService } from "@azure/msal-angular";
import { PublicClientApplication } from "@azure/msal-browser";

/**
 * Authentication changed event
 */
export interface AuthChangeEvent
{
    user: User;
}

/**
 * Current WEB site information
 */
export class CurrentWebSite
{
    /**
     * Name of the site
     */
    name : string = "";

    /**
     * Name of the default profile
     */
    profile : string = "";

    /**
     * List of available profiles
     */
    profiles : AuthProfile[] = [];
}

/**
 * Login status
 */
export enum LoginState
{
    /**
     *  Not logged in
     */
    NotLoggedIn,

    /**
     * Logged in, need 2 factor authentication
     */
    Need2FA,

    /**
     * Logged in, must change password
     */
    MustChangePassword,

    /**
     * User logged in
     */
    LoggedIn
}

/**
 * Interface to the authentication service
 */
export interface IAuthService
{
    /**
     * Is the user logged in?
     */
    isLoggedIn: boolean;

    /**
     * Get user info
     *
     * @return {User} the actual user
     */
    user: User;

    /**
     * Get identifier of the logged in user
     */
    userId: string;

    /**
     * Is the server working in Topsoft?
     */
    isTopsoft: boolean;

    /**
     * Is the current user an administrator?
     */
    isAdmin: boolean;

    /**
     * Login state
     */
    state: LoginState;

    /**
     * Register client
     *
     * @param id        client identifier
     * @param password  client password
     * @param token     user token
     */
    register(id: string, password: string, token: string): void;

    /**
     * Login to the remote system
     *
     * @param id            user identifier or complete authentication parameters
     * @param password      login password
     * @param date          login date
     * @param year          login year
     */
    login(id: string | AuthParams, password?: string, date?: Date, year?: number): Observable<User>;

    /**
     * Log off from the remote system
     */
    logoff(): Observable<any>;

    /**
     * Get current WEB site information
     */
    site(): Observable<CurrentWebSite>;

    /**
     * Check authenticator numbers
     * 
     * @param token token containing numbers from the authenticator
     */
    check2FA(token: string): Observable<boolean>;

    /**
     * Send log history to Topsoft Zrt.
     */
    sendLogToTopsoft(): void;
}

/**
 * Class to call remote methods
 */
@Injectable()
export class AuthService
{
    /**
     * The actual user
     */
    private _user: User = new User();

    /**
     * Authentication parameters
     */
    private _auth?: AuthParams;

    /**
     * Current WEB site information
     */
    private _site?: Observable<CurrentWebSite>;

    /**
     * Login state
     */
    private _state: LoginState = LoginState.NotLoggedIn;

    /**
     * Language changed event
     *
     * @type {EventEmitter<LangChangeEvent>}
     * @private
     */
    private _onAuthChange: EventEmitter<AuthChangeEvent> = new EventEmitter<AuthChangeEvent>();

    constructor(private _http: HttpSenderService, private _msal: MsalService, private spinnerOverlayService: SpinnerOverlayService)
    {
        var usrStr = sessionStorage.getItem("tusr");

        if (usrStr) {
            this._user = JSON.parse(usrStr) as User;
        }
        
        //this._user.id = sessionStorage.getItem("userId") ?? "";
    }

    /**
     * An EventEmitter to listen to lang change events
     * onLangChange.subscribe((params: LangChangeEvent) => {
     *     // do something
     * });
     * @type {EventEmitter<LangChangeEvent>}
     */
    get onAuthChange(): EventEmitter<AuthChangeEvent>
    {
        return this._onAuthChange;
    }

    /**
     * Is the user logged in?
     */
    public get isLoggedIn(): boolean
    {
        return this._user.isloggedin;
    }

    /**
     * Get user info
     *
     * @return {User} the actual user
     */
    public get user(): User
    {
        return new User(this._user);
    }

    /**
     * Get identifier of the logged in user
     */
    public get userId(): string
    {
        return this._user.id;
    }

    public setUserYear(y: number){
        this._user.year = y;
    }

    /**
     * Is the server working in Topsoft?
     */
    public get isTopsoft(): boolean
    {
        return this.isLoggedIn && this._user.isTopsoft;
    }

    /**
     * Is the current user an administrator?
     */
    public get isAdmin(): boolean
    {
        return this.isLoggedIn && this._user.inGroup("ADMIN");
    }

    /**
     * Login state
     */
    public get state(): LoginState
    {
        if(!this.isLoggedIn)
            return LoginState.NotLoggedIn;
        if(this._user.need2FA)
            return LoginState.Need2FA;
        if(this._user.mustChangePwd)
            return LoginState.MustChangePassword;
        return LoginState.LoggedIn;
    }

    private _setUser(u: User)
    {
        this._user = u;
        this._user.isloggedin = true;
        if(this._http.Authorization.IdToken && this._http.Authorization.IdToken.length)
            this._user.idToken = this._http.Authorization.IdToken;
            if(this._http.Authorization.AccessToken && this._http.Authorization.AccessToken.length)
            this._user.AccessToken = this._http.Authorization.AccessToken;
        if(this._http.Authorization.Date)
            this._user.date = this._http.Authorization.Date;
        if(this._http.Authorization.Year)
            this._user.year = this._http.Authorization.Year;
        if(this._http.Authorization.Profile)
            this._user.profile = this._http.Authorization.Profile.name;
        //sessionStorage.setItem("userId", u.id);
        sessionStorage.setItem("tusr", JSON.stringify(this._user));
        localStorage.setItem("pro", this._user.profile);
        this._onAuthChange.emit({ user: this._user });
    }

    /** Login to the remote system
     *
     * @param id            user identifier
     * @param password      login password
     * @param date          login date
     * @param year          login year
     */
    public login(auth: AuthParams): Observable<User|undefined>
    {
        if(auth.Profile && auth.Profile.authMethod == AuthMethod.Msal && (!auth.IdToken || !auth.IdToken.length))
        {
//            const cfg = this._msal.instance.getConfiguration();
//            if(cfg.auth.clientId !== auth.Profile.clientId)
            this._msal.instance = new PublicClientApplication({
                auth: {
                  clientId: auth.Profile.clientId,
                  authority: 'https://login.microsoftonline.com/' + auth.Profile.tenant,
                  redirectUri: auth.Profile.callbackUrl,
                  postLogoutRedirectUri: auth.Profile.callbackUrl
                }
              });
            
            return from(this._msal.instance.initialize()).pipe(mergeMap(b => {
                return this._msal.loginPopup()
                .pipe(mergeMap(result => {
                    console.log(result);
                    auth.IdToken = result.idToken;
                    auth.AccessToken = result.accessToken;
                    return this.login(auth);
                }));
            }));
        }

        this._http.Authorization = auth;
        const spinnerSubscription: Subscription = this.spinnerOverlayService.spinner$.subscribe();
        const ret = this._http.exec<User>("ILogin.User");
        return new Observable<User>((observer) =>{
            ret.subscribe({
                next: (u) => {                    
                    this._setUser(u);
                    observer.next(this._user);
                    spinnerSubscription.unsubscribe();
                },
                error: (error) => {
                    console.log(error);
                    observer.error(error);
                    if(this._http.Authorization.IdToken && this._http.Authorization.IdToken.length)
                    {
                        this._http.Authorization.IdToken = "";
                        this._http.Authorization.AccessToken = "";
                        this._msal.logoutPopup({mainWindowRedirectUri: "/"}).subscribe();
                    }
                    spinnerSubscription.unsubscribe();
                },
                complete: () => console.log("complete")
        })});
    }

    /**
     * Log off from the remote system
     */
    public logoff(): Observable<any>
    {
        if(this._http.Authorization.IdToken && this._http.Authorization.IdToken.length)
        {
            this._http.Authorization.IdToken = "";
            this._http.Authorization.AccessToken = "";
            this._msal.logoutPopup({mainWindowRedirectUri: "/"}).subscribe();
        }

        if(!this.isLoggedIn)
        {
            this._setUser(new User());
            return of();
        }

        const ret = this._http.exec<User>("ILogin.Logout");
        ret.subscribe((x: any) =>
        {
            this._http.resettoken();
            this._user.isloggedin = false;
            this._user.need2FA = false;
            this._user.mustChangePwd = false;
            sessionStorage.setItem("tusr", JSON.stringify(this._user));            
            this._onAuthChange.emit({user: this._user});
        });
        return ret;
    }

    public site(): Observable<CurrentWebSite>
    {
            if(!this._site)
            this._site = this._http.exec<CurrentWebSite>("IWebSystem.Site").pipe(
                tap(console.log),
                shareReplay(1)
            );
        return this._site;
    }

    public check2FA(token: string): Observable<boolean>
    {
        return this._http.exec<boolean>("ILogin.Check2FA", [token]);
    }

    public sendLogToTopsoft(): void
    {
        this._http.exec('IWebSystem.SendLogToTopsoft').subscribe();
    }

    public changePassword(pwd: string): Observable<any>
    {
        return this._http.exec('ILogin.ChangePwd', [pwd]).pipe(tap(() => {
            this._user.mustChangePwd = false;
            this._setUser(this._user);
        }));
    }
}
