import { Injectable } from '@angular/core';

@Injectable()
export class AudioService {

    
    private audioObj = new Audio();

    constructor() {}


    seekTo(seconds: number)
    {
        this.audioObj.currentTime = seconds;
    }

    playSucc(): void
    {
        try
        {
            this.audioObj.pause();
            this.audioObj.currentTime = 0;
            this.audioObj.src = '../../../assets/audio/succ.mp3';
            this.audioObj.load();
            this.audioObj.play();        
        }
        catch(e)
        {
            console.log(e)
        }                
    }

    playFail(): void
    {
        try
        {
            this.audioObj.pause();
            this.audioObj.currentTime = 0;
            this.audioObj.src = '../../../assets/audio/fail.wav';
            this.audioObj.load();
            this.audioObj.play();
        }
        catch(e)
        {
            console.log(e)
        }                
        
        
    }

    playBeep(): void
    {        
        try
        {
            this.audioObj.pause();
            this.audioObj.currentTime = 0;
            this.audioObj.src = '../../../assets/audio/beep.wav';
            this.audioObj.load();
            this.audioObj.play();
        }
        catch(e)
        {
            console.log(e)
        }                
    }

    volumeUp(): void
    {
        if(this.audioObj.volume <= 0.9)
        {
            this.audioObj.volume += 0.1;
            this.playSucc();
        }
    }

    volumeDown(): void
    {
        if(this.audioObj.volume >= 0.1)
        {
            this.audioObj.volume -= 0.1;
            this.playSucc();
        }
    }
}
