import { Component,OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NapiComponent } from '../napi/napi.component';
import { AppComponent } from '../app.component';
@Component({
  selector: 'app-engedhome',
  standalone: true,
  imports: [CommonModule,NapiComponent],
  templateUrl: './engedhome.component.html',
  styleUrls: ['./engedhome.component.css']
})
export class EngedhomeComponent implements OnInit {

  constructor(private ap :AppComponent) {this.ap.title = 'Homeoffice engedélyezés'; }

  ngOnInit(): void {
    
  }  
}
