<!-- *************************************************************************
 Szűrők
************************************************************************* -->
<app-szurok item="szabiinfo"></app-szurok>
<!-- *************************************************************************
 Táblázat -1
************************************************************************* -->
<!-- *************************************************************************
 Táblázat 0
************************************************************************* -->
<table mat-table [dataSource]="dataSource0">

    <ng-container matColumnDef="szoveg">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element"> {{element.szoveg}} </td>
    </ng-container>

    <ng-container matColumnDef="felh">
        <th mat-header-cell *matHeaderCellDef> Ált. </th>
        <td mat-cell *matCellDef="let element"> {{  (element.felh==0)?"":element.felh  }} </td>
    </ng-container>

    <ng-container matColumnDef="tanul">
        <th mat-header-cell *matHeaderCellDef> Tanul. </th>
        <td mat-cell *matCellDef="let element"> {{  (element.tanul==0)?"":element.tanul  }} </td>
    </ng-container>

    <ng-container matColumnDef="rendk">
        <th mat-header-cell *matHeaderCellDef> Rendk. </th>
        <td mat-cell *matCellDef="let element"> {{  (element.rendk==0)?"":element.rendk  }} </td>
    </ng-container>

    <ng-container matColumnDef="ossz">
        <th mat-header-cell *matHeaderCellDef> Össz. </th>
        <td mat-cell *matCellDef="let element"> {{  (element.ossz==0)?"":element.ossz  }} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns0"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns0;"></tr>
</table>
<!-- *************************************************************************
 Szabijelleg szótár
************************************************************************* -->
<div class="example-list">
    <mat-form-field class="huj">
        <mat-label>Szabijelleg</mat-label>
        <mat-select [(value)]="_szabijelleg" matNativeControl required (selectionChange)="onSzjChanged()">
            <mat-option value=" 0" class="huj2">Szabadon felhasználható</mat-option>
            <mat-option value=" 1" class="huj2">Tanulmányi</mat-option>
            <mat-option value=" 2" class="huj2">Betegszabadság</mat-option>
            <mat-option value=" 3" class="huj2">Fizetés nélküli</mat-option>
            <mat-option value=" 4" class="huj2">Szabadnap</mat-option>
            <mat-option value=" 5" class="huj2">Rendkívüli</mat-option>
            <mat-option value=" 6" class="huj2">Szülési</mat-option>
            <mat-option value=" 7" class="huj2">Apa</mat-option>
            <mat-option value=" 8" class="huj2">Munkaköri</mat-option>
            <mat-option value=" 9" class="huj2">Apa plusz</mat-option>
            <mat-option value="10" class="huj2">Szülői</mat-option>
        </mat-select>
    </mat-form-field>
</div>
<!-- *************************************************************************
 Táblázat 1
************************************************************************* -->
<br>
<table mat-table [dataSource]="dataSource">

    <ng-container matColumnDef="ho">
        <th mat-header-cell *matHeaderCellDef> Hó </th>
        <td mat-cell *matCellDef="let element"> {{element.ho}} </td>
    </ng-container>

    <ng-container matColumnDef="tol">
        <th mat-header-cell *matHeaderCellDef> Tól </th>
        <td mat-cell *matCellDef="let element"> {{element.tol}} </td>
    </ng-container>

    <ng-container matColumnDef="ig">
        <th mat-header-cell *matHeaderCellDef> Ig </th>
        <td mat-cell *matCellDef="let element"> {{element.ig}} </td>
    </ng-container>

    <ng-container matColumnDef="nap">
        <th mat-header-cell *matHeaderCellDef> Nap </th>
        <td mat-cell *matCellDef="let element"> {{element.nap}} </td>
    </ng-container>

    <ng-container matColumnDef="kerelem">
        <th mat-header-cell *matHeaderCellDef> Dátumok </th>
            <td mat-cell *matCellDef="let element"> 
                <div *ngIf="(element.k!=='')">Kér:  {{element.k}}</div>
                <div *ngIf="(element.f!=='')">Eng:  {{element.f}}</div>
                <div *ngIf="(element.s!=='')">Tör:  {{element.s}}</div>
                <div *ngIf="(element.v!=='')">Vis:  {{element.v}}</div>
                <div *ngIf="(element.e!=='')">Elu:  {{element.e}}</div>
            </td>
        </ng-container>

    <!-- <ng-container matColumnDef="torles">
        <th mat-header-cell *matHeaderCellDef> Törlés </th>
        <td mat-cell *matCellDef="let element"> {{element.s}} </td>
    </ng-container>

    <ng-container matColumnDef="visszavonas">
        <th mat-header-cell *matHeaderCellDef> Visszavonás </th>
        <td mat-cell *matCellDef="let element"> {{element.v}} </td>
    </ng-container>

    <ng-container matColumnDef="elutasitas">
        <th mat-header-cell *matHeaderCellDef> Elutasítás </th>
        <td mat-cell *matCellDef="let element"> {{element.e}} </td>
    </ng-container> -->


    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>