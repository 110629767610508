<div class="example-list"> <!-- <br> --> <!-- &nbsp; -->
  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
  <mat-form-field class="my-select-panel-class">
    <mat-label>Szervezetiegység</mat-label>
    <mat-select [(value)]="szerv" (selectionChange)="onSzervChanged()">
      <mat-option class="my-select-class" *ngFor="let sz of szervegysek" [value]="sz.key">
        {{sz.text}}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field class="my-select-panel-class2">
    <mat-label>Év</mat-label>
    <mat-select [(value)]="selectedYear" (selectionChange)="onYearChanged()">
      <mat-option class="my-select-class" *ngFor="let year of years" [value]="year.y1">
        {{year.y2}}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field class="my-select-panel-class3">
    <mat-label>Hó</mat-label>
    <mat-select [(value)]="selectedMonth" (selectionChange)="onMonthChanged()">
      <mat-option class="my-select-class" *ngFor="let month of months" [value]="month.y1">
        {{month.y2}}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
<h2 style="color: coral;text-align:center">fennmaradó keret :  {{keret}}  nap</h2>


<div>
  <div style="overflow-x:scroll;">
    <table>
      <tr>
        <th class="h1" *ngFor="let col of headerdata">{{col}}</th>
      </tr>
      <tr *ngFor="let item of bodydata;let i=index">
        <td class="h2" *ngFor="let field of item|keyvalue;let j=index" (click)="selectcell(i,j,field.value)" [ngClass]="{
                        'PP':field.value == 'PP',
                        'DU':field.value == 'DU',
                        'HU':field.value == 'HU',
                        'EU':field.value == 'EU',
                        'DS':field.value == 'DS',
                        'HS':field.value == 'HS',
                        'ES':field.value == 'ES'
                       }">
          <!-- {{field.value}} -->
          {{szov(field.value)}}
        </td>
      </tr>
    </table>
  </div>
</div>
<!-- <button class="e">E : Enged. szabi</button>
  <button class="k">K : Kért szabi</button>
  <button class="t">T : Távollét</button>
  <button class="o">O : Oktatás</button>
  <button class="eh">EH : Enged. homeoffice</button>
  <button class="kh">KH : Kért homeoffice</button>
  <button class="m">M : Munka</button>
  <button class="u">Ü : Ünnep</button>
  <button class="b">B : Betegség</button>
  <button class="t">T : Távollét</button>
  <br><br> -->