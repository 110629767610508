import {Injectable, ApplicationRef, EventEmitter} from "@angular/core";
import {Utils} from "../core/utils";

export interface LangChangeEvent
{
    lang: string;
}

/**
 * Language texts
 */
export interface ILangTexts
{
    /**
     * Log off from the system
     */
    areYouSure: string;

    /**
     * Clear
     */
    clear: string;

    /**
     * Done
     */
    done: string;

    /**
     * Hungarian
     */
    hu: string;

    /**
     * Edit user's profile
     */
    editUserProfile: string;

    /**
     * English
     */
    en: string;

    /**
     * Identifier
     */
    id: string;

    /**
     * Log off from the system
     */
    logoff: string;

    /**
     * Not logged in
     */
    notLoggedIn: string;

    /**
     * Not allowed
     */
    notAllowed: string;

    /**
     * Page will be reloading
     */
    pageReloading: string;

    /**
     * Today
     */
    today: string;

    /**
     * Other values
     */
    [key: string]: string;
}

/**
 * Interface to the language service
 */
export interface ITranslateService
{
    /**
     * The current language
     */
    language: string;

    /**
     * Get a language texts
     * @returns {Object} the localized texts
     */
    texts: ILangTexts;

}

@Injectable()
export class TranslateService implements ITranslateService
{
    /**
     * List of all the language items
     */
    private _allTexts: any;

    /**
     * Current language
     */
    private _lang: string = "";


    /**
     * Texts in the current language
     */
    private _texts: ILangTexts = <ILangTexts> {};

    /**
     * Language changed event
     *
     * @type {EventEmitter<LangChangeEvent>}
     * @private
     */
    private _onLangChange: EventEmitter<LangChangeEvent> = new EventEmitter<LangChangeEvent>();

    /**
     * Get language code
     * @param language  language identifier
     * @returns the language code
     */
    private static getLangCode(language: string): string
    {
        const idx = language.indexOf("-");
        return idx >= 0 ? language.substr(0, idx) : language;
    }

    public init(allTexts: any): void
    {
        this._allTexts = allTexts;
        this.use(sessionStorage.getItem("topLang") ?? "hu");//Utils.cookie("topLang") || "hu"
    }

    /**
     * An EventEmitter to listen to lang change events
     * onLangChange.subscribe((params: LangChangeEvent) => {
     *     // do something
     * });
     * @type {EventEmitter<LangChangeEvent>}
     */
    get onLangChange(): EventEmitter<LangChangeEvent>
    {
        return this._onLangChange;
    }

    /**
     * Language get property method
     * @returns {string} the language code
     */
    public get language(): string
    {
        return this._lang;
    }

    /**
     * Use a language
     *
     * @param lang  the new language
     */
    public use(lang: string): void
    {
        if(lang === this._lang)
            return;

        this._lang = TranslateService.getLangCode(lang);
        sessionStorage.setItem("topLang", lang);
        //Utils.setCookie("topLang", lang);

        const langTexts = <ILangTexts> {};

        const langs = this._allTexts;
        if(!langs)
            return;
        for(const id in langs)
            if(langs.hasOwnProperty(id))
                langTexts[id] = langs[id][this._lang] || langs[id].en;
        this._texts = langTexts;
        this._onLangChange.emit({lang: lang});
    }

    /**
     * Get a language texts
     * @returns {Object} the localized texts
     */
    public get texts(): ILangTexts
    {
        return <ILangTexts> this._texts;
    }

    /**
     * Get text by key
     *
     * @param key           text key
     * @returns {string}    the translated text
     */
    public translate(key: string): string
    {
        if(!key || !this._texts || !this._texts.hasOwnProperty(key))
            return key;
        return this._texts[key] || key;
    }

}
