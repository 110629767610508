<mat-tab-group>
    <mat-tab label="Jogcímek">
        <div class="example-list2">
            <div class="example-list3">
                <i class="sprite sprite-munkanap" alt=" : Munkanap" style="transform: scale(0.7);"></i>
                <p>  : munkanap</p>
            </div>
            <div class="example-list3">
                <i class="sprite sprite-unnep" alt=" : Munkanap" style="transform: scale(0.7);"></i>
                <p>  : ünnepnap</p>
            </div>
            <div class="example-list3">
                <i class="sprite sprite-szabadsag" alt=" : Munkanap" style="transform: scale(0.7);"></i>
                <p>  : szabadság</p>
            </div>
            <div class="example-list3">
                <i class="sprite sprite-betegseg" alt=" : Munkanap" style="transform: scale(0.7);"></i>
                <p>  : betegség</p>
            </div>
            <div class="example-list3">
                <i class="sprite sprite-tavollet" alt=" : Munkanap" style="transform: scale(0.7);"></i>
                <p>  : távollét</p>
            </div>
            <div class="example-list3">
                <i class="sprite sprite-oktatas" alt=" : Munkanap" style="transform: scale(0.7);"></i>
                <p>  : oktatás</p>
            </div>
            <div class="example-list3">
                <i class="sprite sprite-egyeb" alt=" : Munkanap" style="transform: scale(0.7);"></i>
                <p>  : egyéb</p>
            </div>
            <div class="example-list3">
                <i class="sprite sprite-ho" alt=" : Munkanap" style="transform: scale(0.7);"></i>
                <p>  : homeoffice</p>
            </div>
            <div class="example-list3">
                <i class="sprite sprite-tavmunka" alt=" : Munkanap" style="transform: scale(0.7);"></i>
                <p>  : távmunka</p>
            </div>
            <div class="example-list3">
                <i class="sprite sprite-szabadsag_terv" alt=" : Munkanap" style="transform: scale(0.7);"></i>
                <p>  : szabadság terv</p>
            </div>
        </div>
    </mat-tab>
    <mat-tab label="Gombok">
        <div class="example-list2">
            <div class="example-list3">
                <i class="sprite sprite-munkanap" alt=" : Munkanap" style="transform: scale(0.7);"></i>
                <p>  : gyors munkanap felvitel</p>
            </div>
            <div class="example-list3">
                <i class="sprite sprite-szabadsag" alt=" : Munkanap" style="transform: scale(0.7);"></i>
                <p>  : gyors szabi felvitel</p>
            </div>
            <div class="example-list3">
                <i class="sprite sprite-insert" alt=" : Munkanap" style="transform: scale(0.7);"></i>
                <p>  : felvitel(tól-ig,...)</p>
            </div>
            <div class="example-list3">
                <i class="sprite sprite-deletej" alt=" : Munkanap" style="transform: scale(0.7);"></i>
                <p>  : törlés</p>
            </div>
            <div class="example-list3">
                <i class="sprite sprite-ho" alt=" : Munkanap" style="transform: scale(0.7);"></i>
                <p>  : homeoffice felvitel</p>
            </div>
            <div class="example-list3">
                <i class="sprite sprite-hod" alt=" : Munkanap" style="transform: scale(0.7);"></i>
                <p>  : homeoffice törlés</p>
            </div>
            <div class="example-list3">
                <i class="sprite sprite-tavmunka" alt=" : Munkanap" style="transform: scale(0.7);"></i>
                <p>  : távmunka felvitel</p>
            </div>
            <div class="example-list3">
                <i class="sprite sprite-tavmunkad" alt=" : Munkanap" style="transform: scale(0.7);"></i>
                <p>  : távmunka törlés</p>
            </div>
            <div class="example-list3">
                <i class="sprite sprite-infoj" alt=" : Munkanap" style="transform: scale(0.7);"></i>
                <p>  : szabi infó</p>
            </div>
            <div class="example-list3">
                <i class="sprite sprite-pdf" alt=" : Munkanap" style="transform: scale(0.7);"></i>
                <p>  : jelenlét lista</p>
            </div>
            <div class="example-list3">
                <i class="sprite sprite-elutasit" alt=" : Munkanap" style="transform: scale(0.7);"></i>
                <p>  : elutasít</p>
            </div>
            <div class="example-list3">
                <i class="sprite sprite-engedely" alt=" : Munkanap" style="transform: scale(0.7);"></i>
                <p>  : engedélyez</p>
            </div>
            <div class="example-list3">
                <i class="sprite sprite-unlock" alt=" : Munkanap" style="transform: scale(0.7);"></i>
                <p>  : nyit</p>
            </div>
            <div class="example-list3">
                <i class="sprite sprite-lock" alt=" : Munkanap" style="transform: scale(0.7);"></i>
                <p>  : zár</p>
            </div>
        </div>
    </mat-tab>
    <mat-tab label="Ikonok">
        <div class="example-list2">
            <div class="example-list3">
                <i class="sprite sprite-szabadsag" alt=" : Munkanap" style="transform: scale(0.7);"></i>
                <p>  : Szabadság engedélyezve</p>
            </div>
            <div class="example-list3">
                    <i class="sprite sprite-szabadsag_ker" alt=" : Munkanap" style="transform: scale(0.7);"></i>
                <p>  : Szabadság kérelem</p>
            </div>
            <div class="example-list3">
                    <i class="sprite sprite-szabadsag_vis" alt=" : Munkanap" style="transform: scale(0.7);"></i>
                <p>  : Szabadság visszavont</p>
            </div>
            <div class="example-list3">
                <i class="sprite sprite-egyeb" alt=" : Munkanap" style="transform: scale(0.7);"></i>
                <p>  : Egyéb jogcím engedélyezve</p>
            </div>
            <div class="example-list3">
                    <i class="sprite sprite-egyeb_ker" alt=" : Munkanap" style="transform: scale(0.7);"></i>
                <p>  : Egyéb jogcím kérelem</p>
            </div>
            <div class="example-list3">
                    <i class="sprite sprite-egyeb_vis" alt=" : Munkanap" style="transform: scale(0.7);"></i>
                <p>  : Egyéb jogcím visszavont</p>
            </div>
            <div class="example-list3">
                    <i class="sprite sprite-ho" alt=" : Munkanap" style="transform: scale(0.7);"></i>
                <p>  : Homeoffice engedélyezve</p>
            </div>
            <div class="example-list3">
                    <i class="sprite sprite-ho_ker" alt=" : Munkanap" style="transform: scale(0.7);"></i>
                <p>  : Homeoffice kérelem</p>
            </div>
            <div class="example-list3">
                    <i class="sprite sprite-ho_vis" alt=" : Munkanap" style="transform: scale(0.7);"></i>
                <p>  : Homeoffice visszavont</p>
            </div>
            <div class="example-list3">
                    <i class="sprite sprite-tavmunka" alt=" : Munkanap" style="transform: scale(0.7);"></i>
                <p>  : Távmunka engedélyezve</p>
            </div>
            <div class="example-list3">
                <i class="sprite sprite-tavmunka_ker" alt=" : Munkanap" style="transform: scale(0.7);"></i>
                <p>  : Távmunka kérelem</p>
            </div>
                <div class="example-list3">
                <i class="sprite sprite-tavmunka_vis" alt=" : Munkanap" style="transform: scale(0.7);"></i>
                <p>  : Távmunka visszavont</p>
            </div>
        </div>
    </mat-tab>
</mat-tab-group>

