import {Component, OnInit, OnDestroy, Input, EventEmitter} from "@angular/core";
import {AuthChangeEvent, AuthService} from "../security/auth.service";
import {User} from "./user";
import {TranslateService} from "../lang/translate.service";
import {Router} from "@angular/router";
import {UiService} from "../ui/ui.service";

@Component({
    selector: "top-user-name",
    templateUrl: "./user.name.component.html",
    standalone: true
})
export class UserNameComponent implements OnInit, OnDestroy
{
    private _onAuthChange?: EventEmitter<AuthChangeEvent>;

    @Input() title: string = "";
    @Input() btnTitle: string = "";

    user: User = new User();
    isLoggedIn: boolean = false;

    constructor(private _auth: AuthService, private _lang: TranslateService, private _ui: UiService, private _router: Router) {}

    ngOnInit(): void
    {
        this.title = this._lang.texts.editUserProfile;
        this.btnTitle = this._lang.texts.logoff;
        this.user = this._auth.user;
        this.isLoggedIn = !!(this.user.id && this.user.id.length);
        this._onAuthChange = this._auth.onAuthChange;
        this._auth.onAuthChange.subscribe((event: AuthChangeEvent) =>
        {
            this.user = event.user == null ? new User() : event.user;
            this.isLoggedIn = !!(this.user.id && this.user.id.length);
            if(!this.isLoggedIn)
                this._router.navigate(["/login"]);
        });
    }

    public edit(): void
    {
    }

    public logoff(): void
    {
        this._ui.confirm(this._lang.texts.logoff, this._lang.texts.areYouSure).subscribe((res: boolean) => res && this._auth.logoff());
    }

    /**
     * Clean any existing subscription to change events
     * @private
     */
    _dispose(): void
    {
        if(typeof this._onAuthChange !== "undefined")
        {
//            this._onAuthChange.unsubscribe();
            this._onAuthChange = undefined;
        }
    }

    ngOnDestroy(): void
    {
        this._dispose();
    }
}
