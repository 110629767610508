<form novalidate class="my">            <!-- <br> -->       <!-- &nbsp; -->
    <h1 mat-dialog-title>
        <mat-icon color="primary">editcalendar</mat-icon> Felvitel
    </h1>
    <div mat-dialog-content>
    
        <mat-tab-group *ngIf="KellToligora()">
        <div class="center">
            <mat-tab label="Tól-ig">
                <mat-label style="font-size: small;" >Tól-Ig</mat-label>&nbsp;
                <input matInput type="time" class="ora"  [(ngModel)]="data.tol" name="mytol"/>
                <input matInput type="time" class="ora"  [(ngModel)]="data.ig"  name="myig"/>
                <p></p>
            </mat-tab>
        </div>    
        <div class="center">
            <mat-tab label="Óra">
                <mat-label style="font-size: small;">Óra</mat-label>&nbsp;&nbsp;&nbsp;&nbsp;
                <input matInput type="time" class="ora" [(ngModel)]="data.ora" name="myora"/>&nbsp;
                <!-- <mat-slide-toggle  (change)="changedElojel($event)" [(ngModel)]="elojel_" name="myelojel">{{data.elojel}}</mat-slide-toggle> -->
                <p></p>
            </mat-tab>
        </div>    
        </mat-tab-group>

        <mat-form-field appearance="fill" class="szotarak">
            <mat-label>Jogcím</mat-label>
            <input type="text" matInput [formControl]="autocompleteJogcim" [matAutocomplete]="auto5"
                (change)="handleEmptyInputJogcim($event)">
            <mat-autocomplete #auto5="matAutocomplete" [displayWith]="displayJogcim"
                (optionSelected)="onJogcimChange($event)">
                <mat-option class="my-select-class" *ngFor="let option of filteredOptionsJogcim | async" [value]="option">
                    {{option.text}}
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>


        <!-- <mat-form-field appearance="fill">
            <mat-label>Kifizetés nap</mat-label>
            <input matInput [matDatepicker]="dp" class="fiznap" [(ngModel)]="data.fizdate" name="myfizdate"> 
            <mat-datepicker-toggle matIconSuffix [for]="dp"></mat-datepicker-toggle>
            <mat-datepicker #dp></mat-datepicker>
        </mat-form-field>

        <mat-form-field appearance="fill" class="szotarak">
            <mat-label>Kifízetőhely</mat-label>
            <input type="text" matInput [formControl]="autocompleteFizhely" [matAutocomplete]="auto6"
                (change)="handleEmptyInputFizhely($event)">
            <mat-autocomplete #auto6="matAutocomplete" [displayWith]="displayFizhely"
                (optionSelected)="onFizhelyChange($event)">
                <mat-option class="my-select-class" *ngFor="let option of filteredOptionsFizhely| async" [value]="option">
                    {{option.text}}
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>

        <mat-form-field appearance="fill" class="szotarak">
            <mat-label>Munkaszám</mat-label>
            <input type="text" matInput [formControl]="autocompleteMunkaszam" [matAutocomplete]="auto7" 
                (change)="handleEmptyInputMunkaszam($event)">
            <mat-autocomplete #auto7="matAutocomplete" [displayWith]="displayMunkaszam"
                (optionSelected)="onMunkaszamChange($event)">
                <mat-option class="my-select-class" *ngFor="let option of filteredOptionsMunkaszam| async" [value]="option">
                    {{option.text}}
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>

        <mat-form-field appearance="fill" class="szotarak">
            <mat-label>Költséghely</mat-label>
            <input type="text" matInput [formControl]="autocompleteKtghely" [matAutocomplete]="auto8"
                (change)="handleEmptyInputKtghely($event)">
            <mat-autocomplete #auto8="matAutocomplete" [displayWith]="displayKtghely"
                (optionSelected)="onKtghelyChange($event)">
                <mat-option class="my-select-class" *ngFor="let option of filteredOptionsKtghely| async" [value]="option">
                    {{option.text}}
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>

        <mat-form-field appearance="fill" class="szotarak">
            <mat-label>Szervezetiegység</mat-label>
            <input type="text" matInput [formControl]="autocompleteSzervegys" [matAutocomplete]="auto9" 
                (change)="handleEmptyInputSzervegys($event)">
            <mat-autocomplete #auto9="matAutocomplete" [displayWith]="displaySzervegys"
                (optionSelected)="onSzervegysChange($event)">
                <mat-option class="my-select-class" *ngFor="let option of filteredOptionsSzervegys| async" [value]="option">
                    {{option.text}}
                </mat-option>
            </mat-autocomplete>
        </mat-form-field> -->

        <mat-form-field appearance="fill" class="szotarak" *ngIf="KellHelyettesito()">
            <mat-label>Helyettesítő</mat-label>
            <input type="text" matInput [formControl]="autocompleteHelyettesito" [matAutocomplete]="auto10"
                (change)="handleEmptyInputHelyettesito($event)">
            <mat-autocomplete #auto10="matAutocomplete" [displayWith]="displayHelyettesito"
                (optionSelected)="onHelyettesitoChange($event)">
                <mat-option class="my-select-class" *ngFor="let option of filteredOptionsHelyettesito| async" [value]="option">
                    {{option.text}}
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>
        
    </div>

    <div mat-dialog-actions>
        <!-- <button mat-raised-button [mat-dialog-close]="data" color="primary" cdkFocusInitial> -->
        <button mat-raised-button (click)="onClick()" (dblclick)="onDblClick()" color="primary" cdkFocusInitial>
            <mat-icon>done</mat-icon>Rögzít
        </button>
        <button mat-raised-button (click)="onNoClick()" color="primary">
            <mat-icon>close</mat-icon>Mégse
        </button>
    </div>
</form>