import { Observable } from "rxjs";

export class TFileContent
{
    data: string="";
    fileName: string="";
    mime: string="";
}
export interface NamedBlob
{
    data: Blob;
    fileName: string;
    mime: string;
}

export class Berinstall
{
    szurotip: string = "";
    aktberho: number = 0;
    ev      : number = 0;
    dolgozo : string = "";
    fomszerz  : string = "";
    vezeto : boolean = false;
    lezaras : boolean = false;
    tavmunka : boolean = false;
    helyettesito : boolean = false;
    vezetomastmodosithat : boolean = true;    
    homevissza : boolean = true;
    szabigyors : boolean = true;
    toligora : boolean = true;
    csakmunkanapjel : boolean = true;
    szervegyshier : boolean = true;
    ceg : string = "";
    szdb : number = 0;
    hodb : number = 0;
    tmdb : number = 0;
    jodb : number = 0;
    engedelykot : boolean = true;
}
export class evho
{
    ev      : number = 2023;
    ho      : number = 0;
}
export interface KodtarParams
{
    kodcsop: string;
    nullValue: boolean;
    nullValueText: string;
    textInclCode: boolean;
    sortByCode: boolean;
    filterValue : string;
    filterId    : string;
}

export interface TorzstarParams
{
    filterValue : string;
    filterId    : string;
    nullValue   : boolean;
    nullValueText : string;
    textInclCode  : boolean;
    sortByCode  : boolean;
    ev : Number;
    ho : Number;
    tip : string;
}

export interface KeyText
{
    key: string;
    text: string;
}

export interface DolgozoRec
{
    id: string;
    name: string;
    fomszerz: string;
}

export class Tetel
{
    nap       : number=0;
    ora       : number=0;
    tol       : Date = new Date();
    ig        : Date = new Date();
    jogcim    : String='';
    jogcimnev : String='';
    szabi     : String='';
    szabi2    : String='';
    ts        : number=0;
    cimke     : String='';
    szamfejtve: String=''; 
}
export class  TNap
{
    tetelek :  Tetel[]=[];
    icon    :  string[]=[];
}
export class  THonap
{
    napok    : TNap[]=[];
    maszk    : string[]=[];
    maszk2    : string[]=[];
    homofisz : string[]=[];
    homofisz2 : string[]=[];
}

export class TfelvitelGyorsParams {
    dolgozo : String='';
    mszerz  : String='';
    ev      : number=0;
    ho      : number=0;
    nap      : number=0;
    tip     : string='';
    tol     : string='';
    ig      : string='';
    ts      : number=0;
}

export interface felvitelkulcsok {
    dolgozo : String;
    mszerz  : String;
    ev      : number;
    ho      : number;
    ts      : number;
    st      : boolean[];
}

export class TflevitelTolt {
    dolgozo       : String=''; 
    mszerz        : String='';
    ev            : Number=0;
    ho            : Number=0;
    nap           : Number=0;
    ts            : Number=0;
    jogcim        : String='';
    jogcimnev     : String='';
    ora           : String='';
    elojel        : String='';
    tol           : String='';
    ig            : String=''; 
    fizdate       : Date=new Date();
    fizhely       : String='';
    fizhelynev    : String='';
    munkaszam     : String='';
    munkaszamnev  : String='';
    ktghely       : String='';
    ktghelynev    : String='';
    szervegys     : String='';
    szervegysnev  : String='';
    item          : String='';
    selectTomb    : boolean[] = [];
    helyettesito     : String='';
    helyettesitonev  : String='';
}

export interface szabiinfo0 {
    szoveg   : String;
    felh     : Number;
    tanul    : Number;
    rendk    : Number;
    ossz     : Number;
}
export interface szabiinfo {
    ho    : Number;
    tol   : Number;
    ig    : Number;
    nap   : Number;
    k     : String;
    f     : String;
    s     : String;
    v     : String;
    e     : String;
    w     : String;
    y     : String;
}
export interface szabiinfotable {
    header : String;
    body   : String;
    keret  : number;
    nezegetes : boolean;
}
export interface engedtable {
    header : String;
    body   : String;
    dolgozo: string[];
    mszerz: string[];
    jogcimdata : string[];
    zarva  : boolean[];
}

export interface Tzart {
    zart : boolean;
    szamfejtve : boolean;
}

export interface IJelenlet
{
    getBerinstall(): Observable<Berinstall>;
    //****************************************************************************** */
    // Szürök
    //****************************************************************************** */
    szervegys(): Observable<KeyText[]>;
    getSzervegys(params: TorzstarParams): Observable<KeyText[]>;
    szervegys2(): Observable<KeyText[]>;
    getSzervegys2(params: TorzstarParams): Observable<KeyText[]>;
    szervegys3(): Observable<KeyText[]>;
    getFizhely(params: TorzstarParams): Observable<KeyText[]>;
    fizhely(): Observable<KeyText[]>;
    getDolgozo(sz:String, fi:String, params: TorzstarParams): Observable<DolgozoRec[]>;
    getMszerz(dolg:String): Observable<KeyText[]>;
    getevho(dolg:String,mszerz:String,item:String): Observable<evho>;
    gethelyettesitetttolt(params: TorzstarParams): Observable<KeyText[]>;
    helyettesitetttolt(): Observable<KeyText[]>;
    //****************************************************************************** */
    // Naptár
    //****************************************************************************** */
    felvitelGyors(par:TfelvitelGyorsParams,maszk:boolean[]): Observable<string>;
    deleteGyors(par:TfelvitelGyorsParams,maszk:boolean[]): Observable<string>;
    hoGyors(par:TfelvitelGyorsParams,maszk:boolean[]): Observable<string>;
    //****************************************************************************** */
    jogcimekAC(filter: string,tip:String): Observable<KeyText[]>;
    fizhelyekAC(filter: string): Observable<KeyText[]>;
    munkaszamokAC(filter: string,ev:Number,ho:Number): Observable<KeyText[]>;
    ktghelyekAC(filter: string,ev:Number,ho:Number): Observable<KeyText[]>;
    szervegysekAC(filter: string): Observable<KeyText[]>;
    //****************************************************************************** */
    getfelvitelTolt(params:TfelvitelGyorsParams):Observable<TflevitelTolt>;
    getmodositasTolt(params:TfelvitelGyorsParams):Observable<TflevitelTolt>;
    jelenletTran(data:TflevitelTolt,masz:boolean[]): Observable<string>;
    jelenletTrandel(params:TfelvitelGyorsParams): Observable<string>;
    //****************************************************************************** */
    szabiEnged(par:TfelvitelGyorsParams,maszk:boolean[]): Observable<string>;
    hoEnged(par:TfelvitelGyorsParams,maszk:boolean[],tip2:String): Observable<string>;
    szabiEngedcs(ev:Number,dolgozo:string[],tip:String): Observable<string>;
    hoEngedcs(ev:Number,dolgozo:string[],tip:String,tip2:String): Observable<string>;
    //****************************************************************************** */
    getszabiinfo0(dolgozo:String,mszerz:String): Observable<szabiinfo0[]>;
    getszabiinfo(dolgozo:String,mszerz:String,szabijelleg:String): Observable<szabiinfo[]>;
    getszabiinfotable(szervegys:String,ev:Number,ho:Number,tip:String,helytip:boolean,munkaido:boolean): Observable<szabiinfotable>;
    getszabiinfotable2(szervegys:String,ev:Number,ho:Number,tip:String): Observable<engedtable>;
    getengedtable(helyettesitett:String,szervegys:String,ev:Number,ho:Number,tip:String): Observable<engedtable>;
    //****************************************************************************** */
    getzart(dolgozo:String,mszerz:String,ev:Number,ho:Number): Observable<Tzart>;
    lezart(dolgozo:String,mszerz:String,ev:Number,ho:Number): Observable<boolean>;
    getNyitZar(dolgozo:String,mszerz:String,ev:Number,ho:Number,tip:String): Observable<boolean>;
    getNyitZarCsop(dolgozo:String[],mszerz:String[],maszk:boolean[],ev:Number,ho:Number,tip:String): Observable<boolean>;
//****************************************************************************** */
    gettervtable(szervegys:String,ev:Number,ho:Number): Observable<szabiinfotable>;
//****************************************************************************** */
    tervinsertdelete(ev:number,ho:number,j:number):Observable<string>;
//****************************************************************************** */
tryRegisterDolgozo(lid:string): Observable<string>;
}
