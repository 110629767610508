import { Component } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@Component({
    selector: 'app-spinner-overlay',
    templateUrl: './spinner-overlay.component.html',
    styleUrls: ['./spinner-overlay.component.css'],
    standalone: true,
    imports: [MatProgressSpinnerModule],
})
export class SpinnerOverlayComponent {
  constructor() {}
}
