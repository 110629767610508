<div class="example-list"> <!-- <br> --> <!-- &nbsp; -->
  <!-- <div *ngIf="isLoading"  style="display: flex; justify-content: center; align-items: center;zoom: 0.6">
    <mat-progress-spinner color="primary" mode="indeterminate" class="md-spinner"></mat-progress-spinner>
  </div> -->
  <!-- <mat-slide-toggle [checked]="false" (change)="changedTip($event)">Homeoffice/Távmunka</mat-slide-toggle> -->
  <!-- &nbsp;&nbsp;&nbsp; -->
  <mat-form-field class="my-select-panel-class">
    <mat-label>Szervezetiegység</mat-label>
    <mat-select [(value)]="szerv" (selectionChange)="onSzervChanged()">
      <mat-option class="my-select-class" *ngFor="let sz of szervegysek" [value]="sz.key">
        {{sz.text}}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field class="my-select-panel-class2">
    <mat-label>Év</mat-label>
    <mat-select [(value)]="selectedYear" (selectionChange)="onYearChanged()">
      <mat-option class="my-select-class" *ngFor="let year of years" [value]="year.y1">
        {{year.y2}}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field class="my-select-panel-class3">
    <mat-label>Hó</mat-label>
    <mat-select [(value)]="selectedMonth" (selectionChange)="onMonthChanged()">
      <mat-option class="my-select-class" *ngFor="let month of months" [value]="month.y1">
        {{month.y2}}
      </mat-option>
    </mat-select>
  </mat-form-field>
  &nbsp;&nbsp;&nbsp;
  <mat-slide-toggle *ngIf="helyettesito()" [checked]="false" (change)="changedHelyettesito($event)">Helyettesítő</mat-slide-toggle>
  &nbsp;&nbsp;&nbsp;
  <mat-slide-toggle  [checked]="false" (change)="changedMunkaido($event)">Munkaidő</mat-slide-toggle>
</div>


<div>
  <div style="overflow-x:scroll;">
    <table>
      <tr>
        <th class="h1" *ngFor="let col of headerdata">{{col}}</th>
      </tr>
      <tr *ngFor="let item of bodydata;let i=index">
        <td class="h2" *ngFor="let field of item|keyvalue;let j=index"
          [ngClass]="{'p':field.value == 'P',
                      't':field.value == 'T',
                      'o':field.value == 'O',
                      'm':field.value == 'm',
                      'u':field.value == 'ü',
                      'SZE' : also3kar(field.value) == 'SZE',
                      'SZY' : also3kar(field.value) == 'SZY',
                      'SZK' : also3kar(field.value) == 'SZK',
                      'HOE' : also3kar(field.value) == 'HOE',
                      'HOY' : also3kar(field.value) == 'HOY',
                      'HOK' : also3kar(field.value) == 'HOK',
                      'TME' : also3kar(field.value) == 'TME',
                      'TMY' : also3kar(field.value) == 'TMY',
                      'TMK' : also3kar(field.value) == 'TMK'
                     }">
          {{j>0?szoveg(field.value):field.value}}</td>
      </tr>
    </table>
  </div>
</div>
<button class="ures">SZ : szabadság, HO : homeoffice, TM : távmunka</button>
<button class="SZE">Engedélyezett</button>
<button class="SZY">Visszavonás elutasítva</button>
<button class="SZK">Kért</button>
<button class="t">T : Távollét</button>
<button class="o">O : Oktatás</button>
<button class="m">m : Munkaidő</button>
<button class="u">ü : Fiz.ünnep</button>
<br><br>

<!-- <div>
  <div style="overflow-x:scroll;">
    <table mat-table [dataSource]="bodydata">
      <ng-container [matColumnDef]="column" *ngFor="let column of headerdata;let j=index">
        <th class="h1" mat-header-cell *matHeaderCellDef>{{ column }}</th>
        <td class="h2" mat-cell *matCellDef="let element">{{element[column]}}</td>
      </ng-container>
      <tr class="h1" mat-header-row *matHeaderRowDef="headerdata"></tr>
      <tr mat-row *matRowDef="let row; columns: headerdata"></tr>
    </table>
  </div>
</div>
<br><br> -->

