import {Pipe, PipeTransform} from "@angular/core";
import {DecimalPipe} from "@angular/common";

@Pipe({
    name: "topNumber",
    standalone: true
})
export class TopNumberPipe implements PipeTransform
{
    constructor(private _number: DecimalPipe) {}

    public transform(value: any, digits?: string): string|null
    {
        if(!value && value !== 0)
            return null;
        return this._number.transform(value, digits);
    }
}

