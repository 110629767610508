import { CommonModule } from '@angular/common';
import { Component, OnInit,Inject,ViewChild,ElementRef,AfterViewInit } from '@angular/core';
import {Router, ActivatedRoute, RouterLink} from "@angular/router";
import { AppService } from '../app.service';
import {FormsModule, ReactiveFormsModule, UntypedFormControl} from '@angular/forms';    // FormsModule - az NG modellhez
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {MatDialogModule, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {MatSnackBar} from "@angular/material/snack-bar";
import {TopNumberPipe, TranslatePipe, TranslateService} from "top-core";
import { MatCardModule } from '@angular/material/card';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButton, MatButtonModule } from '@angular/material/button';
import { IJelenlet, TorzstarParams,KeyText,felvitelkulcsok,TfelvitelGyorsParams,TflevitelTolt} from '../model';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatTabsModule} from '@angular/material/tabs';

@Component({
  selector: 'app-felvitel',
  standalone: true,
  templateUrl: './felvitel.component.html',
  styleUrls: ['./felvitel.component.css'] ,
  imports: [
    FormsModule,  CommonModule, RouterLink, ReactiveFormsModule,
    TranslatePipe, TopNumberPipe,
    MatInputModule, MatSelectModule, MatTableModule, MatSelectModule, MatCardModule, MatSelectModule, MatExpansionModule,
    MatIconModule, MatListModule, MatAutocompleteModule, MatDialogModule, MatButtonModule, MatSlideToggleModule, MatDatepickerModule, MatTabsModule]
  })


export class FelvitelComponent implements OnInit {
  autocompleteJogcim      = new UntypedFormControl();   
  autocompleteFizhely     = new UntypedFormControl();   
  autocompleteMunkaszam   = new UntypedFormControl();   
  autocompleteKtghely     = new UntypedFormControl();   
  autocompleteSzervegys   = new UntypedFormControl();   
  autocompleteHelyettesito   = new UntypedFormControl();   
  //*************************************************** */
  jogcimek                : KeyText[] = [];
  fizhelyek               : KeyText[] = [];
  munkaszamok             : KeyText[] = [];
  ktghelyek               : KeyText[] = [];
  szervegysek             : KeyText[] = [];
  helyettesitok           : KeyText[] = [];
  //*************************************************** */
  filteredOptionsJogcim?    : Observable<KeyText[]>;
  filteredOptionsFizhely?   : Observable<KeyText[]>;
  filteredOptionsMunkaszam? : Observable<KeyText[]>;
  filteredOptionsKtghely?   : Observable<KeyText[]>;
  filteredOptionsSzervegys? : Observable<KeyText[]>;
  filteredOptionsHelyettesito? : Observable<KeyText[]>;
  //************************************************** */
  invchsubs!: any;
  _dolgozo: String = '';
  _mszerz: String = '';
  _ev: Number = 0;
  _ho: Number = 0;
  _ts: Number = 0;
  _nap: Number = 0;
  elojel_ : boolean = true;
  felvi : TflevitelTolt= new TflevitelTolt;

  matMenuTimer: any;
  
  constructor(private _service: AppService,private router: Router, private _snack: MatSnackBar, private _route: ActivatedRoute, private _proxy: AppService, 
    public dialogRef: MatDialogRef<FelvitelComponent>, @Inject(MAT_DIALOG_DATA) public data: TflevitelTolt) 
      {}

    
  ngOnInit(): void {
    this._dolgozo = this.data.dolgozo;
    this._mszerz = this.data.mszerz;
    this._ev = this.data.ev;
    this._ho = this.data.ho;
    this._nap = this.data.nap;
    this._ts = this.data.ts;
    this.data.ora = "00:00";
    this.data.tol = "00:00";
    this.data.ig  = "00:00";
    this.data.elojel = '+';
    this.data.elojel = '+';
    this.elojel_ = true;
    this.data.item = String(this.data.item);
    //************************************************************* */
    // Jogcím szótár
    //************************************************************* */
        this.autocompleteJogcim.valueChanges
        .pipe(
        startWith(''),         
        map(value => typeof value === 'string' ? this._proxy.jogcimekAC(value,String(this.data.item)) : this._proxy.jogcimekAC(value?.text,String(this.data.item)))
        ).subscribe(p=> this.filteredOptionsJogcim = p);
    //************************************************************* */
    // Fizhely szótár
    //************************************************************* */
        this.autocompleteFizhely.valueChanges
        .pipe(
        startWith(''),         
        map(value => typeof value === 'string' ? this._proxy.fizhelyekAC(value) : this._proxy.fizhelyekAC(value?.text))
        ).subscribe(p=> this.filteredOptionsFizhely = p);
    //************************************************************* */
    // Munkaszám szótár
    //************************************************************* */
        this.autocompleteMunkaszam.valueChanges
        .pipe(
        startWith(''),         
        map(value => typeof value === 'string' ? this._proxy.munkaszamokAC(value,this._ev,this._ho) : this._proxy.munkaszamokAC(value?.text,this._ev,this._ho))
        ).subscribe(p=> this.filteredOptionsMunkaszam = p);
    //************************************************************* */
    // Ktghely szótár
    //************************************************************* */
        this.autocompleteKtghely.valueChanges
        .pipe(
        startWith(''),         
        map(value => typeof value === 'string' ? this._proxy.ktghelyekAC(value,this._ev,this._ho) : this._proxy.ktghelyekAC(value?.text,this._ev,this._ho))
        ).subscribe(p=> this.filteredOptionsKtghely = p);
    //************************************************************* */
    // Szervegys szótár
    //************************************************************* */
        this.autocompleteSzervegys.valueChanges
        .pipe(
        startWith(''),         
        map(value => typeof value === 'string' ? this._proxy.szervegysekAC(value) : this._proxy.szervegysekAC(value?.text))
        ).subscribe(p=> this.filteredOptionsSzervegys = p);
    //************************************************************* */
    // Helyettesito szótár
    //************************************************************* */
    // this.autocompleteHelyettesito.valueChanges
    //   .pipe(
    //   startWith(''),         
    //   map(value => typeof value === 'string' ? this._proxy.helyettesitokAC(this.data.szervegys,value) : this._proxy.helyettesitokAC(this.data.szervegys,value?.text))
    // ).subscribe(p=> this.filteredOptionsHelyettesito = p);
    // this.autocompleteHelyettesito.valueChanges
    //   .pipe(
    //   startWith(''),         
    //   map(state => state ? this.filterHelyettesito(state) : this.helyettesitok.slice())
    // )
  }  


  filterHelyettesito(name: string) {
    return this.helyettesitok.filter(state =>
      state.text.toLowerCase().indexOf(name.toLowerCase()) === 0);
  }

  ngAfterViewInit() : void
  {
    var hh : KeyText;
    var par: TfelvitelGyorsParams = new (TfelvitelGyorsParams);
    par.dolgozo = this._dolgozo;
    par.mszerz = this._mszerz;
    par.ev = Number(this._ev);
    par.ho = Number(this._ho);
    par.nap= Number(this._nap);
    par.ts = Number(this._ts);
    if (par.ts==-1) {
      // ********
      // felvitel
      // ********
      this._proxy.getfelvitelTolt(par).subscribe(p => {
        this.felvi = p;
        this.autocompleteFizhely.setValue({key:this.felvi.fizhely,text:this.felvi.fizhelynev});
        this.autocompleteMunkaszam.setValue({key:this.felvi.munkaszam,text:this.felvi.munkaszamnev});
        this.autocompleteKtghely.setValue({key:this.felvi.ktghely,text:this.felvi.ktghelynev});
        this.autocompleteSzervegys.setValue({key:this.felvi.szervegys,text:this.felvi.szervegysnev});
        // this.autocompleteJogcim.setValue({key:this.felvi.jogcim,text:this.felvi.jogcimnev});
        if (this.felvi.helyettesito!='')
          this.autocompleteHelyettesito.setValue({key:this.felvi.helyettesito,text:this.felvi.helyettesitonev});
        //***************************************************************************************** */
        this.data.fizdate = new Date(this.felvi.fizdate);
        this.data.fizhely = this.felvi.fizhely;
        this.data.munkaszam = this.felvi.munkaszam;
        this.data.ktghely = this.felvi.ktghely;
        this.data.szervegys = this.felvi.szervegys;
        this.data.helyettesito = this.felvi.helyettesito;
        this.data.ora = this.felvi.ora;
        this._proxy.helyettesitokAC(this.data.szervegys,'').subscribe(p=>{
          this.helyettesitok=p;
          this.filteredOptionsHelyettesito = this.autocompleteHelyettesito.valueChanges
          .pipe(
          startWith(''),         
          map(state => state ? this.filterHelyettesito(state) : this.helyettesitok.slice()))
        });
      });
    }
    else {
      // *********
      // módosítás
      // *********
      this._proxy.getmodositasTolt(par).subscribe(p => {
        this.felvi = p;
        this.autocompleteJogcim.setValue({key:this.felvi.jogcim,text:this.felvi.jogcimnev});        
        this.autocompleteFizhely.setValue({key:this.felvi.fizhely,text:this.felvi.fizhelynev});
        this.autocompleteMunkaszam.setValue({key:this.felvi.munkaszam,text:this.felvi.munkaszamnev});
        this.autocompleteKtghely.setValue({key:this.felvi.ktghely,text:this.felvi.ktghelynev});
        this.autocompleteSzervegys.setValue({key:this.felvi.szervegys,text:this.felvi.szervegysnev});
        this.autocompleteHelyettesito.setValue({key:this.felvi.helyettesito,text:this.felvi.helyettesitonev});
        //***************************************************************************************** */
        this.data.jogcim = this.felvi.jogcim;
        this.data.ora = this.felvi.ora;
        this.data.tol = this.felvi.tol;
        this.data.ig = this.felvi.ig;
        this.data.elojel = this.felvi.elojel;
        if (this.data.elojel=='+')
          this.elojel_ = true;
        else
          this.elojel_ = false;
        this.data.fizdate = new Date(this.felvi.fizdate);
        this.data.fizhely = this.felvi.fizhely;
        this.data.munkaszam = this.felvi.munkaszam;
        this.data.ktghely = this.felvi.ktghely;
        this.data.szervegys = this.felvi.szervegys;
        this.data.helyettesito = this.felvi.helyettesito;
        this._proxy.helyettesitokAC(this.data.szervegys,'').subscribe(p=>{this.helyettesitok=p});
      });
    }
}
  //************************************************************* */
  // Jogcím szótár
  //************************************************************* */
  onJogcimChange(event: any){
    this.data.jogcim = event.option.value.key;
  }
  handleEmptyInputJogcim(event: any){
    if(event.target.value === '') {
       this.data.jogcim = "";
    }
  }
  displayJogcim(ktgs: KeyText): string {
    return ktgs && ktgs.text ? ktgs.text : '';
  }
  //************************************************************* */
  // Fizhely szótár
  //************************************************************* */
  onFizhelyChange(event: any){
    this.data.fizhely= event.option.value.key;
  }
  handleEmptyInputFizhely(event: any){
    if(event.target.value === '') {
       this.data.fizhely = "";
    }
  }
  displayFizhely(ktgs: KeyText): string {
    return ktgs && ktgs.text ? ktgs.text : '';
  }
  //************************************************************* */
  // Munkaszám szótár
  //************************************************************* */
  onMunkaszamChange(event: any){
    this.data.munkaszam= event.option.value.key;
  }
  handleEmptyInputMunkaszam(event: any){
    if(event.target.value === '') {
       this.data.munkaszam = "";
    }
  }
  displayMunkaszam(ktgs: KeyText): string {
    return ktgs && ktgs.text ? ktgs.text : '';
  }
  //************************************************************* */
  // Ktghely szótár
  //************************************************************* */
  onKtghelyChange(event: any){
    this.data.ktghely= event.option.value.key;
  }
  handleEmptyInputKtghely(event: any){
    if(event.target.value === '') {
       this.data.ktghely = "";
    }
  }
  displayKtghely(ktgs: KeyText): string {
    return ktgs && ktgs.text ? ktgs.text : '';
  }
  //************************************************************* */
  // Szervegys szótár
  //************************************************************* */
  onSzervegysChange(event: any){
    this.data.szervegys= event.option.value.key;
  }
  handleEmptyInputSzervegys(event: any){
    if(event.target.value === '') {
       this.data.szervegys = "";
    }
  }
  displaySzervegys(ktgs: KeyText): string {
    return ktgs && ktgs.text ? ktgs.text : '';
  }
  //************************************************************* */
  // Helyettesítő szótár
  //************************************************************* */
  onHelyettesitoChange(event: any){
    this.data.helyettesito= event.option.value.key;
  }
  handleEmptyInputHelyettesito(event: any){
    if(event.target.value === '') {
       this.data.helyettesito = "";
    }
  }
  displayHelyettesito(ktgs: KeyText): string {
    return ktgs && ktgs.text ? ktgs.text : '';
  }
  //******************************************************* */
  // OK
  //******************************************************* */
  onClick(): void {
    this.matMenuTimer = setTimeout( () => {this.onClick_();}, 300); 
  }
  onClick_(): void {
    if (!this.matMenuTimer) return;
    this._service.jelenletTran(this.data,this.data.selectTomb).subscribe(p => {
      this.dialogRef.close();
    });
  }
  onDblClick(): void {
    clearTimeout(this.matMenuTimer);
    this.matMenuTimer = undefined;
  }
  //******************************************************* */
  // Cancel
  //******************************************************* */
  onNoClick(): void {
    this.dialogRef.close();
  }
  //******************************************************* */
  // Egyéb
  //******************************************************* */
  changedElojel(e: any): void {
    if (!e.checked) 
      this.data.elojel = '-';
    else 
      this.data.elojel = '+';
  }

  KellHelyettesito():boolean {
    if ((this.data.item=='kerelem') && (this._service._Berinst().helyettesito))
      return true;
    else
      return false;
  }
  KellToligora():boolean {
    // if ((this.data.item!='kerelem'))
    if (this._service._Berinst().toligora)
      return true;
    else
      return false;
  }

}
