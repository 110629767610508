export const LANGUAGE_TEXTS =
{
  "en": {
    "en": "English",
    "hu": "Angol"
  },
  "loginHeader": {
    "en": "Login to the site",
    "hu": "Bejelentkezés"
  },
  "loginBtn": {
    "en": "Login",
    "hu": "Belépés"
  },
  "userIdDetail": {
    "en": "User identifier",
    "hu": "Felhasználói azonosító"
  },
  "year": {
    "en": "Year",
    "hu": "Év"
  },
  "hu": {
    "en": "Hungarian",
    "hu": "Magyar"
  },
  "profile": {
    "en": "User profile",
    "hu": "Felhasználói profil"
  },
  "passwordDetail": {
    "en": "Password",
    "hu": "Jelszó"
  },
  "pDFjelszocsere": {
    "en": "PDF password change",
    "hu": "PDF jelszócsere"
  },
  "jelszocsere": {
    "en": "Password change",
    "hu": "Jelszócsere"
  },
  "kafeteria": {
    "en": "Cafetria",
    "hu": "Kafetéria"
  },
  "mainPageTitle": {
    "en": "Presence",
    "hu": "Jelenlét"
  },
  "moveTopsoft": {
    "en": "Topsoft Zrt.",
    "hu": "Topsoft Zrt."
  },
  "jovfiz": {
    "en": "Income pay sheets",
    "hu": "Jövedelem fizetési lapok"
  },
  "listak": {
    "en": "Presence lists",
    "hu": "Jelenlét listák"
  },
  "hitelesites": {
    "en": "Vacation authentication",
    "hu": "Szabadság hitelesités"
  },
  "engedely": {
    "en": "Vacation permission",
    "hu": "Szabadság engedély"
  },
  "ekaker": {
    "en": "EAKER",
    "hu": "EAKER"
  },
  "modosit": {
    "en": "Data update (email)",
    "hu": "Adat módositás (email)"
  },
  "kerelem": {
    "en": "Vacation request",
    "hu": "Szabadság kérelem"
  },
  "bnapi": {
    "en": "BDaily presence",
    "hu": "BNapi jelenlét"
  },
  "napi": {
    "en": "Daily presence",
    "hu": "Napi jelenlét"
  },
  "titleA": {
    "en": "Bér, TB, Munkaügy",
    "hu": "Bér, TB, Munkaügy"
  },
  "yes": {
    "en": "Yes",
    "hu": "Igen"
  },
  "areYouSure": {
    "en": "Are you sure?",
    "hu": "Biztos benne?"
  },
  "no": {
    "en": "No",
    "hu": "Nem"
  },
  "logoff": {
    "en": "Logoff",
    "hu": "Kijelentkezés"
  },
  "ok": {
    "en": "Ok",
    "hu": "Rendben"
  },
  "sendlogtotopsoft": {
    "en": "Send Log file to topsoft",
    "hu": "Naplófájl elküldése Topsoft-nak"
  },
  "senttotopsoft": {
    "en": "Log file sent!",
    "hu": "Napló fájl elküldve!"
  },
  "chgPwdHeader": {
    "en": "Change the password",
    "hu": "Jelszó csere"
  },
  "password": {
    "en": "Password",
    "hu": "Jelszó"
  },
  "password2": {
    "en": "Password again",
    "hu": "Jelszó megint"
  }
};
