<form novalidate> <!-- <br> --> <!-- &nbsp; -->
    <h1 mat-dialog-title>
        <mat-icon color="primary">editcalendar</mat-icon> Szabadság infó
    </h1>
    <div mat-dialog-content>

        <!-- *************************************************************************
        Táblázat 0
        ************************************************************************* -->
        <table mat-table [dataSource]="dataSource0">

            <ng-container matColumnDef="szoveg">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element"> {{element.szoveg}} </td>
            </ng-container>

            <ng-container matColumnDef="felh">
                <th mat-header-cell *matHeaderCellDef> Ált. </th>
                <td mat-cell *matCellDef="let element"> {{ (element.felh==0)?"":element.felh }} </td>
            </ng-container>

            <ng-container matColumnDef="tanul">
                <th mat-header-cell *matHeaderCellDef> Tanul. </th>
                <td mat-cell *matCellDef="let element"> {{ (element.tanul==0)?"":element.tanul }} </td>
            </ng-container>

            <ng-container matColumnDef="rendk">
                <th mat-header-cell *matHeaderCellDef> Rendk. </th>
                <td mat-cell *matCellDef="let element"> {{ (element.rendk==0)?"":element.rendk }} </td>
            </ng-container>

            <ng-container matColumnDef="ossz">
                <th mat-header-cell *matHeaderCellDef> Össz. </th>
                <td mat-cell *matCellDef="let element"> {{ (element.ossz==0)?"":element.ossz }} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns0"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns0;"></tr>
        </table>
        <!-- *************************************************************************
        Táblázat 1
        ************************************************************************* -->
        <br>
        <table mat-table [dataSource]="dataSource">

            <ng-container matColumnDef="ho">
                <th mat-header-cell *matHeaderCellDef> Hó </th>
                <td mat-cell *matCellDef="let element"> {{element.ho}} </td>
            </ng-container>

            <ng-container matColumnDef="tol">
                <th mat-header-cell *matHeaderCellDef> Tól </th>
                <td mat-cell *matCellDef="let element"> {{element.tol}} </td>
            </ng-container>

            <ng-container matColumnDef="ig">
                <th mat-header-cell *matHeaderCellDef> Ig </th>
                <td mat-cell *matCellDef="let element"> {{element.ig}} </td>
            </ng-container>

            <ng-container matColumnDef="nap">
                <th mat-header-cell *matHeaderCellDef> Nap </th>
                <td mat-cell *matCellDef="let element"> {{element.nap}} </td>
            </ng-container>

            <ng-container matColumnDef="kerelem">
                <th mat-header-cell *matHeaderCellDef> Dátumok </th>
                <td mat-cell *matCellDef="let element">
                    <div *ngIf="(element.k!=='')">Kér: {{element.k}}</div>
                    <div *ngIf="(element.f!=='')">Eng: {{element.f}}</div>
                    <div *ngIf="(element.s!=='')">Tör: {{element.s}}</div>
                    <div *ngIf="(element.v!=='')">Vis: {{element.v}}</div>
                    <div *ngIf="(element.e!=='')">Elu: {{element.e}}</div>
                    <div *ngIf="(element.w!=='')">VisEng: {{element.v}}</div>
                    <div *ngIf="(element.y!=='')">VisElu: {{element.e}}</div>
                </td>
            </ng-container>


            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>




    </div>

    <div mat-dialog-actions>
        <button mat-raised-button [mat-dialog-close]="data" color="primary" cdkFocusInitial>
            <mat-icon>done</mat-icon>OK
        </button>
    </div>
</form>