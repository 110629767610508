import {Component, OnInit, ViewChild, ElementRef} from "@angular/core";
import { AuthParams, AuthProfile, AuthService, CurrentWebSite, TranslatePipe } from "top-core";
import {Router} from "@angular/router";
import { DateAdapter, MatOptionModule } from "@angular/material/core";
import { FormBuilder, Validators, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatSelectModule } from "@angular/material/select";
import { MatInputModule } from "@angular/material/input";
import { MatFormFieldModule } from "@angular/material/form-field";
import { NgIf, NgFor } from "@angular/common";
import { MatCardModule } from "@angular/material/card";
import { Berinstall } from './model';
import { AppService } from './app.service';
import { AppComponent } from './app.component';

/**
 * Login record
 */
export interface Login {
    userName: string;
    password: string;
    year: number;
    profile: AuthProfile;
}

@Component({
    selector: "top-login",
    templateUrl: "./login.component.html",
    styles: [
        `
            mat-card {
                max-width: 400px;
                margin: 2em auto;
                text-align: center;
            }

            mat-form-field {
                display: block;
            }
        `
    ],
    standalone: true,
    imports: [MatCardModule, FormsModule, ReactiveFormsModule, NgIf, MatFormFieldModule, MatInputModule, MatSelectModule, NgFor, MatOptionModule, MatButtonModule, TranslatePipe]
})
export class LoginComponentSpec implements OnInit
{
    @ViewChild("userIdCtrl") userIdCtrl?: ElementRef;

    form = this._fb.nonNullable.group({
        userId: [""],
        userName: ["", [Validators.required, Validators.maxLength(100)]],
        password: [""],
        year: [new Date().getFullYear(), [Validators.min(1999), Validators.max(2100)]],
        profile: new AuthProfile(),
        title: [""],
    }, {updateOn: "blur"});

    //profile: AuthProfile = new AuthProfile();
    selfChange: boolean = false;
    webSite: CurrentWebSite = new CurrentWebSite();

    constructor(
        private _fb: FormBuilder,
        private _auth: AuthService,
        private _router: Router,
        private _dateAdapter: DateAdapter<Date>,
        private _service: AppService,
        private _appcomp: AppComponent,
    )
    {
        this._appcomp.title = "TopInfoWeb";
    }

    ngOnInit(): void
    {
        this.form.patchValue({ year: new Date().getFullYear() });
        if(this.userIdCtrl != undefined)
            this.userIdCtrl.nativeElement.focus();
        
        // this._auth.site().subscribe((site) => {
        //     this.webSite = site;
        //     let defprof = localStorage.getItem("pro");
        //     if (defprof == null)
        //         defprof = site.profile;
        //     this.form.patchValue({profile: defprof});
        // });

        this._auth.site().subscribe((site) => {
            this.webSite = site;   
            let pn = localStorage.getItem('profilename');
            if (!pn)
                pn = site.profile;         
            for(var i in site.profiles)
                if(site.profiles[i].name == pn)
                {                    
                    this.form.patchValue({profile: site.profiles[i]});
                    break;
                }
        });

        this._dateAdapter.setLocale("hu-HU");
    }

    ngOnDestroy() {
    }

    public login(): void
    {
        const l: Login = <Login> this.form.value;

        let pars = new AuthParams();
        pars.UserId = l.userName;
        pars.Password = l.password;
        pars.Profile = l.profile;
        pars.Date = new Date();
        pars.Year = l.year;

        this._auth.login(pars).subscribe((user) => {
            let pn = pars.Profile? pars.Profile.name:"";
            localStorage.setItem('profilename', pn);
            if(user?.need2FA)
            {
                const token = window.prompt("Adja meg a hitelesítési kódot", "");
                if(token)
                    this._auth.check2FA(token).subscribe(b => {
                        if(b) {
                            this._service.fetchBerinstallLogin().subscribe(p => {
                                this._router.navigate(["/"]);
                              });
                                                   
                        }
                });
            }
            else {
                this._service.fetchBerinstallLogin().subscribe(p => {
                    this._router.navigate(["/"]);
                });
}
        });
    }
}
