import {Injectable, EventEmitter, OnDestroy} from '@angular/core';
import {AudioService} from './audio.service';
import {fromEvent, Subscription} from "rxjs";

@Injectable({
  providedIn: 'root',
})
export class BarcodeService implements OnDestroy
{
    barcode : string ="";
    interruptMsg: string ="";
    lastKeyStroke: Date = new Date();
    keyboardSub?: Subscription;
    private readonly _onBarcodeChanged: EventEmitter<string>;
    private _onError: EventEmitter<string>;

    public addKey(key: string): void
    {
        if(key.length != 1)
            return;

        const now = new Date();
        const duration = now.valueOf() - this.lastKeyStroke.valueOf();
        if(duration > 50)
            this.barcode = "";
        this.lastKeyStroke = now;

        this.barcode += key;        
    }

    public interrupt(msg: string): void
    {
        this.interruptMsg = msg;        
    }

    public getBarcode(): string
    {
        return this.barcode;
    }

    constructor(private _sound: AudioService)
    {
        this._onError = new EventEmitter<string>();
        this.keyboardSub = fromEvent(window, 'keydown').subscribe((ev => {
            const event = <KeyboardEvent>ev;
           // alert(event.key);
            if(event.key == "Unidentified")
                return;

            console.log("Keydown:");
            console.log(ev);

            const now = new Date();
            const duration = now.valueOf() - this.lastKeyStroke.valueOf();
            var killit= event.key == "@"? true: false;
            if(duration > 100)
                this.barcode = "";
            else
                killit = true;
            this.lastKeyStroke = now;
            
            if(event.key === 'Enter')
            {
                if(this.barcode.length > 0)
                {
                    console.log("Got barcode '" + this.barcode + "'");
                    if(!this._isVolumeBarCode(this.barcode) && !this._isInterrupted()){
                        this._sound.playBeep();
                        this._onBarcodeChanged.emit(this.barcode);                        
                    }
                        
                    this.barcode = "";
                    ev.preventDefault();
                    ev.stopPropagation();
                }else
                    document.body.focus();
            }
            
            else if(event.key.length == 1){
                this.barcode += event.key;
                //console.log("partialbcode_keydown:"+this.barcode);
            }
                
            
            if (killit){
                console.log("killit");
                ev.preventDefault();
                ev.stopPropagation();
            }
        }));

        this._onBarcodeChanged = new EventEmitter<string>();
    }
    
    public get onError(): EventEmitter<string>
    {
        return this._onError;
    }

    ngOnDestroy()
    {
        this.keyboardSub?.unsubscribe();
    }

    private _isInterrupted(): boolean
    {
        if (this.interruptMsg!="")
        {                        
            this._onError.emit(this.interruptMsg);
            this.interruptMsg = "";
            return true;
        }
            
        return false;
    }

    private _isVolumeBarCode(bc: string): boolean
    {
        const volumeUp = "4028017545228";
        const volumeDown = "1203116204006";
        if(bc == volumeUp)
        {
            this._sound.volumeUp();
            return true;
        }

        if(bc == volumeDown)
        {
            this._sound.volumeDown();
            return true;
        }

        return false;
    }
    
    public get onBarcodeChanged(): EventEmitter<string>
    {
        return this._onBarcodeChanged;
    }
}
