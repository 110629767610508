/**
 * Created by lendvai on 2017.05.17.
 */

import {EventEmitter, Injectable} from "@angular/core";
import {Observable, Observer} from "rxjs";
import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import {AuthParams} from "../security/auth-params";
import {TaskState} from "../core/global";
import {Utils} from "../core/utils";
import {Router} from "@angular/router";
import { User } from "../security/user";

/**
 * Class to call remote methods
 */
@Injectable()
export class HttpSenderService
{
    /**
     * User token
     */
    private _token: string = "";

    private _onError: EventEmitter<string>;

    /**
     * Authorization parameters
     */
    public Authorization: AuthParams;

    constructor(private _http: HttpClient, private _router: Router)
    {
        this.Authorization = new AuthParams();
        this._onError = new EventEmitter<string>();
        this._token = sessionStorage.getItem("token") ?? "";
    }

    public get onError(): EventEmitter<string>
    {
        return this._onError;
    }

    /**
     * Create http remote call options
     *
     * @param method  remote method name ( for example top.ITest.Echo )
     * @param nonce   remote seed value
     * @returns {BaseRequestOptions} request options
     */
    private _options(nonce?: string): { }
    {
        let headers = new HttpHeaders({"Accept": "application/json"});

        headers = headers.set("X-Top-Host", window.location.origin);

        if(this._token)
            headers = headers.set("X-User-Token", this._token);

        if(this.Authorization && nonce != undefined)
            headers = headers.set("X-Top-Authorization2", Utils.toBase64(this.Authorization.responseHeader(nonce)));

        const options = {
            headers: headers,
            observe: "response"
        };

        return options;
    }

    private _getHeader(headers: HttpHeaders, name: string): string | undefined
    {
        const ret = headers.get(name);
        return ret == null ? undefined : ret;
    }

    private _getHeaderDef(headers: HttpHeaders, name: string, def: string = ""): string
    {
        const ret = headers.get(name);
        return ret == null ? def : ret;
    }
    /**
     * Process HTTP response
     *
     * @param ret       the return value
     * @param resp      the HTTP response
     * @param method    called method
     * @param args      method arguments
     * @private
     */
    private _parseResponse<T>(ret: Observer<T>, resp: HttpResponse<T>, method: string, args?: any[]): void
    {
        const origToken = this._token;
        let s = resp.headers.get("X-User-Token");
        if(s)
        {
            this._token = s;
            sessionStorage.setItem("token", s);
        }

        if(resp.status / 100 !== 2)
        {
            ret.error(resp.statusText);
            return;
        }
        s = resp.headers.get("X-Top-ErrorMessage");
        if(s && s.length)
        {
            s = Utils.fromBase64(s);
            const stack = resp.headers.get("X-Top-ErrorStackTrace");
            if(stack && stack.length)
                s = s + "\n" + Utils.fromBase64(stack);
            this._onError.emit(s);
            ret.error(s);
            return;
        }

        let ts = TaskState.Processed;
        s = resp.headers.get("X-Top-TaskState");
        if(s && s.length)
            ts = parseInt(s, 10);
        switch(ts)
        {
            case TaskState.Processed:
                ret.next(resp.body!);
                break;

            case TaskState.AuthNeeded:
                
                const nonce = this._getHeaderDef(resp.headers, "X-Top-Nonce");
                if(nonce.length > 0 && this.Authorization)
                {                                        
                    if((origToken.length == 0 || origToken == this._token) && this.Authorization.canAuth)
                        setTimeout(() => this._sendRequest(ret, method, args, this._getHeaderDef(resp.headers, "X-Top-Nonce")), 600);
                    else{
                        console.log("origt:"+origToken+";t:"+this._token);                        
                        this._onError.emit("login error");
                        ret.error("login error");
                        sessionStorage.setItem("tusr", JSON.stringify(new User()));
                        sessionStorage.removeItem("token");                        
                        location.reload();
                        return;                
                        //window.location.href = "/login";
                        //this._router.navigate(["/login"]);// ez a navigate valamiert homokoraban marad.. 
                    }
                        
                }
                break;

            default:
                setTimeout(() => this._sendRequest(ret, method, args), 500);
                break;
        }
    }

    private _sendRequest<T>(ret: Observer<T>, method: string, args?: any[], nonce?: string)
    {
        this._http
            .post(method, args, this._options(nonce))
            .subscribe(
                response => this._parseResponse(ret, <HttpResponse<T>> response, method, args),
                error => {
                    console.log(error.message);
                    this._onError.emit(error.message);
                }
            );
    }

    public resettoken(): void
    {
        this._token = "";
    }

    /**
     * Execute a HTTP method
     * @param {string} method   method name
     * @param {any[]} args      method calling arguments
     * @returns {Observable<T>} the response
     */
    exec<T>(method: string, args?: any[]): Observable<T>
    {
        return new Observable<T>((observer) =>{
            this._sendRequest(observer, method, args);
        });
    }
}
