/**
 * Calculate MD5 hash value
 */
export class Md5
{
    private static rotateLeft(lValue: number, iShiftBits: number)
    {
        return (lValue<<iShiftBits) | (lValue>>>(32-iShiftBits));
    }

    private static addUnsigned(lX: number, lY: number): number
    {
        const lX8 = lX & 0x80000000;
        const lY8 = lY & 0x80000000;
        const lX4 = lX & 0x40000000;
        const lY4 = lY & 0x40000000;
        const lResult = (lX & 0x3FFFFFFF) + (lY & 0x3FFFFFFF);
        if(lX4 & lY4)
            return lResult ^ 0x80000000 ^ lX8 ^ lY8;
        if(lX4 | lY4)
            return lResult & 0x40000000 ? lResult ^ 0xC0000000 ^ lX8 ^ lY8 : lResult ^ 0x40000000 ^ lX8 ^ lY8;
        return lResult ^ lX8 ^ lY8;
    }

    private static f(x: number, y: number, z: number): number
    {
        return (x & y) | ((~x) & z);
    }

    private static g(x: number, y: number, z: number): number
    {
        return (x & z) | (y & (~z));
    }

    private static h(x: number, y: number, z: number): number
    {
        return (x ^ y ^ z);
    }

    private static I(x: number, y: number, z: number): number
    {
        return (y ^ (x | (~z)));
    }

    private static ff(a: number, b: number, c: number, d: number, x: number, s: number, ac: number): number
    {
        a = this.addUnsigned(a, this.addUnsigned(this.addUnsigned(this.f(b, c, d), x), ac));
        return this.addUnsigned(this.rotateLeft(a, s), b);
    }

    private static gg(a: number, b: number, c: number, d: number, x: number, s: number, ac: number): number
    {
        a = this.addUnsigned(a, this.addUnsigned(this.addUnsigned(Md5.g(b, c, d), x), ac));
        return this.addUnsigned(this.rotateLeft(a, s), b);
    }

    private static hh(a: number, b: number, c: number, d: number, x: number, s: number, ac: number): number
    {
        a = this.addUnsigned(a, this.addUnsigned(this.addUnsigned(Md5.h(b, c, d), x), ac));
        return this.addUnsigned(this.rotateLeft(a, s), b);
    }

    private static ii(a: number, b: number, c: number, d: number, x: number, s: number, ac: number): number
    {
        a = this.addUnsigned(a, this.addUnsigned(this.addUnsigned(this.I(b, c, d), x), ac));
        return this.addUnsigned(this.rotateLeft(a, s), b);
    }

    private static convertToWordArray(s: string): number[]
    {
        let lWordCount: number;
        const lMessageLength = s.length;
        const lNumberOfWordsTemp1=lMessageLength + 8;
        const lNumberOfWordsTemp2= (lNumberOfWordsTemp1 - lNumberOfWordsTemp1 % 64) / 64;
        const lNumberOfWords = (lNumberOfWordsTemp2 + 1) * 16;
        const lWordArray = new Array<number>(lNumberOfWords - 1);
        let lBytePosition: number;
        let lByteCount: number;
        for(lByteCount = 0; lByteCount < lMessageLength; ++lByteCount)
        {
            lWordCount = (lByteCount - lByteCount % 4) / 4;
            lBytePosition = (lByteCount % 4) *8;
            lWordArray[lWordCount] = lWordArray[lWordCount] | (s.charCodeAt(lByteCount) << lBytePosition);
        }
        lWordCount = (lByteCount - lByteCount % 4) / 4;
        lBytePosition = (lByteCount % 4) *8;
        lWordArray[lWordCount] = lWordArray[lWordCount] | (0x80 << lBytePosition);
        lWordArray[lNumberOfWords - 2] = lMessageLength << 3;
        lWordArray[lNumberOfWords - 1] = lMessageLength >>> 29;
        return lWordArray;
    }

    private static wordToHex(lValue: number): string
    {
        let wordToHexValue = "";
        for(let lCount = 0; lCount <= 3; ++lCount)
        {
            const lByte = (lValue >>> lCount * 8) & 255;
            const wordToHexValueTemp = `0${lByte.toString(16)}`;
            wordToHexValue = wordToHexValue + wordToHexValueTemp.substr(wordToHexValueTemp.length - 2, 2);
        }
        return wordToHexValue;
    }

    private static utf8Encode(s: string): string
    {
        s = s.replace(/\r\n/g,"\n");
        let utftext = "";

        for(let n = 0; n < s.length; n++)
        {
            const c = s.charCodeAt(n);

            if(c < 128)
                utftext += String.fromCharCode(c);
            else if(c > 127 && c < 2048)
            {
                utftext += String.fromCharCode((c >> 6) | 192);
                utftext += String.fromCharCode((c & 63) | 128);
            }
            else
            {
                utftext += String.fromCharCode((c >> 12) | 224);
                utftext += String.fromCharCode(((c >> 6) & 63) | 128);
                utftext += String.fromCharCode((c & 63) | 128);
            }
        }
        return utftext;
    }

    public static md5(s: string): string
    {
        const s11 = 7, s12 = 12, s13 = 17, s14 = 22;
        const s21 = 5, s22 =  9, s23 = 14, s24 = 20;
        const s31 = 4, s32 = 11, s33 = 16, s34 = 23;
        const s41 = 6, s42 = 10, s43 = 15, s44 = 21;

        s = Md5.utf8Encode(s);

        const x = Md5.convertToWordArray(s);
        let a = 0x67452301; let b = 0xEFCDAB89; let c = 0x98BADCFE; let d = 0x10325476;

        for(let k = 0; k < x.length; k += 16)
        {
            const aa = a; const bb = b; const cc = c; const dd = d;
            a=Md5.ff(a,b,c,d,x[k+0], s11,0xD76AA478);
            d=Md5.ff(d,a,b,c,x[k+1], s12,0xE8C7B756);
            c=Md5.ff(c,d,a,b,x[k+2], s13,0x242070DB);
            b=Md5.ff(b,c,d,a,x[k+3], s14,0xC1BDCEEE);
            a=Md5.ff(a,b,c,d,x[k+4], s11,0xF57C0FAF);
            d=Md5.ff(d,a,b,c,x[k+5], s12,0x4787C62A);
            c=Md5.ff(c,d,a,b,x[k+6], s13,0xA8304613);
            b=Md5.ff(b,c,d,a,x[k+7], s14,0xFD469501);
            a=Md5.ff(a,b,c,d,x[k+8], s11,0x698098D8);
            d=Md5.ff(d,a,b,c,x[k+9], s12,0x8B44F7AF);
            c=Md5.ff(c,d,a,b,x[k+10],s13,0xFFFF5BB1);
            b=Md5.ff(b,c,d,a,x[k+11],s14,0x895CD7BE);
            a=Md5.ff(a,b,c,d,x[k+12],s11,0x6B901122);
            d=Md5.ff(d,a,b,c,x[k+13],s12,0xFD987193);
            c=Md5.ff(c,d,a,b,x[k+14],s13,0xA679438E);
            b=Md5.ff(b,c,d,a,x[k+15],s14,0x49B40821);
            a=Md5.gg(a,b,c,d,x[k+1], s21,0xF61E2562);
            d=Md5.gg(d,a,b,c,x[k+6], s22,0xC040B340);
            c=Md5.gg(c,d,a,b,x[k+11],s23,0x265E5A51);
            b=Md5.gg(b,c,d,a,x[k+0], s24,0xE9B6C7AA);
            a=Md5.gg(a,b,c,d,x[k+5], s21,0xD62F105D);
            d=Md5.gg(d,a,b,c,x[k+10],s22,0x2441453);
            c=Md5.gg(c,d,a,b,x[k+15],s23,0xD8A1E681);
            b=Md5.gg(b,c,d,a,x[k+4], s24,0xE7D3FBC8);
            a=Md5.gg(a,b,c,d,x[k+9], s21,0x21E1CDE6);
            d=Md5.gg(d,a,b,c,x[k+14],s22,0xC33707D6);
            c=Md5.gg(c,d,a,b,x[k+3], s23,0xF4D50D87);
            b=Md5.gg(b,c,d,a,x[k+8], s24,0x455A14ED);
            a=Md5.gg(a,b,c,d,x[k+13],s21,0xA9E3E905);
            d=Md5.gg(d,a,b,c,x[k+2], s22,0xFCEFA3F8);
            c=Md5.gg(c,d,a,b,x[k+7], s23,0x676F02D9);
            b=Md5.gg(b,c,d,a,x[k+12],s24,0x8D2A4C8A);
            a=Md5.hh(a,b,c,d,x[k+5], s31,0xFFFA3942);
            d=Md5.hh(d,a,b,c,x[k+8], s32,0x8771F681);
            c=Md5.hh(c,d,a,b,x[k+11],s33,0x6D9D6122);
            b=Md5.hh(b,c,d,a,x[k+14],s34,0xFDE5380C);
            a=Md5.hh(a,b,c,d,x[k+1], s31,0xA4BEEA44);
            d=Md5.hh(d,a,b,c,x[k+4], s32,0x4BDECFA9);
            c=Md5.hh(c,d,a,b,x[k+7], s33,0xF6BB4B60);
            b=Md5.hh(b,c,d,a,x[k+10],s34,0xBEBFBC70);
            a=Md5.hh(a,b,c,d,x[k+13],s31,0x289B7EC6);
            d=Md5.hh(d,a,b,c,x[k+0], s32,0xEAA127FA);
            c=Md5.hh(c,d,a,b,x[k+3], s33,0xD4EF3085);
            b=Md5.hh(b,c,d,a,x[k+6], s34,0x4881D05);
            a=Md5.hh(a,b,c,d,x[k+9], s31,0xD9D4D039);
            d=Md5.hh(d,a,b,c,x[k+12],s32,0xE6DB99E5);
            c=Md5.hh(c,d,a,b,x[k+15],s33,0x1FA27CF8);
            b=Md5.hh(b,c,d,a,x[k+2], s34,0xC4AC5665);
            a=Md5.ii(a,b,c,d,x[k+0], s41,0xF4292244);
            d=Md5.ii(d,a,b,c,x[k+7], s42,0x432AFF97);
            c=Md5.ii(c,d,a,b,x[k+14],s43,0xAB9423A7);
            b=Md5.ii(b,c,d,a,x[k+5], s44,0xFC93A039);
            a=Md5.ii(a,b,c,d,x[k+12],s41,0x655B59C3);
            d=Md5.ii(d,a,b,c,x[k+3], s42,0x8F0CCC92);
            c=Md5.ii(c,d,a,b,x[k+10],s43,0xFFEFF47D);
            b=Md5.ii(b,c,d,a,x[k+1], s44,0x85845DD1);
            a=Md5.ii(a,b,c,d,x[k+8], s41,0x6FA87E4F);
            d=Md5.ii(d,a,b,c,x[k+15],s42,0xFE2CE6E0);
            c=Md5.ii(c,d,a,b,x[k+6], s43,0xA3014314);
            b=Md5.ii(b,c,d,a,x[k+13],s44,0x4E0811A1);
            a=Md5.ii(a,b,c,d,x[k+4], s41,0xF7537E82);
            d=Md5.ii(d,a,b,c,x[k+11],s42,0xBD3AF235);
            c=Md5.ii(c,d,a,b,x[k+2], s43,0x2AD7D2BB);
            b=Md5.ii(b,c,d,a,x[k+9], s44,0xEB86D391);
            a=this.addUnsigned(a,aa);
            b=this.addUnsigned(b,bb);
            c=this.addUnsigned(c,cc);
            d=this.addUnsigned(d,dd);
        }

        const temp = Md5.wordToHex(a) + Md5.wordToHex(b) + Md5.wordToHex(c) + Md5.wordToHex(d);
        return temp.toLowerCase();
    }
}
