import {AfterViewInit, Component, ElementRef, Input, Renderer2, ViewChild} from "@angular/core";
import {MatDialogRef} from "@angular/material/dialog";

@Component({
    selector: "safe-html",
    template: "<div #root></div>",
    standalone: true
})
export class SafeHtmlComponent implements AfterViewInit
{
    @ViewChild('root') root!: ElementRef;
    @Input() text = "";

    constructor(private _r: Renderer2) {}

    ngAfterViewInit()
    {
        if(this.root)
            this._r.setProperty(this.root.nativeElement,'innerHTML', this.text);
    }
}
