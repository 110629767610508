<mat-card class="kezdolap-card">
    <mat-card-header>
      
      <mat-card-title style="text-align: center;">
        <i class="sprite sprite-logo2"></i><br>
        Kedves {{this.userName}} !<hr>
      </mat-card-title>
      <mat-card-subtitle  style="text-align: center;">Üdvözöljük a TopInfo webes jelenlét és szabadság rögzítő alkalmazásában.</mat-card-subtitle>
      
    </mat-card-header>    
  </mat-card>
<mat-card class="kezdolap-card">
    <mat-card-header>
      <mat-card-title>Rögzítések</mat-card-title>
      <!-- <mat-card-subtitle>Az alábbi menüpontokból választhat</mat-card-subtitle> -->
    </mat-card-header>
    <mat-card-actions>
      <button mat-stroked-button color="primary" class="fastmenubtn" routerLink="/kerelem"><i class="sprite sprite-szabadsag" alt="" style="transform: scale(0.7);vertical-align: middle;"></i>Szabadság</button>
      &nbsp;
      <button mat-stroked-button color="primary" class="fastmenubtn" routerLink="/kerelemho"><i class="sprite sprite-ho" alt="" style="transform: scale(0.7);vertical-align: middle;"></i>Homeoffice</button>
      &nbsp;
      <button mat-stroked-button color="primary" class="fastmenubtn" routerLink="/jelenlet"><i class="sprite sprite-munkanap" alt="" style="transform: scale(0.7);vertical-align: middle;"></i><br/>Jelenlét</button>
      
    </mat-card-actions>
  </mat-card>  

  <mat-card class="kezdolap-card" *ngIf="showmenu('engedely')">
    <mat-card-header>
      <mat-card-title>Engedélyezések</mat-card-title>
      <!-- <mat-card-subtitle></mat-card-subtitle> -->
    </mat-card-header>
    <mat-card-actions>
      <button mat-stroked-button [matBadge]="szdb()" [matBadgeHidden]="szdb()<1" matBadgeColor="warn" color="primary" class="fastmenubtn" routerLink="/enged" [queryParams]="{ tip: 'sz'}"><i class="sprite sprite-szabadsag" alt="" style="transform: scale(0.7);vertical-align: bottom;"></i>Szabadság</button>
      &nbsp;
      <button  *ngIf="showmenu('jogcim')" mat-stroked-button [matBadge]="jodb()" [matBadgeHidden]="jodb()<1" matBadgeColor="warn" color="primary" class="fastmenubtn" routerLink="/enged" [queryParams]="{ tip: 'jo'}"><i class="sprite sprite-egyeb" alt="" style="transform: scale(0.7);vertical-align: middle;"></i>Jogcímek</button>
      &nbsp;
      <button mat-stroked-button [matBadge]="hodb()" [matBadgeHidden]="hodb()<1" matBadgeColor="warn" color="primary" class="fastmenubtn" routerLink="/enged" [queryParams]="{ tip: 'ho'}"><i class="sprite sprite-ho" alt="" style="transform: scale(0.7);vertical-align: middle;"></i>Homeoffice</button>
      &nbsp;
      <button  *ngIf="showmenu('tavmunka')" mat-stroked-button [matBadge]="tmdb()" [matBadgeHidden]="tmdb()<1" matBadgeColor="warn" color="primary" class="fastmenubtn" routerLink="/enged" [queryParams]="{ tip: 'tm'}"><i class="sprite sprite-tavmunka" alt="" style="transform: scale(0.7);vertical-align: middle;"></i>Távmunka</button>
    </mat-card-actions>
  </mat-card>  




  <!-- <mat-card class="kezdolap-card">
    <mat-card-header>
      <mat-card-title>Használati útmutató</mat-card-title>
      <mat-card-subtitle>A honlap kezelésének megismeréséhez, kérjük olvassa el a használati leírást!</mat-card-subtitle>
    </mat-card-header>
    <mat-card-actions>
      <button mat-raised-button color="primary">Letöltés</button>    
    </mat-card-actions>
  </mat-card> -->
