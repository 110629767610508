import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule} from '@angular/forms';
import { MatInputModule} from '@angular/material/input';
import { MatFormFieldModule} from '@angular/material/form-field';
import { AppComponent } from '../app.component';
import { SzurokComponent } from '../szurok/szurok.component';
import { AppService } from '../app.service';
import { MatTableModule} from '@angular/material/table';
import { MatDividerModule} from '@angular/material/divider';
import { KeyText} from '../model';
import { getYear } from 'date-fns';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';


@Component({
  selector: 'app-szabiinfotable',
  standalone: true,
  imports: [CommonModule,MatSelectModule,FormsModule,MatInputModule,MatFormFieldModule,SzurokComponent,
    MatTableModule,MatDividerModule,MatSlideToggleModule,MatProgressSpinnerModule],
  templateUrl: './szabiinfotable.component.html',
  styleUrls: ['./szabiinfotable.component.css']
})
export class SzabiinfotableComponent {
selectedYear: number = 0;
selectedMonth: number = 0;
honap : Number = 0;
szerv : String = '';
szervegysek: KeyText[] = [];
bodydata= [];
headerdata = [];
tip = 'sz';
helytip : boolean = false;
munkaido : boolean = false;
isLoading = false;

years : any = [
  {y1: 2024,y2: "2024"},
  {y1: 2025,y2: "2025"},
];
months : any = [
  {y1: 0,y2: ""},
  {y1: 1,y2: "1"},
  {y1: 2,y2: "2"},
  {y1: 3,y2: "3"},
  {y1: 4,y2: "4"},
  {y1: 5,y2: "5"},
  {y1: 6,y2: "6"},
  {y1: 7,y2: "7"},
  {y1: 8,y2: "8"},
  {y1: 9,y2: "9"},
  {y1: 10,y2: "10"},
  {y1: 11,y2: "11"},
  {y1: 12,y2: "12"}
];

  constructor(private ap :AppComponent,private _service: AppService) {
    this.ap.title = 'Táblázatok';
    this.ap.menuhujuj = false;
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this._service.fetchBerinstall().subscribe(p => {
      this._service.szervegys2().subscribe(p => {
        this.szervegysek = p;
        this.selectedYear = getYear(Date.now());
          //************************************ */
          this.years[0].key =  this.selectedYear;
          this.years[0].KeyText =  this.selectedYear.toString;
          this.years[1].key =  this.selectedYear+1;
          this.years[1].Kextext =  (this.selectedYear+1).toString;
          //************************************ */
        this.szerv = p[0].key;
        this.ToltTabla(this.szerv, this.selectedYear, this.selectedMonth, this.tip,this.helytip,this.munkaido);
      });
    });
  }

  ToltTabla(szervegys:String,ev:number,ho:number,tip:string,helytip:boolean,munkaido:boolean) : void {
    if ((szervegys=='') || (ev==0)) return;
    this.isLoading = true;
    //******************************************************************* */
    this._service.getszabiinfotable(szervegys,ev, ho,tip,helytip,munkaido).subscribe(p => {
      this.headerdata = JSON.parse(String(p.header));
      this.bodydata = JSON.parse(String(p.body));
      this.isLoading = false;
    });
  }
  onSzervChanged(): void
  {
    this.ToltTabla(this.szerv,this.selectedYear,this.selectedMonth,this.tip,this.helytip,this.munkaido);
  }
  onYearChanged(): void
  {
    this.ToltTabla(this.szerv,this.selectedYear,this.selectedMonth,this.tip,this.helytip,this.munkaido);
  }
  onMonthChanged(): void
  {
    this.ToltTabla(this.szerv,this.selectedYear,this.selectedMonth,this.tip,this.helytip,this.munkaido);
  }
  changedTip(e: any): void {
    if (!e.checked) {
      this.tip = 'sz';
      this.ToltTabla(this.szerv,this.selectedYear,this.selectedMonth,this.tip,this.helytip,this.munkaido);
    }
    else {
      this.tip = 'ho';
      this.ToltTabla(this.szerv,this.selectedYear,this.selectedMonth,this.tip,this.helytip,this.munkaido);
    }
  }
  changedHelyettesito(e: any): void {
    if (!e.checked) {
      this.helytip = false;
      this.ToltTabla(this.szerv,this.selectedYear,this.selectedMonth,this.tip,this.helytip,this.munkaido);
    }
    else {
      this.helytip = true;
      this.ToltTabla(this.szerv,this.selectedYear,this.selectedMonth,this.tip,this.helytip,this.munkaido);
    }
  }
  changedMunkaido(e: any): void {
    if (!e.checked) {
      this.munkaido = false;
      this.ToltTabla(this.szerv,this.selectedYear,this.selectedMonth,this.tip,this.helytip,this.munkaido);
    }
    else {
      this.munkaido = true;
      this.ToltTabla(this.szerv,this.selectedYear,this.selectedMonth,this.tip,this.helytip,this.munkaido);
    }
  }
  helyettesito():boolean {
    return this._service._Berinst().helyettesito;
  }
  elsokar(s : any):string {
    if (s.length>2) return '';
    return s[0];
  }
  also3kar(s : any):string {
    return s.toString().substring(0,3);
  }    
  szoveg(s : any):string {
    if (s.length==1) 
      return s
    else if (s.length==3) 
      return s.toString().substring(0,2);
    else
      return s.toString().substring(0,2)+' '+s.toString().substring(4);
  }    
  hujuj(s : [], j : number):string {
    return s[j];
  }    

}
