<div class="example-list">
<!-- Szervegys szótár -------------------------------------------------------------------------------------------->
<!--<mat-form-field class="my-select-panel-class">
  <mat-label>{{szervlabel}}</mat-label>
    <mat-select [(value)]="szerv" (selectionChange)="onSzervChanged()">
        <mat-option class="my-select-class" *ngFor="let sz of szervegysek" [value]="sz.key">
          {{sz.text}}
        </mat-option>
    </mat-select>
  </mat-form-field>-->
<!-- Dolgozó szótár ----------------------------------------------------------------------------------------------->
<mat-form-field class="my-selectdolgozo-panel-class">
    <mat-label>Dolgozó</mat-label>
    <input type="text" matInput [formControl]="autocompleteDolgozo" id="hujuj"
        [matAutocomplete]="auto">
    <mat-icon  (click)="ontorles()" matSuffix>arrow_drop_down</mat-icon>
    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" (optionSelected)="onDolgozoChanged($event)" >
        <mat-option *ngFor="let option of filtereddolgozo | async" [value]="option" >
            {{option.name}}
        </mat-option>
    </mat-autocomplete>
</mat-form-field>
<!-- <button class="image2" (click)="ontorles()" title="törlés dolgozó" style="width:24px;height:24px">
  <div class="image2"><i class="sprite sprite-elutasit."  style="transform: scale(0.7);"></i></div>
</button>   -->
<!-- Munkaszerződés szótár ------------------------------------------------------------------------------------------>
<!-- <mat-form-field class="my-select-panel-class">
  <mat-label>Munkaszerződés</mat-label>
    <mat-select [(value)]="msz" (selectionChange)="onMszChanged()">
        <mat-option class="my-select-class" *ngFor="let sz of munkaszerzodesek" [value]="sz.key">
          {{sz.text}}
        </mat-option>
    </mat-select>
  </mat-form-field> -->
</div>
