import {SecUtils} from "./sec-utils";

/**
 * User information
 */
export class User
{
    /**
     * Unique user identifier
     */
    public id: string = "";

    /**
     * OpenID user token
     */

    public idToken: string = "";

    /**
     * OAUTH2 access token
     */

    public accessToken: string = "";

    /**
     * 
     * Is the user logged in
     */
    public isloggedin: boolean = false;
    /**
     * User name
     */
    public name: string = "";

    /**
     * Name of the user login profile
     */
    public profile: string = "";

    /**
     * Name of the groups the user belongs to
     *
     * Values must be separated by commas without spaces
     */
    public groups: string = "";

    /**
     * Globally used year
     */
    public year = new Date().getFullYear();

    /**
     * Globally used date
     */
    public date = new Date();

    /**
     * Result of the login
     */
    public result = null;

    /**
     * Full name of the main server assembly
     */
    public serverName: string = "";

    /**
     * Main assembly creation date
     */
    public serverDate: Date = new Date();

    /**
     * Is this user member of the Topsoft corporation?
     */
    public isTopsoft: boolean = false;

    /**
     * User password
     */
    public password: string = "";

    /**
     * User password again form confirmation purposes
     */
    public confirmPassword: string = "";

    /// <summary>
    /// 2 factor authentication needed
    /// </summary>
    public need2FA: boolean = false;

    /// <summary>
    /// Must change password after login
    /// </summary>
    public mustChangePwd: boolean = false;

    /**
     * Create a user
     *
     * @param usr   the source user
     */
    constructor(usr?: any)
    {
        if(!usr)
            return;
        for(const id in usr)
            if(usr.hasOwnProperty(id))
                this[id] = usr[id];
    }


    /**
     * Is the current user in any of the groups?
     *
     * @param groups {String}   comma separated list of groups
     */
    public inGroup(groups: string): boolean
    {
        return SecUtils.inGroup(this.groups, groups);
    }

    /**
     * Add a group to the groups of the current user
     *
     * @param group {String}   group name
     */
    public addGroup(group: string): void
    {
        SecUtils.addGroup(group, this.groups);
    }

    /*
     * Remove group(s) from the current user groups
     *
     * @param group {String}    comma separated list of groups to remove
     */
    public removeGroup(group: string): void
    {
        SecUtils.removeGroup(group, this.groups);
    }

    /**
     * Other values
     */
    [key: string]: any;
}
