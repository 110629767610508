import { Component, OnInit, Input,NgModule,ChangeDetectionStrategy, ViewChild, TemplateRef } from '@angular/core';
import { CommonModule, Time } from '@angular/common';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import {
  CalendarEvent, CalendarMonthViewBeforeRenderEvent, CalendarWeekViewBeforeRenderEvent, CalendarDayViewBeforeRenderEvent,
  CalendarView, CalendarDateFormatter,CalendarEventAction, CalendarEventTimesChangedEvent
} from 'angular-calendar';
import { SzurokComponent } from '../szurok/szurok.component';
import { startOfDay, endOfDay, subDays, addDays, endOfMonth, isSameDay, isSameMonth, addHours, hoursToMilliseconds, getHours } from 'date-fns';
import { Subject } from 'rxjs';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatIconModule } from '@angular/material/icon';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { registerLocaleData } from '@angular/common';
import localeHu from '@angular/common/locales/hu';
import { EventColor } from 'calendar-utils';
import { AppService } from '../app.service';
import { AppComponent } from '../app.component';
import { Berinstall, THonap, TfelvitelGyorsParams } from '../model';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { FormControl } from '@angular/forms';
import { AuthService, HttpSenderService, TopCoreModule, TranslateService, UiService } from 'top-core';
import { textChangeRangeIsUnchanged } from 'typescript';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatDialogModule} from '@angular/material/dialog';
import {FelvitelComponent} from "../felvitel/felvitel.component";
import {SzabiinfomodComponent} from "../szabiinfomod/szabiinfomod.component";
import { MatInputModule } from '@angular/material/input';
import {FormsModule} from '@angular/forms';    //ng modellhez
import {MatTabsModule} from '@angular/material/tabs';
import {MatExpansionModule} from '@angular/material/expansion';
import { KeyText} from '../model';
import { MatListModule } from '@angular/material/list';
import { MatSelectModule } from '@angular/material/select';
import {MatSnackBar} from "@angular/material/snack-bar";

const colors: Record<string, EventColor> = {
  red: {
    primary: '#ad2121',
    secondary: '#FAE3E3',
  },
  blue: {
    primary: '#1e90ff',
    secondary: '#D1E8FF',
  },
  yellow: {
    primary: '#e3bc08',
    secondary: '#FDF1BA',
  },
  green: {
    primary: '#4ead59',
    secondary: '#baf5c1',
  },
};

registerLocaleData(localeHu);

@Component({
  selector: 'app-napi',
  standalone: true,
  imports: [
    CommonModule,
    CalendarModule,
    SzurokComponent, MatButtonModule, MatButtonToggleModule, MatIconModule, MatDatepickerModule, MatFormFieldModule, MatSlideToggleModule,MatInputModule,FormsModule
    ,MatTabsModule,MatExpansionModule,MatListModule,MatSelectModule
  ],
  templateUrl: './napi.component.html',
  styleUrls: ['./napi.component.css'],
})

export class NapiComponent implements OnInit {
  view: CalendarView = CalendarView.Month;
  CalendarView = CalendarView;
  viewDate: Date = new Date();
  refresh: Subject<any> = new Subject();
  events: CalendarEvent[] = [];
  locale: string = "hu";
  invchsubs!: any;
  isDisabled: boolean = true;

  Honap: THonap = new THonap();
  VanHonap: boolean = false;

  _dolgozo: String = '';
  _mszerz: String = '';
  _ev: Number = 0;
  _ho: Number = 0;
  tol_: String = '';
  ig_: String = '';
  lezaras  : boolean = false;
  szamfejtve : boolean = false;
  lezaras_ : String ='nyitott';

  kijtol : number = -1;
  kijig  : number = -1;

  selected_: boolean = false;
  selectTomb: boolean[] = [];
  tolig_: boolean = false;

  scaleXY : String = 'scale(' + 1.2 + ')';
  margin2px : String = "";

  pic: String[] = ['sprite sprite-insert_.', 'sprite sprite-munkanap_', 'sprite sprite-szabadsag_', 'sprite sprite-ho_', 'sprite sprite-delete_', 'sprite sprite-tavmunka_','sprite sprite-tavmunkad_'];

  @Input() item = '';

  constructor(private ap :AppComponent, private _service: AppService, private _ui: UiService, public dialog: MatDialog, private _snack: MatSnackBar,) {this.ap.menuhujuj = false; }

  ngOnInit(): void {
    
    this.invchsubs = this._service.Kulcsok.subscribe(message => {
      if (message.item=="szabiinfo") return;
      this._dolgozo = message.dolgozo;
      this._mszerz = message.mszerz;
      this._ev = message.ev;
      this._ho = message.ho;
      //**********************************************************
      this.HonapTolt(this._dolgozo, this._mszerz, Number(this._ev), Number(this._ho));
      //**********************************************************
    });
    this.selecTombInit();
    if (screen.width<1000) {
      this.scaleXY = 'scale(' + 1.0 + ')';
      this.margin2px = "margin : 0px"
    }
    else
      this.margin2px = "margin : 5px"
  }
  ngAfterViewInit() : void
  {
    // if ((this.item=='engedszabi') || (this.item == "engedjogcim") || (this.item=='engedhome') || (this.item=='engedtavmunka') || (this.item=='kerelem') || (this.item=='kerelemho') || (this.item=='kerelemtm'))
      this.selected_ = true;
      // this._snack.open(String(screen.width), "Ok", { duration: 20000 })
  }

  openDialogFelvitel(ts_:number,nap_:number):void {        
    if ((this._dolgozo=='') || (this._mszerz=='') || (this._ev==0) || (this._ho==0))
      return;
    //*********************************************************** */
    const dialogRef = this.dialog.open(FelvitelComponent, {
        data: {
          dolgozo:this._dolgozo,
          mszerz:this._mszerz,
          ev:this._ev,
          ho:this._ho,
          nap:nap_,
          ts:ts_,
          item:this.item,
          selectTomb:this.selectTomb,
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        this.HonapTolt(this._dolgozo, this._mszerz, Number(this._ev), Number(this._ho));
        this.selecTombInit();
      });
    }


  changedTol(e: any): void {
    this.tol_ = e.target.value;
  }
  changedIg(e: any): void {
    this.ig_ = e.target.value;
  }

  AllitPic(b: boolean) {
    if (b)
      this.pic = ['sprite sprite-insert_', 'sprite sprite-munkanap_', 'sprite sprite-szabadsag_', 'sprite sprite-ho_', 'sprite sprite-delete_', 'sprite sprite-hod_', 'sprite sprite-tavmunka_','sprite sprite-tavmunkad_'];
    else
      this.pic = ['sprite sprite-insert', 'sprite sprite-munkanap', 'sprite sprite-szabadsag', 'sprite sprite-ho', 'sprite sprite-deletej', 'sprite sprite-hod', 'sprite sprite-tavmunka','sprite sprite-tavmunkad'];
  }

  selecTombInit() {
    for (let i = 0; i < 31; i++) this.selectTomb[i] = false;
    this.isDisabled = true;
    this.AllitPic(this.isDisabled);
    this.activeDayIsOpen = false;
    this.kijtol = -1;
    this.kijig = -1;
  }

  IsSelectTomb(): boolean {
    for (let i = 0; i < 31; i++) {
      if (this.selectTomb[i] == true)
        return true;
    }
    return false;
  }

  ngOnDestroy() {
    if (this.invchsubs)
      this.invchsubs.unsubscribe();
  }

  setView(view: CalendarView) {
    this.view = view;
  }

  onInsert(): void {

  }

  onhoGyors(tip: String): void {
    if ((this._dolgozo=='') || (this._mszerz=='') || (this._ev==0) || (this._ho==0))
      return;
    //*********************************************************** */
    var par: TfelvitelGyorsParams = new (TfelvitelGyorsParams);
    par.dolgozo = this._dolgozo;
    par.mszerz = this._mszerz;
    par.ev = Number(this._ev);
    par.ho = Number(this._ho);
    par.tip = String(tip);
    this._service.hoGyors(par, this.selectTomb).subscribe(p => {
      this.HonapTolt(this._dolgozo, this._mszerz, Number(this._ev), Number(this._ho));
      this.selecTombInit();
    });
  }
  ontmGyors(tip: String): void {
    if ((this._dolgozo=='') || (this._mszerz=='') || (this._ev==0) || (this._ho==0))
      return;
    //*********************************************************** */
    var par: TfelvitelGyorsParams = new (TfelvitelGyorsParams);
    par.dolgozo = this._dolgozo;
    par.mszerz = this._mszerz;
    par.ev = Number(this._ev);
    par.ho = Number(this._ho);
    par.tip = String(tip);
    par.ts = 1; // távmunka
    this._service.hoGyors(par, this.selectTomb).subscribe(p => {
      this.HonapTolt(this._dolgozo, this._mszerz, Number(this._ev), Number(this._ho));
      this.selecTombInit();
    });
  }

  onengedely(tip:string): void {
    if ((this._dolgozo=='') || (this._mszerz=='') || (this._ev==0) || (this._ho==0))
      return;
    //*********************************************************** */
    var par: TfelvitelGyorsParams = new (TfelvitelGyorsParams);
    par.dolgozo = this._dolgozo;
    par.mszerz = this._mszerz;
    par.ev = Number(this._ev);
    par.ho = Number(this._ho);
    par.tip = tip;
    if (this.item=='engedszabi')
      this._service.szabiEnged(par, this.selectTomb).subscribe(p => {
        this.HonapTolt(this._dolgozo, this._mszerz, Number(this._ev), Number(this._ho));
        this.selecTombInit();
        window.location.reload();
      });
    else if (this.item=='engedjogcim')
        this._service.jogcimEnged(par, this.selectTomb).subscribe(p => {
          this.HonapTolt(this._dolgozo, this._mszerz, Number(this._ev), Number(this._ho));
          this.selecTombInit();
          window.location.reload();
        });
    else if (this.item=='engedhome')
    this._service.hoEnged(par, this.selectTomb,'ho').subscribe(p => {
      this.HonapTolt(this._dolgozo, this._mszerz, Number(this._ev), Number(this._ho));
      this.selecTombInit();
      window.location.reload();
    });
    else if (this.item=='engedtavmunka')
    this._service.hoEnged(par, this.selectTomb,'tm').subscribe(p => {
      this.HonapTolt(this._dolgozo, this._mszerz, Number(this._ev), Number(this._ho));
      this.selecTombInit();
      window.location.reload();
    });
}

  onDelete(tip: String): void {
    if ((this._dolgozo=='') || (this._mszerz=='') || (this._ev==0) || (this._ho==0))
      return;
    //*********************************************************** */
    var par: TfelvitelGyorsParams = new (TfelvitelGyorsParams);
    par.dolgozo = this._dolgozo;
    par.mszerz = this._mszerz;
    par.ev = Number(this._ev);
    par.ho = Number(this._ho);
    par.tip = String(this.item);
    this._ui.confirm('Törlés', 'Biztosan törölni szeretne?').subscribe((res: boolean) => {
      if (res) {
        this._service.deleteGyors(par, this.selectTomb).subscribe(p => {
          this.HonapTolt(this._dolgozo, this._mszerz, Number(this._ev), Number(this._ho));
          this.selecTombInit();
          this.activeDayIsOpen = false;
          this._snack.open(p, "Ok", { duration: 20000 })
        });
      }
    });
  }

  onDeleteTet(nap:Number,ts:Number) : void {
    var par: TfelvitelGyorsParams = new (TfelvitelGyorsParams);
    par.dolgozo = this._dolgozo;
    par.mszerz = this._mszerz;
    par.ev = Number(this._ev);
    par.ho = Number(this._ho);
    par.nap = Number(nap);
    par.ts = Number(ts);
    this._service.jelenletTrandel(par).subscribe(p => {
      this.HonapTolt(this._dolgozo, this._mszerz, Number(this._ev), Number(this._ho));
      this.activeDayIsOpen = false;
    });
  }

  onInsertMunkaidoSzabi(tip: String): void {
    if ((this._dolgozo=='') || (this._mszerz=='') || (this._ev==0) || (this._ho==0))
      return;
    //*********************************************************** */
    var par: TfelvitelGyorsParams = new (TfelvitelGyorsParams);
    par.dolgozo = this._dolgozo;
    par.mszerz = this._mszerz;
    par.ev = Number(this._ev);
    par.ho = Number(this._ho);
    par.tip = String(tip);
    par.tol = String(this.tol_);
    par.ig  = String(this.ig_);
    this._service.felvitelGyors(par, this.selectTomb).subscribe(p => {
      this.HonapTolt(this._dolgozo, this._mszerz, Number(this._ev), Number(this._ho));
      this.selecTombInit();
    });
  }

  changedSelect(e: any): void {
    if (!e.checked) {
      this.selecTombInit();
      //this.selected = false;
      this.isDisabled = true;
      this.AllitPic(this.isDisabled);
    }
    else {
      //this.selected = true;
      this.activeDayIsOpen = false;
    }
  }
  changedTolIg(e: any): void {
    if (!e.checked) {
      this.kijtol = -1;
      this.kijig  = -1;
    }
  }

  onValDate(): void {
    this.selecTombInit();
    if ((this.viewDate.getMonth() + 1 != this._ho) || (this.viewDate.getFullYear() != this._ev)) {
      this._ev = this.viewDate.getFullYear();
      this._ho = this.viewDate.getMonth() + 1;
      this.activeDayIsOpen = false;
      this.HonapTolt(this._dolgozo, this._mszerz, Number(this._ev), Number(this._ho));
    }
  }

  activeDayIsOpen: boolean = false;
  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
     if ((!this.selected_) || (!this.kijelolvisible() )) {     // Nincs kijelőlés
      if (isSameMonth(date, this.viewDate)) {
        if (
          (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
          events.length === 0
        ) {
          this.activeDayIsOpen = false;
        } else {
          this.activeDayIsOpen = true;
        }
        this.viewDate = date;
      }
     }
     else {
     this.activeDayIsOpen = false;
     if ( (date.getMonth() == this.viewDate.getMonth()) 
              && (
                      (!this._service._Berinst().csakmunkanapjel)  || 
                      ( (this._service._Berinst().csakmunkanapjel) && (this.Honap.maszk[date.getDate() - 1]=='M') )
                 )     
        )
     {
        var dd = new Date();
        dd.setDate(dd.getDate()-1);
        if ( (this.selectTomb[date.getDate() - 1] == false) && ( this.kijelolheto(date)) ) 
        {
          this.selectTomb[date.getDate() - 1] = true;
          //************************************* */
          if (this.tolig_)
            this.Allittolig(date);
          //************************************* */
          if (events.length!=0)
            this.activeDayIsOpen = true;
          this.viewDate = date;
        }
        else {
          this.selectTomb[date.getDate() - 1] = false;
          //************************************* */
          if (this.tolig_)
            this.Allittolig(date);
          //************************************* */
          this.activeDayIsOpen = false;this.viewDate = date;
        }
      }
      if (this.IsSelectTomb()) {
        this.isDisabled = false;
        this.AllitPic(this.isDisabled);
      }
      else {
        this.isDisabled = true;
        this.AllitPic(this.isDisabled);
      }
     }
  }

  public Allittolig(date:Date) {
    for (var i: number = 0; i <31; i++) this.selectTomb[i] = false;
    if ((!this.kijelolheto(date)) || (!this.MunkanapDont(date)))
      return;
    //******************************************* */
    if (this.kijtol==-1) {
      this.selectTomb[date.getDate() - 1] = true;
      this.kijtol=date.getDate() - 1;
    }
    else if (this.kijig==-1) {
      if (this.kijtol<(date.getDate() - 1)) {
        this.kijig=date.getDate() - 1;
        for (var i: number = this.kijtol; i <= this.kijig; i++) 
          if (this.Honap.maszk[i]=='M')
            this.selectTomb[i] = true;
      }
      else {
        this.selectTomb[date.getDate() - 1] = true;
        this.kijtol=date.getDate() - 1;
      }
    }
    else {
      this.selectTomb[date.getDate() - 1] = true;
      this.kijtol=date.getDate() - 1;
      this.kijig = -1;
    }
  }

  public kijelolheto(date:Date):boolean {
    var dd = new Date();
    dd.setDate(dd.getDate()-1);
    if (
      (
         ( (this.item!='kerelemho') && (this.item!='kerelemtm')) || (
                                       (( (this.item=='kerelemho') || (this.item=='kerelemtm')) &&(date>=dd))||
                                        (this._service._Berinst().homevissza)
                                     )
      )
    ) 
      return true;
    else
      return false;
  }

  public HonapTolt(dolgozo: String, mszerz: String, ev: number, ho: number) {
    this.activeDayIsOpen = false;
    this.Honap = new THonap();
    if ((dolgozo=='') || (mszerz=='') || (ev==0) || (ho==0))
      return;
    //*********************************************************** */
    if ((this._service._Berinst().lezaras ) && (this.item=='jelenlet')) {
      this._service.getzart(dolgozo, mszerz, ev, ho).subscribe(p => {
        this.lezaras = p.zart;
        this.szamfejtve = p.szamfejtve;
        if (this.lezaras) 
            this.lezaras_ = 'zárt';
        else 
            this.lezaras_ = 'nyitott';
      });
    }
    //*********************************************************** */
    this._service.getHonap(dolgozo, mszerz, ev, ho).subscribe(p => {
      this.VanHonap = false;
      this.Honap = p;
      //*********************************************************
      this.VanHonap = true;
      this.viewDate = new Date(ev, ho - 1);
      var k: number = 0;
      this.events =[];
      for (var i: number = 0; i < this.Honap.napok.length; i++) {
        if ((this.item=='engedhome') && (this.Honap.homofisz2[i]=='') && ((this.Honap.homofisz[i][0]=='K')||(this.Honap.homofisz[i][0]=='V'))) {
          this.selectTomb[i] = true;
        }
        if ((this.item=='engedtavmunka') && (this.Honap.homofisz2[i]=='T') && ((this.Honap.homofisz[i][0]=='K')||(this.Honap.homofisz[i][0]=='V'))) {
          this.selectTomb[i] = true;
        }
        if (this.Honap.napok[i].tetelek)
          for (var j: number = 0; j < this.Honap.napok[i].tetelek.length; j++) {
              if ((this.item=='engedszabi') && ((this.Honap.napok[i].tetelek[j].szabi[0]=='K')||(this.Honap.napok[i].tetelek[j].szabi[0]=='V'))) {
                this.selectTomb[i] = true;
              }
              if ((this.item=='engedjogcim') && ((this.Honap.napok[i].tetelek[j].szabi2[0]=='K')||(this.Honap.napok[i].tetelek[j].szabi2[0]=='V'))) {
                this.selectTomb[i] = true;
              }
              //********************************************************************************* */
                if ((this.Honap.napok[i].tetelek[j].szamfejtve != 'I') && (this.item=="jelenlet") && (this.kijelolvisible())) {
              this.events[k] =
              {
                id: this.Honap.napok[i].tetelek[j].ts,
                start: new Date(this.Honap.napok[i].tetelek[j].tol),
                end: new Date(this.Honap.napok[i].tetelek[j].ig),
                title: String(this.Honap.napok[i].tetelek[j].cimke),
                color : colors.green,
                actions: [
                  {
                          //************************************************
                          // módosítás
                          //************************************************
                      label: '<i class="material-icons mat-icon" color="warn">edit</i>',
                          onClick: ({ event }: { event: CalendarEvent }): void => {
                      this.activeDayIsOpen = false;
                      this.openDialogFelvitel(Number(event.id),event.start.getDate());
                    },
                  },
                  {
                    label: '<i class="material-icons mat-icon">delete</i>',
                    onClick: ({ event }: { event: CalendarEvent }): void => {
                      this._ui.confirm('Törlés', 'Biztosan törölni szeretne?').subscribe((res: boolean) => {
                        if (res) {
                          //************************************************
                          // Törlés
                          //************************************************
                          this.activeDayIsOpen = false;
                          this.onDeleteTet(event.start.getDate(),Number(event.id));
                        }
                      });
                    }
                  },
                ],
              };
            }
            else {
              this.events[k] =
              {
                id: this.Honap.napok[i].tetelek[j].ts,
                start: new Date(this.Honap.napok[i].tetelek[j].tol),
                end: new Date(this.Honap.napok[i].tetelek[j].ig),
                title: String(this.Honap.napok[i].tetelek[j].cimke),
                color : colors.red,
              };
            }
            k++;
          }
      }
    });
  }

  public IconDont(dat: Date, tip: number): boolean {
    if (!(this.VanHonap) && !dat)
      return false;
    const day = dat.getDate();
    if (dat.getMonth() == this.viewDate.getMonth())
      if (this.Honap.napok.length > 0) {
        if (this.Honap.napok[day - 1].icon[tip] != '')
          return true
        else
          return false;
      }
      else
        return false;
    else
      return false;
  }

  public SzabiIkon(dat: Date, tip: number): string {
    if (!(this.VanHonap) && !dat)
      return '';
    const day = dat.getDate();
    if (dat.getMonth() == this.viewDate.getMonth())
      if (this.Honap.napok.length > 0) {
        if (this.Honap.napok[day - 1].icon[tip][0] == 'Y')
          return 'sprite sprite-szabadsag';
        else if (this.Honap.napok[day - 1].icon[tip][0] == 'K')
          return 'sprite sprite-szabadsag_ker';
        else if (this.Honap.napok[day - 1].icon[tip][0] == 'V')
          return 'sprite sprite-szabadsag_vis';
        else
          return 'sprite sprite-szabadsag';
      }
      else
        return '';
    else
      return '';
  }

  public EgyebIkon(dat: Date, tip: number): string {
    if (!(this.VanHonap) && !dat)
      return '';
    const day = dat.getDate();
    if (dat.getMonth() == this.viewDate.getMonth())
      if (this.Honap.napok.length > 0) {
        if (this.Honap.napok[day - 1].icon[tip][0] == 'Y')
          return 'sprite sprite-egyeb';
        else if (this.Honap.napok[day - 1].icon[tip][0] == 'K')
          return 'sprite sprite-egyeb_ker';
        else if (this.Honap.napok[day - 1].icon[tip][0] == 'V')
          return 'sprite sprite-egyeb_vis';
        else
          return 'sprite sprite-egyeb';
      }
      else
        return '';
    else
      return '';
  }
  

  public IconDontSum(dat: Date): boolean {
    if (!(this.VanHonap) && !dat)
      return false;
    const day = dat.getDate();
    if (dat.getMonth() == this.viewDate.getMonth())
      if (this.Honap.napok.length > 0) {
        if ((this.Honap.napok[day - 1].icon[0] == '') && (this.Honap.napok[day - 1].icon[1] == '') &&
          (this.Honap.napok[day - 1].icon[2] == '') && (this.Honap.napok[day - 1].icon[3] == '') &&
          (this.Honap.napok[day - 1].icon[4] == ''))
          return true
        else
          return false;
      }
      else
        return false;
    else
      return false;
  }

  public IconDontSzov(dat: Date, tip: number): string {
    if (!(this.VanHonap) && !dat)
      return '';
    const day = dat.getDate();
    if (dat.getMonth() == this.viewDate.getMonth())
      if (this.Honap.napok.length > 0) {
        if (this.Honap.napok[day - 1].icon[tip] != '')
          return this.Honap.napok[day - 1].icon[tip];
        else
          return '';
      }
      else
        return '';
    else
      return '';
  }

  public MunkanapDont(dat: Date): String {
    if (!(this.VanHonap) && !dat)
      return '';
    const day = dat.getDate();
    if (dat.getMonth() == this.viewDate.getMonth())
      return this.Honap.maszk[day - 1]
    else
      return '';
  }
  public MunkanapDont2(dat: Date): boolean {
    if (!(this.VanHonap) && !dat)
      return false;
    const day = dat.getDate();
    if (dat.getMonth() == this.viewDate.getMonth()) {
      if (this.Honap.maszk.length > 0) {
        if (this.Honap.maszk[day - 1] != '')
          return true;
        else
          return false;
      }
      else
        return false;
    }
    else
      return false;
  }

  public MunkanapSelectMaszk(dat: Date): string {
    if ((this.selectTomb[dat.getDate() - 1]) && (dat.getMonth() == this.viewDate.getMonth()))
      return 'day-css2';
    //**************************************** */
    if (!(this.VanHonap) && !dat)
      return '';
    const day = dat.getDate();
    if (dat.getMonth() == this.viewDate.getMonth()) {
      if (this.Honap.maszk.length > 0) {
        if (this.Honap.maszk[day - 1] != '')
          return '';
        else
          return 'day-css';
      }
      else
        return '';
    }
    else
      return '';
  }

  public HoDont(dat: Date): boolean {
    if (!(this.VanHonap) && !dat)
      return false;
    const day = dat.getDate();
    if (dat.getMonth() == this.viewDate.getMonth()) {
      if (this.Honap.homofisz.length > 0) {
        if (this.Honap.homofisz[day - 1] != '')
          return true;
        else
          return false;
      }
      else
        return false;
    }
    else
      return false;
  }

  public HoTmIcon(dat: Date) : String{
    if (!(this.VanHonap) && !dat)
      return '';
    const day = dat.getDate();
    if (dat.getMonth() == this.viewDate.getMonth()) {
      if (this.Honap.homofisz.length > 0) {
        if (this.Honap.homofisz[day - 1] != '') {
          if (this.Honap.homofisz2[day - 1] != 'T') {
            if ((this.Honap.homofisz[day - 1][0] == 'I') || (this.Honap.homofisz[day - 1][0] == 'Y'))
              return 'sprite sprite-ho';
            else if (this.Honap.homofisz[day - 1][0] == 'K')
              return 'sprite sprite-ho_ker';
            else if (this.Honap.homofisz[day - 1][0] == 'V')
              return 'sprite sprite-ho_vis';
            else
              return '';
          }
          else {
            if ((this.Honap.homofisz[day - 1][0] == 'I') || (this.Honap.homofisz[day - 1][0] == 'Y'))
              return 'sprite sprite-tavmunka';
            else if (this.Honap.homofisz[day - 1][0] == 'K')
              return 'sprite sprite-tavmunka_ker';
            else if (this.Honap.homofisz[day - 1][0] == 'V')
              return 'sprite sprite-tavmunka_vis';
            else
              return '';
          }
        }
        else
          return '';
      }
      else
        return '';
    }
    else
      return '';
  }  

  public TervDont(dat: Date): boolean {
    if (!(this.VanHonap) && !dat)
      return false;
    const day = dat.getDate();
    if (dat.getMonth() == this.viewDate.getMonth()) {
      if (this.Honap.maszk2.length > 0) {
        if ((this.Honap.maszk2[day - 1] != '') &&(this.item=='kerelem'))
          return true;
        else
          return false;
      }
      else
        return false;
    }
    else
      return false;
  }



  public HoDonSzovt(dat: Date): string {
    if (!(this.VanHonap) && !dat)
      return '';
    const day = dat.getDate();
    if (dat.getMonth() == this.viewDate.getMonth()) {
      if (this.Honap.homofisz.length > 0) {
        if (this.Honap.homofisz[day - 1] != 'I')
          return this.Honap.homofisz[day - 1];
        else
          return '';
      }
      else
        return '';
    }
    else
      return '';
  }

  public szabiinfo(): void {
    if ((this._dolgozo=='') || (this._mszerz=='') || (this._ev==0) || (this._ho==0))
      return;
    //*********************************************************** */
    const dialogRef1 = this.dialog.open(SzabiinfomodComponent, {
        data: {
          dolgozo:this._dolgozo,
          mszerz:this._mszerz,
        }
      });
      dialogRef1.afterClosed().subscribe(result => {
        if (result) {
        } 
      });
  }    

  public pdf(): void {
    if ((this._dolgozo=='') || (this._mszerz=='') || (this._ev==0) || (this._ho==0))
      return;
      
    this._service.downloadJelenletLista(this._dolgozo, this._mszerz, this._ev, this._ho).subscribe(blob => {
      const newBlob = new Blob([blob.data], {type: blob.mime});
      var fileURL = URL.createObjectURL(newBlob);
      window.open(fileURL, '_blank');
      //this._saver.save(blob.data, blob.fileName);
  });
  }    

  public felvitelvisible() : boolean {
    if ((this.item == "jelenlet") || (this.item == "kerelem"))
      return true;
    return false;
  }
  public munkaidovisible() : boolean {
    if (this.item=='jelenlet')
      return true;
    return false;
  }
  public deletevisible() : boolean {
    if ((this.item=='kerelem')||(this.item == "jelenlet"))
      return true;
    else
      return false;
  }
  public szabivisible() : boolean {
    if (!this._service._Berinst().szabigyors)
      return false;
    if (this.item == "kerelem")
      return true;
    return false;
  }
  public hovisible() : boolean {
    if (this.item == "kerelemho")
      return true;
    return false;
  }
  public hoGyorsvisible() : boolean {
    if (this.item == "kerelemho")
      return true;
    return false;
  }
  public tmvisible() : boolean {
    if (this.item == "kerelemtm")
      return true;
    return false;
  }
  public tmGyorsvisible() : boolean {
    if (this.item == "kerelemtm")
      return true;
    return false;
  }
  public engedelyvisible() : boolean {
    if ((this.item == "engedhome") || (this.item == "engedszabi") || (this.item == "engedjogcim") || (this.item == "engedtavmunka"))
      return true
    return false;
  }
  public elutasitvisible() : boolean {
    if ((this.item == "engedhome") || (this.item == "engedszabi") || (this.item == "engedjogcim") || (this.item == "engedtavmunka"))
      return true
    return false;
  }
  public infovisible() : boolean {
    if (this.item == "kerelem")
      return true;
    return false;
  }
  public pdfvisible() : boolean {
    if (this.item == "jelenlet")
      return true;
    return false;
  }

  public kijelolvisible() : boolean {
    if ((this._service._Berinst().dolgozo==this._dolgozo) || (this.item=='engedszabi') || (this.item == "engedjogcim") || (this.item=='engedhome') || (this.item=='engedtavmunka'))
      return true;
    if ((this._service._Berinst().dolgozo!=this._dolgozo) && (this._service._Berinst().vezetomastmodosithat))
      return true;
    // this.selected_ = false;
    return false;
  }

  public LezarasKell() : boolean {
    if ((this._service._Berinst().lezaras ) && (this.item=='jelenlet')) {
      return true;
    }
    else {
      return false;
    }
  }

  changedLezaras(e: any): void {
    this.NyitZar(this._dolgozo, this._mszerz, Number(this._ev), Number(this._ho),'zar');
}

  public NyitZar(dolgozo:String, mszerz:String, ev:Number,ho:Number,tip:String): void{
    if ((dolgozo=='') || (mszerz=='') || (ev==0) || (ho==0)) return;
    //******************************************************************** */
    this._service.lezart(dolgozo, mszerz, ev, ho).subscribe(pp => {
      this._service.getzart(this._dolgozo, this._mszerz, Number(this._ev), Number(this._ho)).subscribe(p => {
        this.lezaras = p.zart;
        this.szamfejtve = p.szamfejtve;
        if (this.lezaras) 
            this.lezaras_ = 'zárt';
        else 
            this.lezaras_ = 'nyitott';
      });
      if (!pp )
        this._snack.open('Számfejtésre már lezárva!', "Ok", { duration: 20000 })
    })
  }

}


