import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule} from '@angular/forms';
import { MatInputModule} from '@angular/material/input';
import { MatFormFieldModule} from '@angular/material/form-field';
import { AppComponent } from '../app.component';
import { SzurokComponent } from '../szurok/szurok.component';
import { AppService } from '../app.service';
import { MatTableModule} from '@angular/material/table';
import { MatDividerModule} from '@angular/material/divider';
import { KeyText} from '../model';
import { getYear } from 'date-fns';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';

@Component({
  selector: 'app-terv',
  standalone: true,
  imports: [CommonModule,MatSelectModule,FormsModule,MatInputModule,MatFormFieldModule,SzurokComponent,
    MatTableModule,MatDividerModule,MatSlideToggleModule,MatProgressSpinnerModule],
  templateUrl: './terv.component.html',
  styleUrls: ['./terv.component.css']
})
export class TervComponent {
  selectedYear: number = 0;
  selectedMonth: number = 0;
  keret : number = 0;
  honap : Number = 0;
  nezegetes : boolean = false;
  szerv : String = '';
  szervegysek: KeyText[] = [];
  bodydata= [];
  headerdata = [];
  isLoading = false;
  
  years : any = [
    {y1: 2024,y2: "2024"},
    {y1: 2025,y2: "2025"},
  ];
  months : any = [
    {y1: 0,y2: ""},
    {y1: 1,y2: "1"},
    {y1: 2,y2: "2"},
    {y1: 3,y2: "3"},
    {y1: 4,y2: "4"},
    {y1: 5,y2: "5"},
    {y1: 6,y2: "6"},
    {y1: 7,y2: "7"},
    {y1: 8,y2: "8"},
    {y1: 9,y2: "9"},
    {y1: 10,y2: "10"},
    {y1: 11,y2: "11"},
    {y1: 12,y2: "12"}
  ];
  
    constructor(private ap :AppComponent,private _service: AppService) {
      this.ap.title = 'Terv';
      this.ap.menuhujuj = false;
    }
  
    ngOnInit(): void {
    }
  
    ngAfterViewInit(): void {
      this._service.fetchBerinstall().subscribe(p => {
        this._service.szervegys2().subscribe(p => {
          this.szervegysek = p;
          this.selectedYear = getYear(Date.now());
          //************************************ */
          this.years[0].key =  this.selectedYear;
          this.years[0].KeyText =  this.selectedYear.toString;
          this.years[1].key =  this.selectedYear+1;
          this.years[1].Kextext =  (this.selectedYear+1).toString;
          //************************************ */
          this.szerv = p[0].key;
          this.ToltTabla(this.szerv, this.selectedYear, this.selectedMonth);
        });
      });
    }
  
    ToltTabla(szervegys:String,ev:number,ho:number) : void {
      if ((szervegys=='') || (ev==0)) return;
      this.isLoading = true;
      //******************************************************************* */
      this._service.gettervtable(szervegys,ev, ho).subscribe(p => {
        this.headerdata = JSON.parse(String(p.header));
        this.bodydata = JSON.parse(String(p.body));
        this.isLoading = false;
        this.keret = p.keret;
        this.nezegetes = p.nezegetes;
      });
    }
    onSzervChanged(): void
    {
      this.ToltTabla(this.szerv,this.selectedYear,this.selectedMonth);
    }
    onYearChanged(): void
    {
      this.ToltTabla(this.szerv,this.selectedYear,this.selectedMonth);
    }
    onMonthChanged(): void
    {
      this.ToltTabla(this.szerv,this.selectedYear,this.selectedMonth);
    }
    elsokar(s : any):string {
      if (s.length>2) return '';
      return s[0];
    }
    selectcell(i:number,j:number,e:any) : void {
      var sor = i; 
      if ((sor!=0) || (e=='PP') || (this.nezegetes))
        return;  
      this._service.tervinsertdelete(this.selectedYear,this.selectedMonth,j).subscribe(p => {
        if (p!='')
          this.ToltTabla(this.szerv,this.selectedYear,this.selectedMonth);
      });
    }
    szov(e : any) : string {
      if (e.length>2)
        return e;
      else
        if (e[1]=='S')
          return e[1];
        else
          return '';
      }
  }
  