import { Observable } from "rxjs";
import { ConfirmComponent } from "./confirm.component";
import { AlertComponent } from "./alert.component";
import { MatDialogRef, MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { Injectable } from "@angular/core";

@Injectable()
export class UiService
{
    constructor(private _dialog: MatDialog) {}

    public confirm(title: string, message: string): Observable<boolean>
    {

        let dialogRef: MatDialogRef<ConfirmComponent>;

        dialogRef = this._dialog.open(ConfirmComponent);
        dialogRef.componentInstance.title = title;
        dialogRef.componentInstance.message = message;
        return dialogRef.afterClosed();
    }

    public alert(title: string, message: string): Observable<boolean>
    {

        let dialogRef: MatDialogRef<AlertComponent>;

        dialogRef = this._dialog.open(AlertComponent);
        dialogRef.componentInstance.title = title;
        dialogRef.componentInstance.message = message;
        return dialogRef.afterClosed();
    }
}
