/**
 * Created by lendvai on 2017.05.18.
 */

/**
 * General utility methods
 */
export class Utils
{
    private static _base64KeyStr = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=";

    /*
     * Check if a character is a letter
     */
    public static isLetter(c: string): boolean
    {
        return c === "_" || "a" <= c && c <= "z" || "A" <= c && c <= "Z";
    }

    /*
     * Check if a character is a digit
     */
    public static isDigit(c: string): boolean
    {
        return "0" <= c && c <= "9";
    }

    /*
     * Check if a character is a letter or a digit
     */
    public static isLetterOrDigit(c: string): boolean
    {
        return c === "_" || "a" <= c && c <= "z" || "A" <= c && c <= "Z" || "0" <= c && c <= "9";
    }

    /**
     * Check if a character can be part of a javascript identifier
     * @param ch the character
     * @returns {boolean} true if it is an identifier letter, false otherwise
     */
    public static isIdent(ch:string): boolean
    {
        return this.isLetterOrDigit(ch) || "_" === ch || ch === "$";
    }

    /*
     * Check if a character is a space character
     */
    public static isSpacefunction(c: string): boolean
    {
        return c < " ";
    }

    /**
     * Convert string to boolean
     * @param txt      the string value
     * @param def      default value if cannot convert
     * @returns the boolean value
     */
    public static toBoolean(txt: string, def: boolean): boolean
    {
        switch(txt.toLowerCase())
        {
            case "true":
            case "yes":
            case "1":
                return true;

            case "false":
            case "no":
            case "0":
            case null:
                return false;

            default:
                return def;
        }
    }

    /**
     * Trim a string
     * @param s    the source string
     * @returns the trimmed value
     */
    public static trim(s: string): string
    {
        if(!s.length)
            return s;

        let i = 0;
        while(s.charAt(i) === " ")
            i++;
        if(i > 0)
            s = s.substring(i);
        i = s.length;
        while(i > 0 && s.charAt(i - 1) === " ")
            --i;
        if(i < s.length)
            s = s.substr(0, i);
        return s;
    }

    /**
     * Get cookie
     *
     * @param name cookie name
     * @returns cookie value
     */
    public static cookie(name: string): string
    {
        const ca = document.cookie.split(";");
        for(let i = 0; i < ca.length; i++)
        {
            let cName = ca[i];
            let cValue = "";
            const idx = cName.indexOf("=");
            if(idx >= 0)
            {
                cValue = this.trim(cName.substr(idx + 1));
                cName  = this.trim(cName.substr(0, idx));
            }
            else
                cName = this.trim(cName);
            if(cName === name)
                return cValue;
        }
        return "";
    }

    /**
     * Set cookie value
     *
     * @param name cookie name
     * @param value cookie value
     * @param days expiration days
     */
    public static setCookie(name: string, value: string, days: number = 5): void
    {
        const d = new Date();
        d.setTime(d.getTime() + (days || 5) *24 * 60 * 60 * 1000);
        document.cookie = name + "=" + value + "; expires=" + d.toUTCString();
    }

    /**
     * Remove cookie
     *
     * @param name cookie name
     */
    public static removeCookie(name: string): void
    {
        document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:01 GMT;";
    }

    /**
     * Check if a mobile device
     * @returns {boolean}
     */
    public static isMobile(): boolean
    {
        const ua = window["navigator"]["userAgent"] || window["navigator"]["vendor"] /*|| window["opera"]*/;
        if((/iPhone|iPod|iPad|Silk|Android|BlackBerry|Opera Mini|IEMobile/).test(ua))
            return true;
        return window["outerWidth"] < 767;
    }

    /**
     * Get ASCII code
     */
    public static ascii(a: string): number
    {
        return a.charCodeAt(0);
    }

    /**
     * Rotate a number
     *
     * @param n     the number to rotate
     * @param q     number of bits to rotate
     */
    public static rot(n: number, q: number): number
    {
        return ((n << q) ^ ((n & 0xff) >> (8 - q))) & 0xff;
    }

    /**
     * Compare dates
     */
    public static datesEquals(d1: Date, d2: Date): boolean
    {
        return d1.getFullYear() === d2.getFullYear() && d1.getDate() === d2.getDate() && d1.getMonth() === d2.getMonth();
    }

    /**
     * Convert text to BASE64
     * @param input the input text
     * @return the BASE64 text
     */
    public static toBase64(input: string): string
    {
        if(!input)
            return input;

        let output = "", chr1, chr2, chr3, enc1, enc2, enc3, enc4, i = 0;
        let utftext = "", c, n;

        input = input.replace(/\r\n/g,"\n");
        for(n = 0; n < input.length; n++)
        {
            c = input.charCodeAt(n);

            if (c < 128)
                utftext += String.fromCharCode(c);
            else if((c > 127) && (c < 2048))
            {
                utftext += String.fromCharCode((c >> 6) | 192);
                utftext += String.fromCharCode((c & 63) | 128);

            }
            else
            {
                utftext += String.fromCharCode((c >> 12) | 224);
                utftext += String.fromCharCode(((c >> 6) & 63) | 128);
                utftext += String.fromCharCode((c & 63) | 128);
            }
        }

        while(i < utftext.length)
        {
            chr1 = utftext.charCodeAt(i++);
            chr2 = utftext.charCodeAt(i++);
            chr3 = utftext.charCodeAt(i++);

            enc1 = chr1 >> 2;
            enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
            enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
            enc4 = chr3 & 63;

            if(isNaN(chr2))
                enc3 = enc4 = 64;
            else if(isNaN(chr3))
                enc4 = 64;

            output += this._base64KeyStr.charAt(enc1);
            output += this._base64KeyStr.charAt(enc2);
            output += this._base64KeyStr.charAt(enc3);
            output += this._base64KeyStr.charAt(enc4);
        }

        return output;
    }

    /**
     * Convert BASE64 to text
     * @param input the BASE64 text
     * @return the text
     */
    public static fromBase64(input: string): string
    {
        if(!input)
            return input;

        let output = "", chr1, chr2, chr3, enc1, enc2, enc3, enc4, i = 0;

        input = input.replace(/[^A-Za-z0-9+\/=]/g, "");

        while(i < input.length)
        {
            enc1 = this._base64KeyStr.indexOf(input.charAt(i++));
            enc2 = this._base64KeyStr.indexOf(input.charAt(i++));
            enc3 = this._base64KeyStr.indexOf(input.charAt(i++));
            enc4 = this._base64KeyStr.indexOf(input.charAt(i++));

            chr1 = (enc1 << 2) | (enc2 >> 4);
            chr2 = ((enc2 & 15) << 4) | (enc3 >> 2);
            chr3 = ((enc3 & 3) << 6) | enc4;

            output += String.fromCharCode(chr1);

            if(enc3 !== 64)
                output += String.fromCharCode(chr2);
            if(enc4 !== 64)
                output += String.fromCharCode(chr3);
        }

        let string = "", c = 0, c1 = 0, c2 = 0;

        i = 0;
        while(i < output.length)
        {
            c = output.charCodeAt(i);

            if(c < 128)
            {
                string += String.fromCharCode(c);
                i++;
            }
            else if((c > 191) && (c < 224))
            {
                c1 = output.charCodeAt(i+1);
                string += String.fromCharCode(((c & 31) << 6) | (c1 & 63));
                i += 2;
            }
            else
            {
                c1 = output.charCodeAt(i+1);
                c2 = output.charCodeAt(i+2);
                string += String.fromCharCode(((c & 15) << 12) | ((c1 & 63) << 6) | (c2 & 63));
                i += 3;
            }
        }
        return string;
    }

    /**
     * @name equals
     *
     * @description
     * Determines if two objects or two values are equivalent.
     *
     * Two objects or values are considered equivalent if at least one of the following is true:
     *
     * * Both objects or values pass `===` comparison.
     * * Both objects or values are of the same type and all of their properties are equal by
     *   comparing them with `equals`.
     *
     * @param {*} o1 Object or value to compare.
     * @param {*} o2 Object or value to compare.
     * @returns {boolean} True if arguments are equal.
     */
    public static equals(o1: any, o2: any): boolean
    {
        if(o1 === o2)
                return true;
        if(o1 === null || o2 === null)
                return false;
        if(o1 !== o1 && o2 !== o2)
                return true; // NaN === NaN
        const t1 = typeof o1, t2 = typeof o2;
        let length: number, key: any, keySet: any;
        if(t1 === t2 && t1 === "object")
        {
            if(Array.isArray(o1))
            {
                if(!Array.isArray(o2))
                    return false;
                if((length = o1.length) === o2.length)
                {
                    for(key = 0; key < length; key++)
                        if(!Utils.equals(o1[key], o2[key]))
                            return false;
                    return true;
                }
            }
            else
             {
                if(Array.isArray(o2))
                    return false;
                keySet = Object.create(null);
                for(key in o1)
                {
                    if(!Utils.equals(o1[key], o2[key]))
                        return false;
                    keySet[key] = true;
                }
                for(key in o2)
                    if(!(key in keySet) && typeof o2[key] !== "undefined")
                    return false;
                return true;
            }
        }
        return false;
    }

    /**
     * Convert to javascript ident (first letter is lowercase)
     *
     * @param s the source
     * @return the converted identifier
     */
    public static toIdent(s: string): string
    {
        if(!s)
            return s;
        if(s.length == 1)
            return s.toLowerCase();
        return s.charAt(0).toLowerCase() + s.slice(1);
    }

    /**
     * Convert comma separated list of names to identifier list
     *
     * @param s the source list
     * @return the list
     */
    public static toIdentList(s?: string): string[]
    {
        return s ? s.split(',').filter(value => value && value.length).map(value => Utils.toIdent(value)) : [];
    }

    public static myUrlEncode(s: string): string
    {
        const sx = encodeURI(s);
        let result = "";
        for(let c of sx)
            switch(c)
            {
                case '=':
                    result += "%3D";
                    break;

                case ',':
                    result += "%2C";
                    break;

                case '\'':
                    result += "%27";
                    break;
                    
                default:
                    result += c;
            }        
        return result;
    }
}

