import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatSelectModule } from '@angular/material/select';
import {FormsModule} from '@angular/forms';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import { AppComponent } from '../app.component';
import { SzurokComponent } from '../szurok/szurok.component';
import { AppService } from '../app.service';
import { szabiinfo, szabiinfo0} from '../model';
import {MatTableModule} from '@angular/material/table';
import { MatTableDataSource } from '@angular/material/table';
import {MatDividerModule} from '@angular/material/divider';


@Component({
  selector: 'app-szabiinfo',
  standalone: true,
  imports: [CommonModule,MatSelectModule,FormsModule,MatInputModule,MatFormFieldModule,SzurokComponent,
    MatTableModule,MatDividerModule],
  templateUrl: './szabiinfo.component.html',
  styleUrls: ['./szabiinfo.component.css']
})
export class SzabiinfoComponent  {
invchsubs!: any;
_dolgozo: String = '';
_mszerz: String = '';
_szabijelleg: String = " 0";
dataSource0: MatTableDataSource<szabiinfo0> = new MatTableDataSource<szabiinfo0>([]);
displayedColumns0: string[] = ['szoveg', 'felh', 'tanul', 'rendk','ossz'];
dataSource: MatTableDataSource<szabiinfo> = new MatTableDataSource<szabiinfo>([]);
displayedColumns: string[] = ['ho', 'tol', 'ig', 'nap','kerelem'/*, 'engedely', 'torles', 'visszavonas', 'elutasitas'*/];

  constructor(private ap :AppComponent,private _service: AppService) {
    this.ap.title = 'Szabadság Információ';
    this.ap.menuhujuj = false;
  }

  ngOnInit(): void {
    this.ap.menuhujuj = false;
    this.invchsubs = this._service.Kulcsok.subscribe(message => {
      if (message.item!="szabiinfo") return;
      this._dolgozo = message.dolgozo;
      this._mszerz = message.mszerz;
      this._szabijelleg = ' 0';
      //**********************************************************
      this.SzabiInfo0Tolt();
      this.SzabiInfoTolt();
  });
  }
  
  ngOnDestroy() {
    if (this.invchsubs)
      this.invchsubs.unsubscribe();
  }

  public SzabiInfo0Tolt() {
    this._service.getszabiinfo0(this._dolgozo, this._mszerz).subscribe(p => {
      this.dataSource0 = new MatTableDataSource<szabiinfo0>(p);
    });  
  }
  public SzabiInfoTolt() {
    this._service.getszabiinfo(this._dolgozo, this._mszerz, this._szabijelleg).subscribe(p => {
      this.dataSource = new MatTableDataSource<szabiinfo>(p);
    });  
  }
    
  onSzjChanged(): void
  {
    this.SzabiInfoTolt();
  }

}
