/**
 * Created by lendvai on 2017.05.18.
 */

/**
 * Task states
 */
export enum TaskState
{
    /**
     * State is unknown
     */
    Unknown,

    /**
     * Enqueued for processing
     */
    Enqueued,

    /**
     * Processing the task
     */
    Processing,

    /**
     * The task has been processed
     */
    Processed,

    /**
     * Authentication needed
     */
    AuthNeeded
}
