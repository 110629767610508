import { BreakpointObserver } from '@angular/cdk/layout';
import { CdkOverlayOrigin, Overlay, OverlayConfig, OverlayModule, OverlayRef } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { Component, ElementRef, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule,  } from '@angular/material/card';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenav, MatSidenavModule } from '@angular/material/sidenav';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { MatToolbarModule } from '@angular/material/toolbar';
import { Router, RouterLink, RouterOutlet, RouterLinkActive } from '@angular/router';
import { AuthService, HttpSenderService, LoginState, TopCoreModule, topSpinnerService, TranslateService, UiService } from 'top-core';
import { LANGUAGE_TEXTS } from './texts';
import { TemplatePortal } from '@angular/cdk/portal';
import { AppService } from './app.service';
import {MatDialog} from '@angular/material/dialog';
import { HelphelpComponent } from "./helphelp/helphelp.component"



@Component({
  selector: 'app-root',
  standalone: true,
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  imports: [
    CommonModule, RouterLink, RouterOutlet,
    MatGridListModule, MatButtonModule, MatToolbarModule, MatCardModule, MatMenuModule, MatIconModule, MatSidenavModule, 
    MatListModule, OverlayModule, MatSnackBarModule, RouterLinkActive,
    TopCoreModule,HelphelpComponent
  ]
})
export class AppComponent implements OnInit
{
    @ViewChild(MatSidenav) _sidenav: MatSidenav | undefined;
    @ViewChild(CdkOverlayOrigin) _overlayOrigin?: CdkOverlayOrigin;
    @ViewChild('trigger', {read: ElementRef}) private _button?: ElementRef;
    @ViewChild('menuTemplate') menuTemplate?: TemplateRef<any>;

    title = '';

    menuhujuj : boolean = false;
    //engedelyezo : boolean = false;

    isMenuOpen: boolean = false;
    private _menuOverlay?: OverlayRef;

    constructor(
        private _auth: AuthService, private _router: Router, private _lang: TranslateService, private _http: HttpSenderService,
        private _snack: MatSnackBar, private _breakpointObserver: BreakpointObserver, private topspinner: topSpinnerService,
        public _overlay: Overlay, public _viewContainerRef: ViewContainerRef, private _ui: UiService,private _service: AppService,
        public dialog: MatDialog
    ) {
        this.topspinner.enableHttpIntercept();
    }

    ngOnInit(): void
    {
        this._lang.init(LANGUAGE_TEXTS);
        this._http.onError.subscribe(error => this._snack.open(error, "Ok", { duration: 20000 }));
        // this.engedelyezo = this._service._Berinst().vezeto;
    }

    showmenu(tipus: string):boolean {        
        if (tipus=="engedely")
            return this._service._Berinst().vezeto;
        
        if (tipus=="zaras")
            return this._service._Berinst().vezeto && this._service._Berinst().lezaras;

        if (tipus=="tavmunka")
            return this._service._Berinst().tavmunka;

        if (tipus=="engedelytavmunka")
            return this._service._Berinst().tavmunka && this._service._Berinst().vezeto;

        if (tipus=="engedelyjogcim")
            return this._service._Berinst().engedelykot && this._service._Berinst().vezeto;

        return false;
    }

    get isLoggedIn(): boolean
    {
        return this._auth.state == LoginState.LoggedIn;
    }

    get userName(): string
    {
        if(!this._auth.isLoggedIn)
            return "";
        return this._auth.user.name;
    }

    lang(): string
    {
        return this._lang.language;
    }

    useLang(lang: string): void
    {
        this._lang.use(lang);
    }
    sendLogToTopsoft()
    {
        this._ui.confirm(this._lang.texts.sendlogtotopsoft, this._lang.texts.areYouSure).subscribe((res: boolean) =>  {
            if (res){
                this._auth.sendLogToTopsoft();
                alert(this._lang.texts.senttotopsoft)
            }
                
        });
        
            
    }
    private _logout(): void
    {
        if(this._menuOverlay)
        {
            this._menuOverlay.dispose();
            this._menuOverlay = undefined;
        }
        this._auth.logoff();
        this._router.navigate(['login'])
    }

    logout(): void
    {
        this._ui.confirm(this._lang.texts.logoff, this._lang.texts.areYouSure).subscribe((res: boolean) => res && this._logout());
    }

    openPersonPrefs()
    {
        if(!this._button || !this.menuTemplate)
            return;
        let strategy = this._overlay.position().flexibleConnectedTo(this._button).withPositions([{
            originX: 'start',
            originY: 'bottom',
            overlayX: 'start',
            overlayY: 'top',
        }]);
        let config = new OverlayConfig({
            hasBackdrop: true,
            positionStrategy: strategy,
            scrollStrategy: this._overlay.scrollStrategies.reposition()
        });
        this._menuOverlay = this._overlay.create(config);

        this._menuOverlay.backdropClick().subscribe(() => {
            if(this._menuOverlay)
            {
                this._menuOverlay.dispose();
                this._menuOverlay = undefined;
            }
        });
        this._menuOverlay.attach(new TemplatePortal(this.menuTemplate, this._viewContainerRef));
    }

    help() : void {
        this.menuhujuj = false;
        const dialogRef1 = this.dialog.open(HelphelpComponent, {
          });
          dialogRef1.afterClosed().subscribe(result => {
            if (result) {
            } 
          });    
    }
}

/** Simple component to load into an overlay */
@Component({
    selector: 'person-panel',
    template: '<p class="person">Person {{value}}</p>',
    standalone: true
})
export class PersonPanel {
    value: number = 9000;
}

