import { Injectable,Output,EventEmitter } from '@angular/core';
import { IJelenlet, TorzstarParams,KeyText,Berinstall,DolgozoRec,THonap,
         TfelvitelGyorsParams,TflevitelTolt,evho,szabiinfo,szabiinfo0,szabiinfotable,engedtable,
          Tzart, 
          TFileContent,
          NamedBlob} from './model';
import { Observable } from 'rxjs';
import { HttpSenderService } from 'top-core';

@Injectable({
  providedIn: 'root'
})



export class AppService implements IJelenlet {

  constructor(private _http: HttpSenderService) { 
    this._kulcsok= new EventEmitter<{dolgozo:string,mszerz:String,ev:number,ho:number,item:String}>();
  } 
  private _kulcsok: EventEmitter<{dolgozo:string,mszerz:String,ev:number,ho:number,item:String}>;

  /**
  * Convert BASE64 to BLOB
  * @param base64Image Pass Base64 image data to convert into the BLOB
  */
  private convertBase64ToBlob(base64Image: string, mime: string)
  {
      // Decode Base64 string
      const decodedData = window.atob(base64Image);

      // Create UNIT8ARRAY of size same as row data length
      const uInt8Array = new Uint8Array(decodedData.length);

      // Insert all character code into uInt8Array
      for(let i = 0; i < decodedData.length; ++i)
          uInt8Array[i] = decodedData.charCodeAt(i);

      // Return BLOB image after conversion
      return new Blob([uInt8Array], { type: mime });
  }


  public get Kulcsok(): EventEmitter<{dolgozo:string,mszerz:String,ev:number,ho:number,item:String}>
  {
      return this._kulcsok;
  }

  getHonap(dolgozo:String,mszerz:String,ev:number,ho:number) : Observable<THonap>
  {
    return this._http.exec<THonap>('IJelenlet.GetJelenlet', [dolgozo,mszerz,ev,ho]);
  }

  Berinst : Berinstall = new Berinstall;
  _Berinst() : Berinstall
  { 
      return this.Berinst;
  }
  getBerinstall(): Observable<Berinstall>
  {
        return this._http.exec<Berinstall>('IJelenlet.GetBerinstall', []);
  }
  fetchBerinstall(): Observable<Berinstall>
  {
      return new Observable<Berinstall>(o => {
          if(this.Berinst.ev!=0)
              o.next(this.Berinst);
          else
              this. getBerinstall().subscribe(j => {
                  this.Berinst = j;
                  o.next(j);
              })
      });
  }
  fetchBerinstallLogin(): Observable<Berinstall>
  {
      return new Observable<Berinstall>(o => {
//          if(this.Berinst.ev!=0)
//              o.next(this.Berinst);
//          else
              this. getBerinstall().subscribe(j => {
                  this.Berinst = j;
                  o.next(j);
              })
      });
  }

  getMszerz(dolg : String): Observable<KeyText[]>
  {
    let torzsParams: TorzstarParams = <TorzstarParams>{
      filterValue : '',
      filterId    : '',
      nullValue   : false,
      nullValueText: "-",
      sortByCode: true,
      textInclCode: true
      };    
  return this._http.exec<KeyText[]>('IJelenlet.GetMszerz', [dolg,torzsParams]);
  }

  getSzervegys(params: TorzstarParams): Observable<KeyText[]>
  {
        return this._http.exec<KeyText[]>('IJelenlet.GetSzervegys', [params]);
  }
  getSzervegys2(params: TorzstarParams): Observable<KeyText[]>
  {
        return this._http.exec<KeyText[]>('IJelenlet.GetSzervegys2', [params]);
  }

  szervegys(): Observable<KeyText[]>
  {
    let torzsParams: TorzstarParams = <TorzstarParams>{
      filterValue : '',
      filterId    : '',
      nullValue   : true,
      nullValueText: "-",
      sortByCode: true,
      textInclCode: true
      };    
         return this.getSzervegys(torzsParams);
  }
  szervegys2(): Observable<KeyText[]>
  {
    let torzsParams: TorzstarParams = <TorzstarParams>{
      filterValue : '',
      filterId    : '',
      nullValue   : false,
      nullValueText: "-",
      sortByCode: true,
      textInclCode: true
      };    
         return this.getSzervegys2(torzsParams);
  }
  szervegys3(): Observable<KeyText[]>
  {
    let torzsParams: TorzstarParams = <TorzstarParams>{
      filterValue : '',
      filterId    : '',
      nullValue   : false,
      nullValueText: "-",
      sortByCode: true,
      textInclCode: true,
      tip : 'enged'
      };    
         return this.getSzervegys(torzsParams);
  }

  getFizhely(params: TorzstarParams): Observable<KeyText[]>
  {
        return this._http.exec<KeyText[]>('IJelenlet.GetFizhely', [params]);
  }

  fizhely(): Observable<KeyText[]>
  {
    let torzsParams: TorzstarParams = <TorzstarParams>{
      filterValue : '',
      filterId    : '',
      nullValue   : true,
      nullValueText: "-",
      sortByCode: true,
      textInclCode: true
      };    
         return this.getFizhely(torzsParams);
  }

 getDolgozoFilter(szer:String,filter: string,item:String): Observable<DolgozoRec[]>
  {        
    let torzsParams: TorzstarParams = <TorzstarParams>{
      filterValue : filter,
      filterId    : '',
      nullValue   : false,
      nullValueText: "-",
      sortByCode: true,
      textInclCode: true,
      tip:item
      };    
//      if ((!filter || filter.length==0) && !((item=='engedhome') ||(item=='engedszabi')))
//          return new Observable<DolgozoRec[]>();
      if(this.Berinst.szurotip=='1')
        return new Observable<DolgozoRec[]>(o => {            
                this.getDolgozo(szer,'',torzsParams).subscribe(j => {                    
                    o.next(j);
                })
              })
        else
        return new Observable<DolgozoRec[]>(o => {            
               this.getDolgozo('',szer,torzsParams).subscribe(j => {                    
                  o.next(j);
                })
              });
  }  
  getDolgozoFilterId(filterId: string): Observable<DolgozoRec>
    {        
      let torzsParams: TorzstarParams = <TorzstarParams>{
        filterValue : '',
        filterId    : filterId,
        nullValue   : false,
        nullValueText: "-",
        sortByCode: true,
        textInclCode: true
        };    
        return new Observable<DolgozoRec>(o => {            
          this.getDolgozo('','',torzsParams).subscribe(j => {                    
             o.next(j[0]);
           })
         });

  }  

  
  getDolgozo(sz:String, fi:String, params: TorzstarParams): Observable<DolgozoRec[]>
  {
    return this._http.exec<DolgozoRec[]>('IJelenlet.GetDolgozo', [sz,fi,params]);
  }

  felvitelGyors(par:TfelvitelGyorsParams,maszk:boolean[]): Observable<string>
  {
    return this._http.exec<string>('IJelenlet.felvitelGyors', [par,maszk]);
  }
  deleteGyors(par:TfelvitelGyorsParams,maszk:boolean[]): Observable<string>
  {
    return this._http.exec<string>('IJelenlet.deleteGyors', [par,maszk]);
  }
  hoGyors(par:TfelvitelGyorsParams,maszk:boolean[]): Observable<string>
  {
    return this._http.exec<string>('IJelenlet.hoGyors', [par,maszk]);
  }
  hoEnged(par:TfelvitelGyorsParams,maszk:boolean[],tip2:String): Observable<string>
  {
    return this._http.exec<string>('IJelenlet.hoEnged', [par,maszk,tip2]);
  }
  szabiEnged(par:TfelvitelGyorsParams,maszk:boolean[]): Observable<string>
  {
    return this._http.exec<string>('IJelenlet.szabiEnged', [par,maszk]);
  }
  jogcimEnged(par:TfelvitelGyorsParams,maszk:boolean[]): Observable<string>
  {
    return this._http.exec<string>('IJelenlet.jogcimEnged', [par,maszk]);
  }
  hoEngedcs(ev:Number,dolgozo:string[],tip:String,tip2:String): Observable<string>
  {
    return this._http.exec<string>('IJelenlet.hoEngedcs', [ev,dolgozo,tip,tip2]);
  }
  szabiEngedcs(ev:Number,dolgozo:string[],tip:String): Observable<string>
  {
    return this._http.exec<string>('IJelenlet.szabiEngedcs', [ev,dolgozo,tip]);
  }
  jogcimEngedcs(ev:Number,dolgozo:string[],tip:String): Observable<string>
  {
    return this._http.exec<string>('IJelenlet.jogcimEngedcs', [ev,dolgozo,tip]);
  }
  getevho(dolg:String,mszerz:String,item:String): Observable<evho> {
      return this._http.exec<evho>('IJelenlet.evho', [dolg,mszerz,item]);
  }
  tryRegisterDolgozo(lid:string): Observable<string>{
    return this._http.exec<string>('IJelenlet.tryRegisterDolgozo', [lid]);
  }
  //******************************************************************** */
  // Jogcím szótár
  //******************************************************************** */
  getJogcimek(params: TorzstarParams): Observable<KeyText[]>
  {
    return this._http.exec<KeyText[]>('IJelenlet.GetJogcim', [params]);
  }
  jogcimekAC(filter: string,tip:string): Observable<KeyText[]>
  {        
     // if (!filter)
     //     return new Observable<KeyText[]>();
      
      var param: TorzstarParams = <TorzstarParams>{
          nullValue: false,
          nullValueText: "-",
          sortByCode: true,
          textInclCode: true,
          filterId : '',
          filterValue : filter,
          tip : tip,
      };
                              
      return new Observable<KeyText[]>(o => {            
              this.getJogcimek(param).subscribe(j => {                    
                  o.next(j);
              })
      });
  }
  //******************************************************************** */
  // Fizhely szótár
  //******************************************************************** */
  getfizhelyek(params: TorzstarParams): Observable<KeyText[]>
  {
    return this._http.exec<KeyText[]>('IJelenlet.GetFizhely', [params]);
  }
  fizhelyekAC(filter: string): Observable<KeyText[]>
  {        
     // if (!filter)
     //     return new Observable<KeyText[]>();
      
      var param: TorzstarParams = <TorzstarParams>{
          nullValue: false,
          nullValueText: "-",
          sortByCode: true,
          textInclCode: true,
          filterId : '',
          filterValue : filter
      };
                              
      return new Observable<KeyText[]>(o => {            
              this.getfizhelyek(param).subscribe(j => {                    
                  o.next(j);
              })
      });
  }
  //******************************************************************** */
  // Munkaszám szótár
  //******************************************************************** */
  getmunkaszamok(params: TorzstarParams): Observable<KeyText[]>
  {
    return this._http.exec<KeyText[]>('IJelenlet.GetMunkaszam', [params]);
  }
  munkaszamokAC(filter: string,ev:Number,ho:Number): Observable<KeyText[]>
  {        
     // if (!filter)
     //     return new Observable<KeyText[]>();
      
      var param: TorzstarParams = <TorzstarParams>{
          nullValue: false,
          nullValueText: "-",
          sortByCode: true,
          textInclCode: true,
          filterId : '',
          filterValue : filter,
          ev : ev,
          ho : ho,
};
                              
      return new Observable<KeyText[]>(o => {            
              this.getmunkaszamok(param).subscribe(j => {                    
                  o.next(j);
              })
      });
  }
  //******************************************************************** */
  // Ktghely szótár
  //******************************************************************** */
  getktghelyek(params: TorzstarParams): Observable<KeyText[]>
  {
    return this._http.exec<KeyText[]>('IJelenlet.GetKtghely', [params]);
  }
  ktghelyekAC(filter: string,ev:Number,ho:Number): Observable<KeyText[]>
  {        
     // if (!filter)
     //     return new Observable<KeyText[]>();
      
      var param: TorzstarParams = <TorzstarParams>{
          nullValue: false,
          nullValueText: "-",
          sortByCode: true,
          textInclCode: true,
          filterId : '',
          filterValue : filter,
          ev : ev,
          ho : ho,
};
                              
      return new Observable<KeyText[]>(o => {            
              this.getktghelyek(param).subscribe(j => {                    
                  o.next(j);
              })
      });
  }
  //******************************************************************** */
  // Szervegys szótár
  //******************************************************************** */
  getszervegysek(params: TorzstarParams): Observable<KeyText[]>
  {
    return this._http.exec<KeyText[]>('IJelenlet.GetSzervegys', [params]);
  }
  szervegysekAC(filter: string): Observable<KeyText[]>
  {        
     // if (!filter)
     //     return new Observable<KeyText[]>();
      
      var param: TorzstarParams = <TorzstarParams>{
          nullValue: false,
          nullValueText: "-",
          sortByCode: true,
          textInclCode: true,
          filterId : '',
          filterValue : filter
      };
                              
      return new Observable<KeyText[]>(o => {            
              this.getszervegysek(param).subscribe(j => {                    
                  o.next(j);
              })
      });
  }

  //******************************************************************** */
  //Helyettesitok szótár
  //******************************************************************** */
  gethelyettesitok(sz:String,params: TorzstarParams): Observable<KeyText[]>
  {
    return this._http.exec<KeyText[]>('IJelenlet.GetHelyettesito_', [sz,params]);
  }
  helyettesitokAC(sz:String,filter: string): Observable<KeyText[]>
  {        
      if (sz=='')
          return new Observable<KeyText[]>();
      
      var param: TorzstarParams = <TorzstarParams>{
          nullValue: false,
          nullValueText: "",
          sortByCode: true,
          textInclCode: true,
          filterId : '',
          filterValue : filter
      };
                              
      return new Observable<KeyText[]>(o => {            
              this.gethelyettesitok(sz,param).subscribe(j => {                    
                  o.next(j);
              })
      });
  }
  //******************************************************************** */
  // helyettesitett szótár
  //******************************************************************** */
  gethelyettesitetttolt(params: TorzstarParams): Observable<KeyText[]> {
    return this._http.exec<KeyText[]>('IJelenlet.GetHelyettesitett', [params]);
  }
  helyettesitetttolt(): Observable<KeyText[]> {
    var param: TorzstarParams = <TorzstarParams>{
      nullValue: true,
      nullValueText: "",
      sortByCode: true,
      textInclCode: true,
      filterId: '',
      filterValue: ''
    };

    return new Observable<KeyText[]>(o => {
      this.gethelyettesitetttolt(param).subscribe(j => {
        o.next(j);
      })
    });
  }



  getfelvitelTolt(params:TfelvitelGyorsParams):Observable<TflevitelTolt>{
    return this._http.exec<TflevitelTolt>('IJelenlet.felviteltolt', [params]);
  }
  getmodositasTolt(params:TfelvitelGyorsParams):Observable<TflevitelTolt>{
    return this._http.exec<TflevitelTolt>('IJelenlet.modositastolt', [params]);
  }

  jelenletTran(data:TflevitelTolt,maszk:boolean[]): Observable<string>{
    return this._http.exec<string>('IJelenlet.jelenlettran', [data,maszk]); 
  }

  jelenletTrandel(params:TfelvitelGyorsParams): Observable<string>{
    return this._http.exec<string>('IJelenlet.jelenlettrandel', [params]); 
  }

  getszabiinfo(dolgozo:String,mszerz:String,szabijelleg:String): Observable<szabiinfo[]> {
    return this._http.exec<szabiinfo[]>('IJelenlet.GetSzabiInfo', [dolgozo,mszerz,szabijelleg]); 
  }
  getszabiinfo0(dolgozo:String,mszerz:String): Observable<szabiinfo0[]> {
    return this._http.exec<szabiinfo0[]>('IJelenlet.GetSzabiInfo0', [dolgozo,mszerz]); 
  }
  getszabiinfotable(szervegys:String,ev:Number,ho:Number,tip:String,helytip:boolean,munkaido:boolean): Observable<szabiinfotable> {
    return this._http.exec<szabiinfotable>('IJelenlet.GetSzabTable', [szervegys,ev,ho,tip,helytip,munkaido]); 
  }
  getszabiinfotable2(szervegys:String,ev:Number,ho:Number,tip:String): Observable<engedtable> {
    return this._http.exec<engedtable>('IJelenlet.GetSzabTable2', [szervegys,ev,ho,tip]); 
  }
  getengedtable(helyettesitett:String,szervegys:String,ev:Number,ho:Number,tip:String): Observable<engedtable> {
    return this._http.exec<engedtable>('IJelenlet.GetEngedTable', [helyettesitett,szervegys,ev,ho,tip]); 
  }

  getzart(dolgozo:String,mszerz:String,ev:Number,ho:Number): Observable<Tzart> {
    return this._http.exec<Tzart>('IJelenlet.Zart', [dolgozo,mszerz,ev,ho]); 
  }
  lezart(dolgozo:String,mszerz:String,ev:Number,ho:Number): Observable<boolean> {
    return this._http.exec<boolean>('IJelenlet.LeZart', [dolgozo,mszerz,ev,ho]); 
  }
  getNyitZar(dolgozo:String,mszerz:String,ev:Number,ho:Number,tip:String): Observable<boolean>{
    return this._http.exec<boolean>('IJelenlet.getNyitZar', [dolgozo,mszerz,ev,ho,tip]); 
  }
  getNyitZarCsop(dolgozo:String[],mszerz:String[],maszk:boolean[],ev:Number,ho:Number,tip:String): Observable<boolean>{
    return this._http.exec<boolean>('IJelenlet.getNyitZarCsop', [dolgozo,mszerz,maszk,ev,ho,tip]); 
  }

  getJelenletLista(doglozo: String,mszerz : String, ev: Number, honap: Number): Observable<TFileContent>{
    return this._http.exec<TFileContent>("IJelenlet.GetJelenletLista", [doglozo, mszerz, ev, honap]);
  }

  downloadJelenletLista(dolgozo: String, mszerz: String, ev: Number,  honap: Number): Observable<NamedBlob>
  {
    return new Observable<NamedBlob>(observer => {
          this.getJelenletLista(dolgozo, mszerz, ev, honap).subscribe(c => {
              const b = this.convertBase64ToBlob(c.data, c.mime);
              observer.next({ data: b, fileName: c.fileName, mime: c.mime});
          })
      });
  }
  gettervtable(szervegys:String,ev:Number,ho:Number): Observable<szabiinfotable> {
    return this._http.exec<szabiinfotable>('IJelenlet.GetTervTable', [szervegys,ev,ho]); 
  }
  tervinsertdelete(ev:number,ho:number,j:number):Observable<string>{
    return this._http.exec<string>('IJelenlet.TervInsertDelete', [ev,ho,j]); 
  }

}
