import { Component,OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NapiComponent } from '../napi/napi.component';
import { AppComponent } from '../app.component';

@Component({
  selector: 'app-kerelem',
  standalone: true,
  imports: [CommonModule,NapiComponent],
  templateUrl: './kerelem.component.html',
  styleUrls: ['./kerelem.component.css']
})
export class KerelemComponent implements OnInit {

  constructor(private ap :AppComponent) {this.ap.title = 'Szabadság kérelem'; }

  ngOnInit(): void {
    
  }  
}
