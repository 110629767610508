<mat-card class="mat-card-flat">
    <mat-card-header >
        <mat-card-title>{{'loginHeader'|translate}}</mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <form name="loginForm" novalidate [formGroup]="form" autocomplete="off">
            <p>
            <mat-form-field *ngIf="false">
                <mat-label>{{'year'|translate}}</mat-label>
                <input type="number" matInput formControlName="year" />
            </mat-form-field>
            </p>

            <p>
            <mat-form-field appearance="outline">
                <mat-label><i class="sprite sprite-user"></i>{{'userIdDetail'|translate}}</mat-label>
                <input matInput matInputDirective="focused" formControlName="userName"/>
            </mat-form-field>
            </p>

            <p>
                <mat-form-field appearance="outline">
                    <mat-label><i class="sprite sprite-login"></i>{{'passwordDetail'|translate}}</mat-label>
                    <input matInput type="password" formControlName="password"/>
                </mat-form-field>
            </p>
    
            <p>
            <mat-form-field appearance="outline">
                <mat-label><i class="sprite sprite-login"></i>{{'profile'|translate}}</mat-label>
                <mat-select formControlName="profile">
                    <mat-option *ngFor="let profile of webSite.profiles" [value]="profile">{{profile.name}}</mat-option>
                </mat-select>
            </mat-form-field>
            
            </p>

            <mat-card-actions align="end" >
                <button mat-raised-button type="button" color="primary" (click)="login()">{{'loginBtn'|translate}}</button>
                <!-- <button mat-raised-button color="primary" (click)="sendLogin()">Barcode login</button> -->
            </mat-card-actions>


        </form>
    </mat-card-content>
</mat-card>
